import React, { useState } from 'react';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    BONIFICATION_PASSOIRE,
    NiveauAmelioration,
    niveauAmeliorationList,
    RENO_PLAFOND_CONST,
    RENO_VALUES,
    RenovationDAmpleurParams,
    RenovationDAmpleurElligible,
    RenovationDAmpleurTarget,
    sortiePassoireList,
} from '../../services/calculs/aides/aideRenovationDAmpleurDefs';
import { currencyFormat, Labelled } from '../../services/tools/TypeHelper';
import { ROUTE_SIM_BON_DE_COMMANDE, ROUTE_SIM_DEVIS, ROUTE_SIM_EXIT } from '../../routing/paths';
import * as themesService from '../../services/calculs/theme';
import * as themesTools from '../../services/calculs/themesTools';
import * as ticketService from '../../services/calculs/ticket';
import { useHistory } from 'react-router-dom';
import * as storageService from '../../services/localStorageService';

import * as api from '../../services/apiParticulierService';
import * as devisPdfService from '../../services/calculs/devisPdf';

import * as bdc from '../../pages/audit-simulator/parcoursBdcCommmons';

import { validateDevis } from '../../services/apiParticulierService';
import { BonDeCommandeContexte, initialPdfState } from '../../pages/audit-simulator/parcoursBdcCommmons';
import { AnnexeDetail } from '../../pages/audit-simulator/bon-de-commande/annexe/Annexe';
import { ModalCounter } from '../ModalCounter/ModalCounter';
import {
    ticketStateAtom,
    ticketLoaderAtom,
    themesStateAtom,
    aidesLocalesListStateAtom,
    aideRenovationDAmpleurTargetStateAtom,
    aideRenovationDAmpleurParamsStateAtom,
    ticketValidateDisableStateAtom,
} from '../../services/Recoil/Atom/Themes.atom';
import { canInstallPV } from '../../services/tools/auditTools';
import { filterAL, filterPreconisations, filterTva } from '../../services/calculs/filterPreconisation';
import { updateTicket } from '../../pages/audit-simulator/ticketUpdater';
import { renderDefaultValueSelectV2 } from '../../services/tools/selectValue';

// Style
import './TicketContainer.scss';
import * as Styles from './selectStyles';

// Icons
import { ReactComponent as IconBulb } from '../../assets/icons/synthese/icon-eco-bulb.svg';
import { ReactComponent as IconEuro } from '../../assets/icons/synthese/icon-ticket-euro.svg';
import { isLocal } from '../../services/tools/generalTools';

export type EcopubSave = { checked: boolean; value: number };

const EcoTicket: React.FC = () => {
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const [ticket, setTicket] = useRecoilState(ticketStateAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);
    const [, setTicketIsLoading] = useRecoilState(ticketLoaderAtom); // MayBeToRemove

    const saveEcoPub = (ecopub: EcopubSave) => {
        // first simply save ecopub.
        storageService.setSimValue('ecopub', ecopub);

        // secondary save for annexe screen
        // read annexe,
        const designationDetails = storageService.getSim().designationDetails;
        let annexeDetailFromLocalStorage: AnnexeDetail[];
        if (!designationDetails) {
            annexeDetailFromLocalStorage = Array<AnnexeDetail>();
        } else {
            annexeDetailFromLocalStorage = designationDetails.value as [];
        }
        const indexOfEcopub = annexeDetailFromLocalStorage.findIndex((item) => item.id === '999');
        if (indexOfEcopub === -1) {
            //il n'y avait aps d'écopub, éventuellement, on l'ajoute
            if (ecopub.checked)
                annexeDetailFromLocalStorage.push({
                    designation: 'Contrat éco-publicitaire',
                    prix: ecopub.value + '',
                    id: '999',
                    lines: '1',
                });
        } else {
            // Il y avait une écopub,
            // soit on  met à jour, soit on supprime.
            if (ecopub.checked) annexeDetailFromLocalStorage[indexOfEcopub].prix = ecopub.value + '';
            else annexeDetailFromLocalStorage.splice(indexOfEcopub, 1);
        }
        //re-savegarde.
        storageService.setSimValue('designationDetails', annexeDetailFromLocalStorage);
    };

    const internalUpdateAideEcoPub = async (ecopub: EcopubSave): Promise<void> => {
        setTicketIsLoading(true);
        saveEcoPub(ecopub); // might be 0, a total non sense, agree.

        // On fait un gros clone profond, pour que tout soit en lecture écriture.
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        const ticketClone: ticketService.TicketData = JSON.parse(JSON.stringify(ticket)) as ticketService.TicketData;

        // console.log(
        //     'internalUpdateAideEcoPub before ' +
        //         JSON.stringify(
        //             Object.values(aidesLocales).map((a) => a?.titre),
        //             null,
        //             2
        //         )
        // );
        const aidesLocalesBuilder = async (aidesLocales: ticketService.AidesLocalesList): Promise<ticketService.AidesLocalesList> => {
            // On recalcule les aides locales liées aux packages.
            const aides: ticketService.AidesLocalesList = {
                ...aidesLocales,
                [ticketService.AIDE_ECO_PUBLICITAIRE_NAME]: ecopub.checked ? ticketService.createAideEcoPub(ecopub.value) : undefined,
            };
            return aides;
        };

        // force le re-render de l'ecran parent
        // On vient de changer des paramètres locaux, donc on a pas besoin de faire un appel api
        // La fonction update ticket n'appellera pas l'api si on lui passe le ticket en parametre
        await updateTicket(allThemesClone, setThemes, ticketClone, setTicket, aidesLocales, setAidesLocales, aidesLocalesBuilder);
        setTicketIsLoading(false);
    };
    // Cet input va envoyer la requete au bout d'une seconde apres que le dernier caractere ait été tappé
    const InputWithTimeout = () => {
        let [value, setValue] = useState<number>(Math.abs(aidesLocales?.[ticketService.AIDE_ECO_PUBLICITAIRE_NAME]?.montant ?? 0));
        let [timerId, setTimerId] = useState<string | number | NodeJS.Timeout | undefined>(undefined);

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            let newValue = +e.currentTarget.value;
            setValue(Math.abs(newValue));
            setTimerId(
                setTimeout(async () => {
                    console.log('eco pub timedout ' + newValue);
                    internalUpdateAideEcoPub({ checked: aidesLocales[ticketService.AIDE_ECO_PUBLICITAIRE_NAME] !== undefined, value: newValue });
                }, 1000)
            );
        };

        return (
            <input
                type="number"
                name="adContrat"
                id="adContract"
                className="input-ticket"
                onChange={handleChange}
                value={value}
                disabled={aidesLocales?.[ticketService.AIDE_ECO_PUBLICITAIRE_NAME] === undefined}
            />
        );
    };

    return (
        <div className="ticket-eco">
            <div className="ticket-title">
                <IconBulb fill="#434d5d" />
                Programme Eco Publicitaire
            </div>

            <div className="ticket-content">
                <p>Je souhaite adhérer au :</p>
                <ul>
                    <li>
                        Programme éco publicitaire
                        <div className="custom-checkbox">
                            <label htmlFor="eco">
                                <input
                                    type="checkbox"
                                    name="eco"
                                    id="eco"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        internalUpdateAideEcoPub({
                                            checked: e.currentTarget.checked,
                                            value: aidesLocales[ticketService.AIDE_ECO_PUBLICITAIRE_NAME]?.montant ?? 0,
                                        });
                                    }}
                                    checked={aidesLocales?.[ticketService.AIDE_ECO_PUBLICITAIRE_NAME] !== undefined}
                                />
                                <div className="input-display"></div>
                            </label>
                        </div>
                    </li>
                </ul>

                <div className="form-group mt-3">
                    <label htmlFor="adContrat">Montant du contrat publicitaire</label>
                    <div className="position-relative">
                        <InputWithTimeout />
                        <span data-currency="€"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BecomePartnerComponent: React.FC = () => {
    // Input checkbox
    const [becomePartner, setBecomePartner] = useState<boolean>(false);

    // Toggle ad state
    const handleCheckedChanged = () => {
        setBecomePartner(!becomePartner);
        // first simply save ecopub.
        storageService.setSimValue('becomePartner', !becomePartner);
    };

    return (
        <div className="ticket-partner">
            <div className="ticket-title">
                <IconEuro fill="#434d5d" />
                Devenir partenaire SOGYS
            </div>

            <div className="ticket-content">
                <p>Je souhaite adhérer au :</p>

                <ul>
                    <li>
                        Réseau SOGYS
                        <div className="custom-checkbox">
                            <label htmlFor="ad">
                                <input type="checkbox" name="ad" id="ad" onChange={handleCheckedChanged} />
                                <div className="input-display"></div>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

const TicketComponent: React.FC = () => {
    const AlertSwal = withReactContent(Swal);
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const [ticket, setTicket] = useRecoilState(ticketStateAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState<ticketService.AidesLocalesList>(aidesLocalesListStateAtom);
    const [draTarget, setDraTarget] = useRecoilState(aideRenovationDAmpleurTargetStateAtom);
    const draParams = useRecoilValue(aideRenovationDAmpleurParamsStateAtom);
    const [, setTicketIsLoading] = useRecoilState(ticketLoaderAtom); // MayBeToRemove
    const ticketValidateDisable = useRecoilValue(ticketValidateDisableStateAtom);

    const emptyAuditId = !localStorage.getItem('auditId')!;
    const auditNotSent = storageService.getAudit().auditSent !== true;
    // const SHOW_DEBUG = process.env.REACT_APP_MODE_DEBUG === 'true'; // apparaît que si on est en debug
    // s'utilise avec style={SHOW_DEBUG ? { color: 'red' } : { display: 'none' }

    // Lorsqu'on tape l'url en direct on peut arriver ici alors que l'audit n'est pas correct, ca génère des défauts d'affichage qui ne sont pas des bugs.
    // Mais ils sont percu comme tel.
    // on se protège en affichant un message, en bas du ticket.
    // if (emptyAuditId) console.log("L'auditId est vide !! ");
    // if (auditNotSent) console.log("L'audit n'a pas été envoyé !! ");

    const buildCartArticles = (): Array<JSX.Element> | undefined => {
        const articles = new Array<JSX.Element>();

        for (const theme of themes) {
            for (const subTheme of theme.subThemes!) {
                for (const article of subTheme.packages)
                    if (article.applicable) {
                        articles.push(
                            <li key={subTheme.subThemeType + article.uuid + article.mainProduct.product_id}>
                                <span>
                                    x{article.mainProduct.quantite} {article.mainProduct.nom}
                                </span>
                                <span>{currencyFormat(article.priceTtc, true)}</span>
                            </li>
                        );
                    }
            }
        }
        if (articles.length === 0) {
            return undefined;
        }
        return articles;
    };

    const buildAidesNoRA = (): Array<JSX.Element> | undefined => {
        return buildAidesBase(ticket.aides);
    };

    const buildAidesCompatibleRA = (): JSX.Element | undefined => {
        const filtered = ticket.aides?.filter((aide: ticketService.Aide) => !aide.isMarRelated && aide.isCompatibleMar);
        const K = buildAidesBase(filtered);
        if (!K || K.length === 0) return undefined;
        return (
            <div className="command-recap-aide">
                <h3 className="command-recap-title">Aides</h3>
                <ul className="command-recap-list">{K}</ul>
            </div>
        );
    };
    // const buildAidesRA_old = (): Array<JSX.Element> | undefined => {
    //     const filtered = ticket.aides?.filter((aide: ticketService.Aide) => aide.isMarRelated && aide.isCompatibleMar);
    //     return buildAidesBase(filtered);
    // };
    const buildAidesRA = (): Array<JSX.Element> | undefined => {
        // La on va faire un truc vraiment crasseux, c'est pour ca que je garde buildAidesRA_old juste au dessus
        // concernant l'aide renovation d'ampleur,
        // on ne va pas affichier le montant de l'aide, mais le montant du plafond.
        // par contre on va conservé le véritable montant pour le total.
        // donc on ne modifie que l'affichage.
        let filtered = ticket.aides?.filter((aide: ticketService.Aide) => aide.isMarRelated && aide.isCompatibleMar);
        filtered = filtered.filter((aide: ticketService.Aide) => aide.titre !== ticketService.AIDE_RENO_AMPLEUR);
        const array: Array<JSX.Element> | undefined = buildAidesBase(filtered);

        // le plafond :
        const reno = ticket.aides?.find((aide: ticketService.Aide) => aide.titre === ticketService.AIDE_RENO_AMPLEUR);
        let renoJSX: JSX.Element | undefined;
        if (reno !== undefined) {
            renoJSX = (
                <li key={reno.titre + reno.product_id}>
                    <span>Plafond de dépense (HT)</span>
                    <span>{currencyFormat(RENO_PLAFOND_CONST[draTarget.niveau], 2)}</span>
                </li>
            );
        }
        if (!renoJSX) return array;
        if (!array) return [renoJSX];
        array.push(renoJSX);
        return array;
    };
    const buildAidesBase = (aides: Array<ticketService.Aide> | undefined): Array<JSX.Element> | undefined => {
        return aides?.map((aide: ticketService.Aide, index: number) => (
            <li key={aide.titre + aide.product_id}>
                <span>{aide.titre}</span>
                <span>{aide.montant_lbl}</span>
            </li>
        ));
    };

    const client = (): string => {
        const client = storageService.getClientIdentity();
        if (!client) return '';
        return client.userCivility + ' ' + client.userFirstname + ' ' + client.userLastname;
    };

    const agent = (): string => {
        const agent = storageService.getCurrentAgent();
        if (!agent) return '';
        return agent.prenom_agent + ' ' + agent.nom_agent;
    };

    const emptyAuditIdStyle = { color: 'red', fontSize: 12 };

    const warningEmptyDevis = () => {
        if (!emptyAuditId && !auditNotSent) return undefined;

        return (
            <div style={emptyAuditIdStyle}>
                {' '}
                {emptyAuditId && (
                    <i>
                        L'auditId est vide
                        <br />
                    </i>
                )}
                {auditNotSent && (
                    <i>
                        L'audit n'a pas été envoyé.
                        <br />
                    </i>
                )}
                <i>Le devis ne peut pas être calculé</i>
            </div>
        );
    };
    const { push } = useHistory();

    const validateAndNavigate = async (): Promise<void> => {
        setTicketIsLoading(true); // MayBeToRemove
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        const flowId = localStorage.getItem('flowId')!;

        const packages = themesTools.getSelectedPackagesWithQty(allThemesClone);

        try {
            // Il faut envoyer le devis :
            const devisId = await validateDevis(flowId, packages, themes); // may crash
            storageService.setDevisId(devisId);
            // To make it crash : (remove lines)
            //await validateDevis(+auditId, [{ package_id: '0', produits: [{ product_id: 0, quantite: '-100', surface: '-100' }] }]);
            const context: BonDeCommandeContexte = { themes, ticket, pdfState: initialPdfState };
            //console.log('le contexte ' + JSON.stringify(context.pdfState));
            setTicketIsLoading(false); // MayBeToRemove
            push(storageService.isCurrentAuditMar() ? ROUTE_SIM_DEVIS : ROUTE_SIM_BON_DE_COMMANDE, context);
        } catch (err) {
            console.log((err as any).response.message);

            setTicketIsLoading(false); // MayBeToRemove
        }
    };

    // On créé le 'devis pdf' en mode 'simple'
    const createDevis = async (context: bdc.BonDeCommandeContexte) => {
        const auditIdStr = localStorage.getItem('auditId');
        if (auditIdStr === null) {
            console.log('auditId is null');
            return;
        }
        const auditId = +auditIdStr;
        const { audit, clientIdentity } = storageService.stepListToAuditAndClient();
        // Si appartement, on envoie pas de devis du tout :
        if (!canInstallPV(audit)) {
            return;
        }
        const simulationSimple = await devisPdfService.createSimulationSimple(audit);
        let themesClone = JSON.parse(JSON.stringify(context.themes));
        // Check si pas de PV selectionner remettre celui qui était selectionné apr défaut au départ de la simu.

        const first = themesTools.getFirstPanneau(themesClone);
        if (first === undefined) {
            // Cas particulier.
            // Ici on essaye de vendre du panneau.
            // Si aucun panneau n'est selectionné, on refait la selection des packages et
            // on met le panneau qui était selectionné au debut de la simu.
            let themes = await themesService.getThemes();
            if (!themes || themes.length === 0) {
                return;
            }
            await filterAL(themes);
            await filterTva(audit, themes);
            //themes = await filterByUserPref(prefThemeFilter, themes);
            themesClone = await filterPreconisations(audit, themes);
            themesTools.getFirstPanneau(themesClone);
        }

        const simuAvecPreco = await devisPdfService.createSimulationCompleteAndPv('Simple', auditId, audit, themesClone);
        await api.createDevisPdf('Simple', +auditId, {
            clientIdentity,
            // audit,
            simulationSimple,
            ...simuAvecPreco,
        });
    };

    const receiveDevis = async (): Promise<void> => {
        try {
            setTicketIsLoading(true); // MayBeToRemove
            AlertSwal.fire({
                title: 'Envoi du devis en cours',
                html: <ModalCounter timer={45} />,
                width: 600,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Il faut envoyer le devis :
            const context: BonDeCommandeContexte = { themes, ticket, pdfState: initialPdfState };
            // creation du devis avant de naviguer
            await createDevis(context);
            console.log('create devis did not crash 2');
        } catch (err) {
            console.log((err as any).response.message);
        } finally {
            setTicketIsLoading(false); // MayBeToRemove
            AlertSwal.close();
            push(ROUTE_SIM_EXIT);
        }
    };

    const onChangeNiveauAmelioration = async (selection: Labelled<NiveauAmelioration> | null): Promise<void> => {
        if (selection == null) return;
        const target: RenovationDAmpleurTarget = { niveau: selection.value, sortiePassoire: draTarget.sortiePassoire };
        setDraTarget(target);
        await RefreshAll({ draParams, draTarget: target });
    };

    const toggleSortiePassoire = async (selection: Labelled<boolean> | null): Promise<void> => {
        if (selection === null) return;
        const target: RenovationDAmpleurTarget = { niveau: draTarget.niveau, sortiePassoire: selection.value };
        setDraTarget(target);
        await RefreshAll({ draParams, draTarget: target });
    };

    const aidesLocalesBuilder = async (
        aidesLocales: ticketService.AidesLocalesList,
        allThemesClone: Array<themesService.Theme>,
        ticket: ticketService.TicketData,
        context: { reno: RenovationDAmpleurElligible; params: RenovationDAmpleurTarget }
    ): Promise<ticketService.AidesLocalesList> => {
        // maintenant que l'utilsateur a changé certaines selection,
        // on applique les exceptions
        // forcer le re-render de l'ecran conteneur.
        // let fraisRenAmp: ticketService.Aide | undefined = undefined;
        // let aideFraisRenAmp: ticketService.Aide | undefined = undefined;
        let aideRenAmp: ticketService.Aide | undefined = undefined;

        if (storageService.isCurrentAuditMar() && draParams.elligible) {
            // fraisRenAmp = ticketService.createFraisRenoDAmpleur(context.reno, context.params);
            // aideFraisRenAmp = ticketService.createAideFraisRenoDAmpleur(context.reno, context.params);
            aideRenAmp = ticketService.createAideRenoDAmpleur(context.reno, context.params, ticket);
        }
        // On recalcule les aides locales liées aux packages.
        const aides: ticketService.AidesLocalesList = {
            ...aidesLocales,
            // [ticketService.FRAIS_EXPERTISE_RENO_AMPLEUR]: fraisRenAmp,
            // [ticketService.AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR]: aideFraisRenAmp,
            [ticketService.AIDE_RENO_AMPLEUR]: aideRenAmp,
        };
        //prettyLog('ticketContainer.RefreshAll.aide CALLBACK ', aides);
        return aides;
    };

    const RefreshAll = async (context: { draParams: RenovationDAmpleurParams; draTarget: RenovationDAmpleurTarget }): Promise<void> => {
        setTicketIsLoading(true);
        // On fait un gros clone profond, pour que tout soit en lecture écriture.
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
        const ticketClone: ticketService.TicketData = JSON.parse(JSON.stringify(ticket)) as ticketService.TicketData;

        // force le re-render de l'ecran parent
        // On vient de changer des paramètres locaux, donc on a pas besoin de faire un appel api
        // La fonction update ticket n'appellera pas l'api si on lui passe le ticket en parametre
        await updateTicket(allThemesClone, setThemes, ticketClone, setTicket, aidesLocales, setAidesLocales, aidesLocalesBuilder, context);
        setTicketIsLoading(false);
    };
    return (
        <div className="ticket-sticky">
            <div className="ticket">
                <div className="ticket-header">
                    <h2>Ticket client</h2>
                </div>

                <div className="ticket-body">
                    <div className="client-detail">
                        <ul className="client-detail-list">
                            <li key="Client_8ea27de7-3882-4bca-b99b-97dba909488c">Client: {client()}</li>
                            <li key="Conseiller_8ea27de7-3882-4bca-b99b-97dba909488c">Conseiller: {agent()}</li>
                        </ul>
                    </div>

                    {isLocal() && (
                        <div>
                            <button
                                onClick={() => {
                                    setTicketIsLoading(true);
                                    // On fait un gros clone profond, pour que tout soit en lecture écriture.
                                    const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
                                    themesTools.clearSelectedPackages(allThemesClone);
                                    const ticketClone: ticketService.TicketData = JSON.parse(JSON.stringify(ticket)) as ticketService.TicketData;

                                    // force le re-render de l'ecran parent
                                    // On vient de changer des paramètres locaux, donc on a pas besoin de faire un appel api
                                    // La fonction update ticket n'appellera pas l'api si on lui passe le ticket en parametre
                                    updateTicket(allThemesClone, setThemes, ticketClone, setTicket, aidesLocales, setAidesLocales, aidesLocalesBuilder).then(
                                        () => {
                                            setTicketIsLoading(false);
                                        }
                                    );
                                }}
                            >
                                Vider les packages
                            </button>
                        </div>
                    )}

                    <div className="command-recap">
                        <h3 className="command-recap-title">Commande</h3>

                        <ul className="command-recap-list">
                            {buildCartArticles()}
                            {storageService.isCurrentAuditMar() && draParams.elligible && (
                                <li key="sous_total_44444444-3357-4bce-a8f6-ac0f2009a796">
                                    <span>Sous-total HT</span>
                                    <span>{ticket.montant_vente_ht}</span>
                                </li>
                            )}
                            <li key="sous_total_2f6ede10-3357-4bce-a8f6-ac0f2009a796">
                                <span>Sous-total TTC</span>
                                <span>{ticket.montant_vente}</span>
                            </li>
                        </ul>

                        {!storageService.isCurrentAuditMar() || !draParams || !draParams.elligible ? (
                            <div className="command-recap-aide">
                                <h3 className="command-recap-title">Aides</h3>
                                <ul className="command-recap-list">{buildAidesNoRA()}</ul>
                            </div>
                        ) : (
                            <div>
                                {buildAidesCompatibleRA()}
                                <div className="command-recap-aide">
                                    <h3
                                        className="command-recap-title"
                                        title={
                                            draParams.elligible
                                                ? JSON.stringify({
                                                      cat: draParams.categorieRevenus,
                                                      max: RENO_PLAFOND_CONST[draTarget.niveau],
                                                      ratio:
                                                          RENO_VALUES[draParams.categorieRevenus][draTarget.niveau] +
                                                          (draTarget.sortiePassoire ? BONIFICATION_PASSOIRE : 0),
                                                  })
                                                : ''
                                        }
                                    >
                                        Rénovation d'ampleur
                                    </h3>
                                    Saut de classes
                                    <div className="input-display">
                                        <Select
                                            options={niveauAmeliorationList}
                                            defaultValue={niveauAmeliorationList[0]}
                                            value={renderDefaultValueSelectV2(niveauAmeliorationList, draTarget.niveau)}
                                            styles={Styles.styleForRenoNiveau}
                                            isClearable={false}
                                            isSearchable={false}
                                            className="mb-2"
                                            onChange={onChangeNiveauAmelioration}
                                        />
                                    </div>
                                    Sortie de passoire
                                    <div className="input-display">
                                        <Select
                                            options={sortiePassoireList}
                                            value={renderDefaultValueSelectV2(sortiePassoireList, draTarget.sortiePassoire)}
                                            styles={Styles.styleForRenoSortiePAssoire}
                                            isClearable={false}
                                            isSearchable={false}
                                            className="mb-2"
                                            onChange={toggleSortiePassoire}
                                        />
                                    </div>
                                    <ul className="command-recap-list">{buildAidesRA()}</ul>
                                </div>
                            </div>
                        )}

                        <div className="command-recap-total">
                            <ul className="command-recap-list">
                                <li key="total_aide_d6a39b84-4da0-464a-be2f-666636be3945">
                                    <span>Total des aides</span>
                                    <span>{ticket.montant_aides_cumul}</span>
                                </li>
                                <li key="reste_a_charge_841ccd5d-a217-4f53-83bf-2a7a6035049f">
                                    <span>{storageService.isCurrentAuditMar() ? 'Reste à charge (TTC)' : 'Total'}</span>
                                    <span>{ticket.reste_a_charge}</span>
                                </li>
                            </ul>
                        </div>
                        {warningEmptyDevis()}
                        <button
                            className="btn btn-valid"
                            onClick={(e) => validateAndNavigate()}
                            disabled={(storageService.isCurrentAuditMar() && (!draParams || !draParams.elligible)) || ticketValidateDisable}
                            //disabled={ticketValidateDisable}
                        >
                            Valider
                        </button>
                        <button className="btn btn-valid" onClick={(e) => receiveDevis()}>
                            Exit
                        </button>
                    </div>
                </div>
            </div>
            <EcoTicket />
            <BecomePartnerComponent />
        </div>
    );
};

export default TicketComponent;
