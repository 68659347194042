import React from 'react';

// Autres
import other from '../../assets/images/housing/autres.svg';

// Plafonds
import ceilingNonAmenaged from '../../assets/images/housing/combles-non-amenages.svg';
import ceilingAmenaged from '../../assets/images/housing/combles-amenages.svg';

// Niveaux
import floorLevel from '../../assets/images/housing/niveau.svg';
import floorBase from '../../assets/images/housing/niveau-base.svg';

// Planchers
import floorGround from '../../assets/images/housing/plancher-sur-terre-plein.svg';
import floorBasement from '../../assets/images/housing/plancher-sur-sous-sol.svg';
import floorCrawlSpace from '../../assets/images/housing/plancher-sur-vide-sanitaire.svg';

// Style
import './HousingDiagram.scss';

interface HousingDiagramProps {
    ceilingType?: string | undefined;
    levels?: string | undefined;
    floorType?: string | undefined;
}

const HousingDiagram: React.FC<HousingDiagramProps> = ({ ceilingType = '0', levels = '2', floorType = '3' }) => {
    const renderCeilingImage = () => {
        switch (ceilingType) {
            case '0': // Combles non aménagés
                return <img src={ceilingNonAmenaged} alt="Combles non aménagés" className="diagram-layer ceiling-move" />;
            case '2': // Combles aménagés
                return <img src={ceilingAmenaged} alt="Combles aménagés" className="diagram-layer ceiling-move" />;
            case '3': // Toiture terrasse
                return <img src={other} alt="Toiture terrasse" className="diagram-layer ceiling" style={{ transform: 'translateY(3px)' }} />;
            case '4': // Plafond mitoyen
                return <img src={other} alt="Plafond mitoyen" className="diagram-layer ceiling" style={{ transform: 'translateY(3px)' }} />;
            default:
                return null;
        }
    };

    const renderFloorLevels = () => {
        return (
            <>
                {Array.from({ length: Math.max(0, +levels - 1 ?? 0) }, (_, index) => (
                    <img key={`floor-${index}`} src={floorLevel} alt={`Etage ${index + 1}`} className="diagram-layer floor-level" />
                ))}
                <img src={floorBase} alt="Rez de chaussée" className="diagram-layer floor-base" />
            </>
        );
    };

    const renderFloorTypeImage = () => {
        switch (floorType) {
            case '0': // Plancher sur terre-plein
                return <img src={floorGround} alt="Sur terre-plein" className="diagram-layer floor-type" />;
            case '2': // Plancher sur vide sanitaire
                return <img src={floorCrawlSpace} alt="Sur vide sanitaire" className="diagram-layer floor-type" />;
            case '3': // Plancher sur sous-sol
                return <img src={floorBasement} alt="Sur sous-sol" className="diagram-layer floor-type" />;
            case '4': // Plancher sur toiture
                return <img src={other} alt="Sur toiture" className="diagram-layer floor-type" style={{ transform: 'rotate(180deg) translateY(1px)' }} />;
            default:
                return null;
        }
    };

    return (
        <div className="housing-diagram">
            <div className="diagram-container">
                <div className="ceiling-container">{renderCeilingImage()}</div>
                <div className="floors-container">{renderFloorLevels()}</div>
                <div className="floor-type-container">{renderFloorTypeImage()}</div>
            </div>
        </div>
    );
};

export default HousingDiagram;
