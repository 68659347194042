import React, { useEffect, useRef, useState } from 'react';
import { differenceInSeconds, endOfDay, isToday } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { AidesLocalesList, localAidesNames } from '../../../services/calculs/ticket';
import { aidesLocalesListStateAtom } from '../../../services/Recoil/Atom/Themes.atom';
import { currencyFormat } from '../../../services/tools/TypeHelper';

// Icon
import { ReactComponent as IconChevron } from '../../../assets/icons/icon-chevron.svg';

// Style
import './Countdown.scss';

interface CountDownProps {
    date: string | Date | null;
}

export const Countdown: React.FC<CountDownProps> = (props: CountDownProps) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [collapse, setCollapse] = useState<boolean>(true);
    const [timeRemaining, setTimeRemaining] = useState<number>(0);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const aidesLocales = useRecoilValue<AidesLocalesList>(aidesLocalesListStateAtom);

    // Drag and Drop State
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    // Position of element according to its height/width
    useEffect(() => {
        if (elementRef.current) {
            const elementHeight = elementRef.current.offsetHeight;
            const elementWidth = 450;

            setPosition({
                top: window.innerHeight - 80 - elementHeight, // Simulate bottom: 80
                left: window.innerWidth - 20 - elementWidth, // Simulate right: 20
            });
        }
    }, []);

    // Handle Mouse Down
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setDragging(true);
        setDragStart({ x: e.clientX - position.left, y: e.clientY - position.top });
    };

    // Handle Mouse Move
    const handleMouseMove = (e: MouseEvent) => {
        if (dragging) {
            setPosition({
                top: e.clientY - dragStart.y,
                left: e.clientX - dragStart.x,
            });
        }
    };

    // Handle Mouse Up
    const handleMouseUp = () => {
        setDragging(false);
    };

    // Add and remove event listeners
    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    const resetTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }

        if (!collapse) {
            setTimer(
                setTimeout(() => {
                    setCollapse(true);
                }, Number(process.env.REACT_APP_COUNTDOWN_AIDES_MS ?? 60000))
            ); // 60000 ms = 1 minute
        }
    };

    useEffect(() => {
        resetTimer();
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse]);

    const handleCollapse = () => {
        setCollapse((prevState) => !prevState);
    };

    useEffect(() => {
        if (!props.date) return;
        const target = typeof props.date === 'string' ? new Date(props.date) : props.date;
        const endOfToday = endOfDay(new Date());

        let finalTargetDate: Date;

        if (isToday(target)) {
            finalTargetDate = endOfToday;
        } else {
            finalTargetDate = target;
        }

        const updateRemainingTime = () => {
            const now = new Date();
            const difference = differenceInSeconds(finalTargetDate, now);
            setTimeRemaining(difference > 0 ? difference : 0);
        };

        updateRemainingTime();
        const interval = setInterval(updateRemainingTime, 1000);

        return () => clearInterval(interval);
    }, [props.date]);

    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const calculateTotalAmount = () => {
        if (!aidesLocales) return 0;

        return -Object.values(aidesLocales).reduce((total, aide) => {
            if (aide && aide.montant !== 0) {
                return total + aide.montant;
            }
            return total;
        }, 0);
    };

    const totalAmount = calculateTotalAmount();

    return (
        <div
            ref={elementRef}
            className="countdown"
            data-collapse={collapse}
            onMouseDown={handleMouseDown}
            style={{
                top: position.top,
                left: position.left,
                cursor: dragging ? 'grabbing' : 'grab',
            }}
        >
            <div className="countdown__header">
                <button type="button" className="btn btn-collapse" data-collapse={collapse} onClick={handleCollapse}>
                    <IconChevron fill="#6c6f65" width={18} />
                </button>
                {!collapse && (
                    <p>
                        Profitez de <strong className="fs-5">{currencyFormat(totalAmount, true)}</strong> d'aides
                    </p>
                )}
                <div className="timer">{formatTime(timeRemaining)}</div>

                {collapse && (
                    <p>
                        Plus que quelques minutes pour
                        <br />
                        bénéficier de vos aides
                    </p>
                )}
            </div>
            {collapse && (
                <div className="countdown__body">
                    <ul className="countdown__body-list">
                        {localAidesNames.map((aideName) => {
                            const aide = aidesLocales[aideName];
                            if (aide && aide.montant !== 0) {
                                return (
                                    <li key={aideName}>
                                        <p>{aide.titre} :</p>
                                        <span>{aide.montant_lbl_abs}</span>
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ul>

                    <p className="total">Total des aides : {currencyFormat(totalAmount, true)}</p>

                    <div className="btn-grp justify-content-center mt-3">
                        <button type="button" className="btn btn-useless" onClick={() => setCollapse(false)}>
                            Valider
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
