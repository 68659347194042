import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';
import CheckboxService from '../../components/checkbox/checkboxService';
import {
    improvementsWinter,
    improvementsSummer,
    improvementsAirRenewal,
    improvementsAcousticsLightning,
    improvementsWorkProject,
    improvementsLifeFamily,
    improvementsGrantsCredits,
    improvementsDPE,
    improvementsQuotesForWork,
    pathology1,
    pathology2,
    pathology3,
    pathology4,
    pathology5,
    pathology6,
    pathology7,
    ventilationProblems,
} from './services/handleCheckbox';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as IconUpgrade } from '../../assets/icons/icon-upgrade.svg';
import { ReactComponent as IconList } from '../../assets/icons/icon-list.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';

import * as Yup from 'yup';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ROUTE_AUDIT_INFO_EVELOPPE } from '../../routing/paths';
import { useStepNavigation } from '../../routing/StepNavigationContext';

const CollectionsReportsInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const AlertSwal = withReactContent(Swal);

    const buildingPicturesPathology1 = useRef(null);
    const buildingPicturesPathology2 = useRef(null);
    const buildingPicturesPathology3 = useRef(null);
    const buildingPicturesPathology4 = useRef(null);
    const buildingPicturesPathology5 = useRef(null);
    const buildingPicturesPathology6 = useRef(null);
    const buildingPicturesPathology7 = useRef(null);

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;

        // Mise à jour du formulaire
        if (event.hasOwnProperty('target')) {
            handleChange(event);
        }

        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                if (event.hasOwnProperty('target')) {
                    return property.value === event.target.value;
                }
                return property.value === event.value;
            });
            return storageService.setAuditValue(step, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }

        // Cas standard
        return storageService.setAuditValue(step, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    /**
     * Valeur lors de l'initialisation de la page
     */
    const initialValues = {
        improvementsWinter: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsWinter',
            `Vous souhaitez améliorer votre confort d'hiver`,
            PropertyType['basic']
        ),
        improvementsSummer: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsSummer',
            `Vous souhaitez améliorer votre confort d'été`,
            PropertyType['boolean']
        ),
        improvementsAirRenewal: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsAirRenewal',
            `Vous souhaitez améliorer le renouvellement d’air`,
            PropertyType['boolean']
        ),
        improvementsAcousticsLightning: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsAcousticsLightning',
            `Vous souhaitez améliorer votre l’acoustique et l’éclairage`,
            PropertyType['boolean']
        ),
        improvementsWorkProject: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsWorkProject',
            `Vous avez un projets de travaux`,
            PropertyType['boolean']
        ),
        improvementsLifeFamily: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsLifeFamily',
            `Vous avez un projets de vie, composition de la famille`,
            PropertyType['boolean']
        ),
        improvementsGrantsCredits: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsGrantsCredits',
            `Avez vous déjà obtenu des Subventions ou crédits pour des travaux de rénovation énergétique`,
            PropertyType['boolean']
        ),
        improvementsDPE: storageService.checkPropertyExistThenCreateOrRender(5, 'improvementsDPE', `Avez vous déjà réalisé un DPE`, PropertyType['boolean']),
        improvementsQuotesForWork: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsQuotesForWork',
            `Avez vous déjà réalisé des devis de travaux`,
            PropertyType['boolean']
        ),
        improvementsComments: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'improvementsComments',
            `Précisez ce que vous souhaitez améliorer`,
            PropertyType['boolean']
        ),
        otherPathology: storageService.checkPropertyExistThenCreateOrRender(5, 'otherPathology', `Autres pathologie constatée`, PropertyType['boolean']),
        pathology1: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'pathology1',
            `Manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs`,
            PropertyType['boolean']
        ),
        pathology2: storageService.checkPropertyExistThenCreateOrRender(5, 'pathology2', `Moisissures dans l’habitation`, PropertyType['boolean']),
        pathology3: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'pathology3',
            `Moisissures dans l’isolation thermique intérieur (ITI)`,
            PropertyType['boolean']
        ),
        pathology4: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'pathology4',
            `Fissure dans l’isolation thermique extérieur (ITE)`,
            PropertyType['boolean']
        ),
        pathology5: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'pathology5',
            `Moisissures dans l'isolation thermique extérieur (ITE)`,
            PropertyType['boolean']
        ),
        pathology6: storageService.checkPropertyExistThenCreateOrRender(5, 'pathology6', `Qualité́ de l’air intérieur dégradé`, PropertyType['boolean']),
        pathology7: storageService.checkPropertyExistThenCreateOrRender(5, 'pathology7', `Condensation`, PropertyType['boolean']),
        ventilationProblems: storageService.checkPropertyExistThenCreateOrRender(
            5,
            'ventilationProblems',
            `L’habitation comporte t’elle des anomalies sur l’étancheité à l’air ?`,
            PropertyType['boolean']
        ),

        buildingPicturesPathology1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology1',
            `Photo du manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs`,
            PropertyType['photo']
        ),
        buildingPicturesPathology2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology2',
            `Photo de moisissures dans l'habitation`,
            PropertyType['photo']
        ),
        buildingPicturesPathology3: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology3',
            `Photo de moisissures dans l'isolsation thermique éxterieur (ITI)`,
            PropertyType['photo']
        ),
        buildingPicturesPathology4: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology4',
            `Photo de fissure dans l'isolation thermique éxterieur (ITE)`,
            PropertyType['photo']
        ),
        buildingPicturesPathology5: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology4',
            `Photo de moisissures dans l'islation thermique éxterieur (ITE)`,
            PropertyType['photo']
        ),
        buildingPicturesPathology6: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology6',
            `Photo de la qualité de l'air dégradé`,
            PropertyType['photo']
        ),
        buildingPicturesPathology7: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesPathology7',
            `Photo de la condensation`,
            PropertyType['photo']
        ),
    };

    /**
     * Schéma de validation
     */
    const validationSchema = Yup.object({
        improvementsWinter: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous souhaitez améliorer votre confort l'hiver`)
            .test('improvementsWinter', `Vous n'avez pas précisé si vous souhaitez améliorer votre confort l'hiver`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsSummer: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous souhaitez améliorer votre confort d'été`)
            .test('improvementsWinter', `Vous n'avez pas précisé si vous souhaitez améliorer votre confort d'été`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsAirRenewal: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous souhaitez améliorer le renouvellement de l'air`)
            .test('improvementsAirRenewal', `Vous n'avez pas précisé si vous souhaitez améliorer le renouvellement de l'air`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsAcousticsLightning: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous souhaitez améliorer votre acoustique et l'éclairage`)
            .test('improvementsAcousticsLightning', `Vous n'avez pas précisé si vous souhaitez améliorer votre acoustique et l'éclairage`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsWorkProject: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous avez des projets de travaux`)
            .test('improvementsWorkProject', `Vous n'avez pas précisé si vous avez des projets de travaux`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsLifeFamily: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous avez un projet de vie, composition de famille`)
            .test('improvementsLifeFamily', `Vous n'avez pas précisé si vous avez un projet de vie, composition de famille`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsGrantsCredits: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous avez déja obtenu des subventions ou crédits pour des travaux de rénobation énergétique`)
            .test(
                'improvementsGrantsCredits',
                `Vous n'avez pas précisé si vous avez déja obtenu des subventions ou crédits pour des travaux de rénobation énergétique`,
                (value) => {
                    return [true, false].includes(value as boolean);
                }
            ),
        improvementsDPE: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous avez déja réalisé un DPE`)
            .test('improvementsDPE', `Vous n'avez pas précisé si vous avez déja réalisé un DPE`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsQuotesForWork: Yup.boolean()
            .required(`Vous n'avez pas précisé si vous avez déja réalisé des devis de travaux`)
            .test('improvementsQuotesForWork', `Vous n'avez pas précisé si vous avez déja réalisé des devis de travaux`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        improvementsComments: Yup.string(),
        otherPathology: Yup.string(),
        pathology1: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs`)
            .test(
                'pathology1',
                `Vous n'avez pas précisé si l'habitation manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs`,
                (value) => {
                    return [true, false].includes(value as boolean);
                }
            ),
        pathology2: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte des moisissures`)
            .test('pathology2', `Vous n'avez pas précisé si l'habitation comporte des moisissures`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        pathology3: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte des moisissures dans l'isolation thermique intérieur (ITI)`)
            .test('pathology3', `Vous n'avez pas précisé si l'habitation comporte des moisissures dans l'isolation thermique intérieur (ITI)`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        pathology4: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation compote des fissure dans l'isolation thermique extérieur (ITE)`)
            .test('pathology4', `Vous n'avez pas précisé si l'habitation compote des fissure dans l'isolation thermique extérieur (ITE)`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        pathology5: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte des moisissures dans l'isolation thermique extérieur (ITE)`)
            .test('pathology5', `Vous n'avez pas précisé si l'habitation comporte des moisissures dans l'isolation thermique extérieur (ITE)`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        pathology6: Yup.boolean()
            .required(`Vous n'avez pas précisé si la qualité de l'air intérieur est dégradé`)
            .test('pathology6', `Vous n'avez pas précisé si la qualité de l'air intérieur est dégradé`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        pathology7: Yup.boolean()
            .required(`Vous n'avez pas précisé la présence de condensation dans l'habitation`)
            .test('pathology7', `Vous n'avez pas précisé la présence de condensation dans l'habitation`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        ventilationProblems: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte des anomalies sur l'étanchéité à l'air`)
            .test('ventilationProblems', `Vous n'avez pas précisé si l'habitation comporte des anomalies sur l'étanchéité à l'air`, (value) => {
                return [true, false].includes(value as boolean);
            }),

        buildingPicturesPathology1: Yup.string().notRequired(),
        buildingPicturesPathology2: Yup.string().notRequired(),
        buildingPicturesPathology3: Yup.string().notRequired(),
        buildingPicturesPathology4: Yup.string().notRequired(),
        buildingPicturesPathology5: Yup.string().notRequired(),
        buildingPicturesPathology6: Yup.string().notRequired(),
        buildingPicturesPathology7: Yup.string().notRequired(),
    });

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            // isInitialValid={storageService.getAudit().step5.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
        >
            {({ values, setValues, handleChange, isValid, touched, setFieldValue, handleBlur, errors }) => {
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(5, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (5 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                return (
                    <div className="container">
                        <h1 className="main-title-mini">5. Recueil & constat</h1>
                        <h2 className="main-subtitle">
                            Recueil d’informations et des besoins du maître d'ouvrage
                            <div className="help-text" data-tip data-for="helpOuvrage">
                                <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                            </div>
                        </h2>

                        <ReactTooltip id="helpOuvrage" place="bottom" type={'light'} effect={'solid'}>
                            <p>Le Maitre d’ouvrage = le client / commanditaire</p>
                        </ReactTooltip>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconUpgrade fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Améliorations souhaitées</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <CheckboxService
                                                handleCheckbox={improvementsWinter}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsWinter'}
                                                propName={values.improvementsWinter}
                                                title={"Vous souhaitez améliorer votre confort d'hiver"}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={improvementsSummer}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsSummer'}
                                                propName={values.improvementsSummer}
                                                title={"Vous souhaitez améliorer votre confort d'été"}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <CheckboxService
                                                handleCheckbox={improvementsAirRenewal}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsAirRenewal'}
                                                propName={values.improvementsAirRenewal}
                                                title={'Vous souhaitez améliorer le renouvellement d’air'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={improvementsAcousticsLightning}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsAcousticsLightning'}
                                                propName={values.improvementsAcousticsLightning}
                                                title={'Vous souhaitez améliorer votre l’acoustique et l’éclairage'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <CheckboxService
                                                handleCheckbox={improvementsWorkProject}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsWorkProject'}
                                                propName={values.improvementsWorkProject}
                                                title={'Vous avez un projets de travaux'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={improvementsLifeFamily}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsLifeFamily'}
                                                propName={values.improvementsLifeFamily}
                                                title={'Vous avez un projets de vie, composition de la famille'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <CheckboxService
                                                handleCheckbox={improvementsGrantsCredits}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsGrantsCredits'}
                                                propName={values.improvementsGrantsCredits}
                                                title={'Avez vous déjà obtenu des Subventions ou crédits pour des travaux de rénovation énergétique'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={improvementsDPE}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsDPE'}
                                                propName={values.improvementsDPE}
                                                title={'Avez vous déjà réalisé un DPE'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={improvementsQuotesForWork}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'improvementsQuotesForWork'}
                                                propName={values.improvementsQuotesForWork}
                                                title={'Avez vous déjà réalisé des devis de travaux'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-md-0 mb-3">
                                            <label htmlFor="improvementsComments" className="form-label">
                                                Précisez ce que vous souhaitez améliorer
                                            </label>
                                            <textarea
                                                rows={5}
                                                className={'form-control'}
                                                placeholder="Nous n’avons pas plus de précisions à apporter"
                                                name="improvementsComments"
                                                id="improvementsComments"
                                                onBlur={handleBlur}
                                                value={values.improvementsComments || ''}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 5, 'improvementsComments')}
                                            ></textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconList fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Liste des Pathologies constatées dans l’habitation</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3 align-items-end">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            {values.pathology1 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology1}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology1'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology1}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology1'}
                                                propName={values.pathology1}
                                                title={'Manque de calfeutrage entre la maçonnerie et le châssis des menuiseries extérieurs'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {values.pathology2 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology2}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology2'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology2}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology2'}
                                                propName={values.pathology2}
                                                title={'Moisissures dans l’habitation'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3 align-items-end">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            {values.pathology3 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology3}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology3'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology3}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology3'}
                                                propName={values.pathology3}
                                                title={'Moisissures dans l’isolation thermique intérieur (ITI)'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {values.pathology4 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology4}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology4'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology4}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology4'}
                                                propName={values.pathology4}
                                                title={'Fissure dans l’isolation thermique extérieur (ITE)'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3 align-items-end">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            {values.pathology5 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology5}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology5'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology5}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology5'}
                                                propName={values.pathology5}
                                                title={"Moisissures dans l'isolation thermique extérieur (ITE)"}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {values.pathology6 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology6}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology6'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology6}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology6'}
                                                propName={values.pathology6}
                                                title={'Qualité́ de l’air intérieur dégradé'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-end mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            {values.pathology7 && (
                                                <div className="d-flex">
                                                    <AddStepPhotos
                                                        prop={buildingPicturesPathology7}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesPathology7'}
                                                    />
                                                </div>
                                            )}
                                            <CheckboxService
                                                handleCheckbox={pathology7}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pathology7'}
                                                propName={values.pathology7}
                                                title={'Condensation'}
                                            />
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={ventilationProblems}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'ventilationProblems'}
                                                propName={values.ventilationProblems}
                                                title={'L’habitation comporte t’elle des anomalies sur l’étanchéité à l’air ?'}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="otherPathology" className="form-label">
                                                Autre pathologie constatée
                                            </label>
                                            <textarea
                                                rows={5}
                                                className={'form-control'}
                                                placeholder="Autre pathologie constatée"
                                                name="otherPathology"
                                                id="otherPathology"
                                                onBlur={handleBlur}
                                                value={values.otherPathology || ''}
                                                onChange={(event) => updateValue(event, handleChange, isValid, 5, 'otherPathology')}
                                            ></textarea>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(5, '/audit')}>
                                Retour au menu principal
                            </button>

                            <button
                                type="button"
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    if (Object.entries(errors).length !== 0) {
                                        // Préparation liste d'étapes en erreur
                                        let errorsList: string[] = [];
                                        Object.keys(errors).forEach((field: any) => {
                                            // @ts-ignore
                                            if (errors[field] !== '') {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[field]}`];
                                            }
                                        });
                                        // Affichage message
                                        AlertSwal.fire({
                                            title: `Champs manquants`,
                                            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                        <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                            cancelButtonText: 'Continuer la saisie',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        });
                                    } else {
                                        validateThenRoute(5, ROUTE_AUDIT_INFO_EVELOPPE);
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default CollectionsReportsInformations;
