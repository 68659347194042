import { filterMethod, logRef } from '../filterPreconisation';
import { PackageData } from '../package';
import { SubTheme } from '../theme';
import * as api from '../../apiParticulierService';
import * as tools from './filterChauffageTools';
import { EnergyLossInput } from '../../apiParticulierService';

export const PAC_RATIO_DEPERDITION_BESOIN_CHAUFFAGE = 1.3;
export const PAC_TOLERENCE = 1.1;

/**
 * filtrer les packages inutiles du theme 'Chauffage'. Le theme sera modifé et retourné
 * @param audit l'audit sur lequel on préconise
 * @param subTheme le theme dont le type doit être 'Chauffage'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
export const filterChauffage: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //logRef('filterChauffage', subTheme.packages);
    //console.log('passed in filterChauffage, input count = ' + theme.packages.length);

    // si pas de package, on laisse tomber, c'est déjà filtré !
    if (subTheme.packages.length === 0) return subTheme;

    // algo :
    // séparer les pac AA et les pac AE
    // trouver la bonne pac AE,
    // trouver la bonne pac AA,
    // les merger dans une liste et les reoturner.

    let { pacsAA, pacsAE } = tools.dispatchPacByType(subTheme);

    // trouver LA seule pac AE
    const pacAE = await getAllPacAE(audit, pacsAE);
    // trouver LA seule pac AA
    const pacAA = getTheOnePacAA(audit, pacsAA);

    // merger les deux packs dans une liste.
    const packs = Array<PackageData>();

    if (pacAE) {
        packs.push(...pacAE); // ces pac sont selectionnées !
    }
    if (pacAA) {
        packs.push(pacAA);
        // si Convecteur / panneaux rayonnant / Ventilo convecteur, on selectionne cette pacAA.
        const heater = audit.heaterFeature.value;
        pacAA.applicable = heater === '0' || heater === '1' || heater === '2';
    }

    // log
    // if (pacAE) console.log('Final selected pacAE : ' + pacAE.reference + ' => ' + extractNbSplit(pacAE) + ' / ' + pacAE.mainProduct.puissance);
    // else console.log('Final selected pacAE : undefined');
    // if (pacAA) console.log('Final selected pacAA : ' + pacAA.reference + ' => ' + extractNbSplit(pacAA) + ' / ' + pacAA.mainProduct.puissance);
    // else console.log('Final selected pacAA : undefined');

    // utiliser la liste et la retourner
    subTheme.packages = packs;

    return subTheme;
};

/**
 * Cette fonction va choisir TOUTES les PAC AE, compatibles avec l'audit, selon les données de l'audit.
 * En fonction de la déperdition (cardonelle) de l'audit, et la déperdition plus favorable potentielle,
 * @param audit
 * @param pacsAE
 * @returns undefined si rien ne correspond. LE package PacAE selectionné (applicable = true) adapté à la la situation
 */
const getAllPacAE = async (audit: any, pacsAE: Array<PackageData>): Promise<Array<PackageData> | undefined> => {
    // si audit mal remplit ou si pas de packages
    if (pacsAE.length <= 0) return undefined;

    const auditSafeData = tools.extractOtherData(audit);
    if (!auditSafeData) return undefined;

    logRef('Départ AE', pacsAE);

    // on fait les filtrage dans un ordre précis.
    // certains filtres peuvent être réordonnés, d'autres non.
    // Il faut finir par la selection par puissance.

    // on retire les pac pas adapté au compteur.
    pacsAE = pacsAE.filter(auditSafeData.isTriphase ? tools.keepPacTriphase : tools.keepPacMonophase);
    logRef(auditSafeData.isTriphase ? 'Triphasé' : 'Monophasé', pacsAE);

    // on selectionne les PAC qui ont (ou pas) l'eau chaude l'iée au chauffage => -PEC-
    pacsAE = pacsAE.filter(auditSafeData.isPec ? tools.keepPacPec : tools.removePacPec);
    logRef(auditSafeData.isPec ? 'PEC' : '! PEC', pacsAE);

    if (auditSafeData.isMultipleHydronicZone) {
        // si on a deux zone hydronique
        // on prend les pac qui ont -2R-
        pacsAE = pacsAE.filter(tools.keepPac2R);
        logRef('2R', pacsAE);
    } else {
        // si on a qu'une zone hydronique, filtre et on garde que les non -2R-
        pacsAE = pacsAE.filter(tools.removePac2R);
        logRef('! 2R', pacsAE);

        // ensuite selon le type de radiateur :

        // si plancher chauffant (heater === '6'),
        // on garde les pac Basse temperature, sinon, on les enlève.
        pacsAE = pacsAE.filter(auditSafeData.heater === '6' ? tools.keepPacAEBasseTemp : tools.removePacAEBasseTemp);

        // si radiateur (avec ou sans vanne) (heater === '4' || heater === '5'),
        // on garde les pac moyenne et haute temperature, sinon, on les enlève.
        pacsAE = pacsAE.filter(
            auditSafeData.heater === '4' || auditSafeData.heater === '5' ? tools.keepPacAEMoyenneHauteTemp : tools.removePacAEMoyenneHauteTemp
        );

        logRef("type d'emmeteur " + auditSafeData.heater + ' (' + auditSafeData.heaterName + ')', pacsAE);
    }

    pacsAE.sort(tools.compareBySplit);
    logRef('tri par puissance croissante', pacsAE);

    // Si il ne reste rien : on return undefined :
    if (pacsAE.length <= 0) {
        logRef('terminé sans résultat ');
        return undefined;
    }
    // On va ajouter des package construits "à la main" pour les grandes puissances.
    // ils sont composés de deux packages de puissance plus faibles.
    pacsAE = upgradePacAE(pacsAE);

    // ATTENTION : SI la logique change dans cette fonction,
    // il faut modifier la fonction redimenstionnerLaPac dans src/services/calculs/packageSelectorSpecialRules.ts

    // approximation initiale de la déperdition (au cas ou l'api foire)
    // ca va correspondre a la plus grande puissance de pacAE, qu'on prendra
    let deperditionMax = auditSafeData.surfaceHab / 10;
    // ca va correspondre a la plus petite puissance de pacAE, qu'on prendra
    let deperditionMin = auditSafeData.surfaceHab / 10;
    try {
        const energyLossInputDefavorable = api.exctractAndCheckEnergyLossInfo(audit);
        if (energyLossInputDefavorable) {
            // Dans le pire des cas, aucun changement d'isolation n'est fait, la déperdition sera maximale
            // et la pac sera de plus forte puissance.
            const energyLossOutput = await api.getEnergyLoss(energyLossInputDefavorable);
            deperditionMax = energyLossOutput.deperditions;

            // Dans le meilleur des cas, toutes les isolations sont faites, la déperdition sera minimale
            // et la pac sera de plus faible puissance.
            const energyLossInputFavorable: EnergyLossInput = { ...energyLossInputDefavorable };
            energyLossInputFavorable.murs = true;
            energyLossInputFavorable.combles = true;
            energyLossInputFavorable.plancher = true;
            energyLossInputFavorable.doubleVitrage = true;
            energyLossInputFavorable.hygroreglable = true;
            const energyLossOutputFavorable = await api.getEnergyLoss(energyLossInputFavorable);
            deperditionMin = energyLossOutputFavorable.deperditions;
        }
    } catch (ex) {
        // don't do anything use our approx.
        console.log('EnergyLoss fail = ' + JSON.stringify(ex, null, 2));
        // console.log('energyLossInput = null');
    }

    // On applique le facteur de 1.3 sur les déperditions. réunion avec ylan du 28/11/2024
    deperditionMin *= PAC_RATIO_DEPERDITION_BESOIN_CHAUFFAGE;
    deperditionMax *= PAC_RATIO_DEPERDITION_BESOIN_CHAUFFAGE;
    //console.log('energyLoss = ' + deperditionMin + ' < ' + deperditionMax);

    const selectedPack: Array<PackageData> = new Array<PackageData>();

    let index = 0;
    let lastIndex = 0;
    // maintenant qu'on a ordonné les puissances,
    // on va retenir les pac qui ont une puissance qui peuvent supporter ces deux déperditions
    for (const pack of pacsAE) {
        if (pack.puissance === undefined) continue; // théoriquement n'arrive pas.

        // Si la puissance est juste 10% sous la déperdition max, on la prend.
        // c'est a dire si la déperdition est 17, on ne devrait prendre une pac à 22.
        // Mais on tolère si la pac est moins de 10% en dessous => on prendrait la pac a 16.
        let puissanceToleree = pack.puissance * PAC_TOLERENCE;

        index++;
        // on prend toutes les pac dont la puissance est entre les deux energyLoss
        if (deperditionMin <= puissanceToleree && puissanceToleree <= deperditionMax) {
            selectedPack.push(pack);
            lastIndex = index;
            // Ca fonctionne car on les a trié par puissance croissante.
        }
    }
    console.log('nextIndex = ' + lastIndex + ' / ' + pacsAE.length);
    // On en rajoute 1, puisqu'on veut la puissance immédiatement supérieur au maximum.
    // Ca fonctionne puisqu'ils sont triés par puissance croissante.
    if (lastIndex < pacsAE.length) selectedPack.push(pacsAE[lastIndex]);

    if (selectedPack.length <= 0) {
        selectedPack.push(pacsAE[0]);
        //je vois pas comment c'est possible
        console.log('Smaller pac selected ! ');
    }

    // On selectionne le dernier PAC, parmis ceux retenus, celui qui a la plus grande puissance
    // puisqu'on commence dans le cas défavorable !
    // A noter, de toutes façon ca sera recauclué plus tard.
    const lastPack = selectedPack[selectedPack.length - 1];
    lastPack.applicable = true;
    logRef('selected AE  ', selectedPack);

    return selectedPack;
};

/**
 * Cette fonction va choisir une seule (ou zero) PAC AA, selon les données de l'audit
 * @param audit
 * @param pacsAA
 * @returns undefined si rien ne correspond. LE package PacAA non-selectionné (applicable = false) adapté à la la situation
 */
const getTheOnePacAA = (audit: any, pacsAA: Array<PackageData>): PackageData | undefined => {
    // si audit mal remplit ou si pas de packages
    if (!audit || !audit.generalOptionsMain || pacsAA.length <= 0) return undefined;

    let nbPieceAChauffer = 0;

    if (audit.generalOptionsMain && !isNaN(audit.generalOptionsMain.value)) {
        nbPieceAChauffer = +audit.generalOptionsMain.value; // QU'on se le dise, c'est le nombre de pièces.
    }
    //console.log('nombre de pièce à chauffer : ' + nbPieceAChauffer);
    // la c'est un poil compliqué :
    // je boucle sur les pack
    // je cherche celui qui a un nombre de split égal au nombre de piece a chauffer.
    // comme c'est pas sur que je trouve, je stocke le plus grand et le plus petit pour les ressortir en cas limite à la fin.

    let smallerNbSpit = 1000; // dummy value to find something smaller
    let smaller: PackageData | undefined = undefined;
    let biggerNbSplit = 0; // dummy value to find something bigger
    let bigger: PackageData | undefined = undefined;

    for (const pack of pacsAA) {
        const nbSplits = tools.extractNbSplit(pack);
        // Si j'ai le bon nombre de splits, on prend celui là et on arrête tout.
        if (nbPieceAChauffer === nbSplits) {
            //console.log('pack ' + pack.reference + ' => ' + extractNbSplit(pack) + ' / ' + pack.mainProduct.puissance);
            return pack;
        }
        // on enregistre le plus grand et le plus petit pour le cas ou on a pas le bon résultat.
        if (nbSplits > biggerNbSplit) {
            biggerNbSplit = nbSplits;
            bigger = pack;
        }
        if (nbSplits < smallerNbSpit) {
            smallerNbSpit = nbSplits;
            smaller = pack;
        }
    }

    // Si pas trouvé, check le plus petit et le plus grand.
    // on peut affecter deux fois si le plus petit = le plus grand
    if (nbPieceAChauffer >= biggerNbSplit) {
        // console.log('pack ' + bigger?.reference + ' (' + extractNbSplit(bigger!) + ' / ' + bigger?.mainProduct.puissance + ')');
        return bigger;
    }
    if (nbPieceAChauffer <= smallerNbSpit) {
        // console.log('pack ' + smaller?.reference + ' (' + extractNbSplit(smaller!) + ' / ' + smaller?.mainProduct.puissance + ')');
        return smaller;
    }
    return undefined;
};

/**
 * Cette fonction va ajouter des packages avec deux pac dedans.
 * Attention, ces packages ne correspondent pas à des produits icoll, ils sont construits "à la main".
 * Mais, comme on gère les quantités, ca devrait passer....
 * @param pacsAE
 * @returns
 */
export const upgradePacAE = (pacsAE: Array<PackageData>): Array<PackageData> => {
    // on prend la puissance de la plus grosse PAC.
    const PMAX = pacsAE.reduce((max, pac) => Math.max(max, pac.mainProduct.puissance ?? 0), 0);

    const newPacsAE = new Array<PackageData>();

    for (const pac of pacsAE) {
        if (!pac.mainProduct.puissance) continue;
        // Si prendre deux fois cette pac, donne une puissance plus élevée que le MAX,
        // on ajoute un package avec deux fois sa puissance..
        if (pac.mainProduct.puissance * 2 >= PMAX) {
            const doublePac = JSON.parse(JSON.stringify(pac)); // clone
            doublePac.uuid = Math.random().toString(36).substring(2); // nouvel uuid !!! important !
            doublePac.puissance = 2 * pac.mainProduct.puissance;
            doublePac.mainProduct.quantite = 2;
            if (doublePac.poseProduct) doublePac.poseProduct.quantite = 2;

            // Il faut recalculer les prix, puisqu'on a tout doublé !
            doublePac.priceHt = doublePac.mainProduct.quantite * doublePac.mainProduct.prix_ht;
            doublePac.priceTtc = doublePac.mainProduct.quantite * doublePac.mainProduct.prix_ht * (1 + doublePac.mainProduct.tva / 100.0);
            if (doublePac.poseProduct) {
                doublePac.priceHt += doublePac.poseProduct.quantite * doublePac.poseProduct.prix_ht;
                doublePac.priceTtc += doublePac.poseProduct.quantite * doublePac.poseProduct.prix_ht * (1 + doublePac.poseProduct.tva / 100.0);
            }

            // pour l'instant aucune aide, c'est pas là qu'on gère ça.
            doublePac.totalHelp = 0;
            doublePac.totalHelpDeduced = doublePac.priceTtc + doublePac.totalHelp;

            newPacsAE.push(doublePac);
        }
    }
    // Exemple à date :
    // disponible : 4.5*1, 6*1, 8*1, 11*1, 16*1, 22*1, 27*1.
    // on va ajouter : 16*2, 22x2 et 27x2.
    // on n'ajoute pas : 4*2 ou 11*2 car ils sont déjà couverts par 8 et 22.
    // On ajoute pas 3*16 ou 3*27 car on ne prend pas de package avec 3 PAC (apparement c'est pas possible techniquement)

    // on concatene les deux listes et on retourne le tout.
    return pacsAE.concat(newPacsAE);
};
