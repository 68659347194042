import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTE_SIM_BON_DE_COMMANDE, ROUTE_SIM_DEVIS, ROUTE_SIM_FINANCEMENT, ROUTE_SIM_RENDEMENT, ROUTE_SIM_TVA } from '../../../../routing/paths';
import { PropertyType } from '../../../../services/localStorageService';
import * as storageService from '../../../../services/localStorageService';

import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { currencyFormat } from '../../../../services/tools/TypeHelper';
import { DocumentsViewer } from '../../../../components/Document/DocumentsViewer';
import * as api from '../../../../services/apiParticulierService';
import * as bdc from '../../parcoursBdcCommmons';
import { ModalCounter } from '../../../../components/ModalCounter/ModalCounter';
import { aidesLocalesListStateAtom } from '../../../../services/Recoil/Atom/Themes.atom';
import { useRecoilValue } from 'recoil';
import * as ticketService from '../../../../services/calculs/ticket';
import * as themesTools from '../../../../services/calculs/themesTools';

// Icons
import { ReactComponent as IconBDC } from '../../../../assets/icons/simulator/icon-recapitulatif.svg';
import { ReactComponent as IconPoubelle } from '../../../../assets/icons/icon-remove.svg';
import { ReactComponent as Loader } from '../../../../assets/icons/loader.svg';

// README : voir BonDeCommande.tsx

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['annexe_bdc', 'contrat_pub'];

export interface AnnexeDetail {
    designation: string;
    prix: string;
    id: string;
    lines: string;
    isAides?: boolean;
}

const randomKeyGen = (): string => {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 15; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

type FormValues = {
    designation: string;
    prix: string;
    id: string;
    lines: string;
    designationDetails: [];
};

const isEmptyObject = (obj: { [key: string]: string }): boolean => {
    return Object.values(obj).every((value) => value === '');
};
const isArrayEmpty = (array: { [key: string]: string }[]): boolean => array.every((obj) => isEmptyObject(obj));

const aidesToCheck = [ticketService.AIDE_REMISE_MMA_NAME, ticketService.AIDE_REMISE_COMMERCIALE_NAME];

const Annexe: React.FC = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);
    const aidesLocales = useRecoilValue<ticketService.AidesLocalesList>(aidesLocalesListStateAtom);

    const annexeDetailFromLocalStorage = storageService.getSim().designationDetails.value as [];
    const AlertSwal = withReactContent(Swal);
    const [annexeDetailList, setAnnexeDetailList] = useState<AnnexeDetail[]>(isArrayEmpty(annexeDetailFromLocalStorage) ? [] : annexeDetailFromLocalStorage);
    const [rows, setRows] = useState<number>(1);

    const context = useLocation<bdc.BonDeCommandeContexte>().state;

    // montant de l'éco pub.
    let montantEcopub = 0; // on essaye de peupler après.
    let showEcoPub = false; // remplacer false par la future variable
    const ecopubObj = storageService.getSim().ecopub;
    if (ecopubObj && ecopubObj.value) {
        const ecopubValue = storageService.getSim().ecopub?.value;
        if (ecopubValue && typeof ecopubValue === 'number') {
            montantEcopub = ecopubValue;
        } else if (ecopubValue && typeof ecopubValue === 'string') {
            montantEcopub = isNaN(+ecopubValue) ? 0 : +ecopubValue;
        } else if (ecopubValue && typeof ecopubValue === 'object') {
            showEcoPub = ecopubValue.checked === true; // might not exists, so really compare with true.
            if (ecopubValue.checked === true && typeof ecopubValue.value === 'number') {
                montantEcopub = ecopubValue.value;
            }
        }
        //tous les autres cas : montantEcopub = 0 & ecoPub = false
    }

    const addAidesToAnnexeDetailList = () => {
        const newAnnexeDetails: AnnexeDetail[] = [];

        aidesToCheck.forEach((aideName) => {
            if (aidesLocales && aidesLocales[aideName]) {
                const aide = aidesLocales[aideName];
                if (aide && aide.montant !== undefined && aide.montant > 0) {
                    const convertedDesignation = ticketService.getLongName(aide.titre);
                    const aideExistante = annexeDetailList.some((item) => item.designation === convertedDesignation && item.prix === aide.montant_lbl);

                    if (!aideExistante) {
                        const annexeDetail: AnnexeDetail = {
                            designation: convertedDesignation,
                            prix: aide.montant_lbl, // Price already formatted
                            id: randomKeyGen(),
                            lines: '1',
                            isAides: true, // Show in non-formatted input
                        };
                        newAnnexeDetails.push(annexeDetail);
                    }
                }
            } else {
                console.warn(`Aide '${aideName}' is not defined in aidesLocales`);
            }
        });

        setAnnexeDetailList((prevAnnexeDetailList) => {
            // Supprimer les anciennes aides avec les mêmes noms convertis
            const updatedList = prevAnnexeDetailList.filter((item) => !newAnnexeDetails.some((newItem) => newItem.designation === item.designation));
            return [...updatedList, ...newAnnexeDetails];
        });
    };

    useEffect(() => {
        addAidesToAnnexeDetailList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aidesLocales]);

    // Update local storage
    useEffect(() => {
        storageService.setSimValue('designationDetails', annexeDetailList);
    }, [annexeDetailList]);

    // Formik default values
    const initialValues: FormValues = {
        designation: '',
        prix: '',
        id: '',
        lines: '',
        designationDetails: storageService.checkPropertyExistThenCreateOrRenderSimulator('designationDetails', 'Désignation & Prix', PropertyType['anexe']),
    };

    const validationSchema = Yup.object({
        designationDetails: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        designation: Yup.object({
                            value: Yup.string().notRequired(),
                        }),
                        prix: Yup.object({
                            value: Yup.number().min(0).notRequired(),
                        }),
                    })
                    .notRequired()
            )
            .notRequired(),
    });

    // Ajouter les éléments dans l'array `annexeDetails`
    const addToAnnexeDetail = (values: AnnexeDetail, setValues: Function) => {
        clearPdfContext(); // would force to re-doawnload pdf.

        values.id = randomKeyGen();
        values.lines = values.designation.split(/\r|\r\n|\n/).length.toString();

        setAnnexeDetailList([...annexeDetailList, values]);
        // Vider les champs
        setValues(initialValues);

        // Remettre les rows du textarea par défaut
        setRows(1);
    };

    // Supprimer un élément spécifique du tableau
    const removeToAnnexeDetail = (index: string) => {
        clearPdfContext(); // would force to re-doawnload pdf.

        let tmp = annexeDetailList.filter((item) => item.id !== index);

        setAnnexeDetailList(tmp);

        // Update local storage
        storageService.setSimValue('designationDetails', tmp);
    };

    // Enlarge textarea rows
    const enlargeYourTextarea = (e: React.KeyboardEvent) => {
        // e = keyboard key pressed
        if (e.key === 'Enter') {
            setRows(rows + 1);
        }

        if (rows > 3) {
            setRows(4);
        }
    };

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <DocumentsViewer docs={pdfList.map(bdc.pdfOutputToDocumentEntry)} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };

    const { push } = useHistory();

    const clearPdfContext = (): void => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    const isFormattedPrice = (price: string): boolean => {
        return /[^\d]/.test(price);
    };

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        console.log('initialisePdfContext');

        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.

        const result = Array<api.DocParameters>();

        // build all annexes.
        if (annexeDetailList.length > 0) {
            type Params = api.annexe_bdc['parameters'];
            const parameters: Params = {};
            for (let i = 0; i < annexeDetailList.length && i < 8; ++i) {
                const price = annexeDetailList[i].prix;
                const formattedPrice = isFormattedPrice(price) ? price : currencyFormat(parseFloat(price), true);

                parameters[`designation_${i + 1}` as keyof Params] = annexeDetailList[i].designation + ' (' + formattedPrice + ')';
                parameters[`quantite_${i + 1}` as keyof Params] = '1';
            }
            console.log('initialisePdfContext "annexe_bdc" parameters  = ' + JSON.stringify(parameters));
            const input: api.DocParameters<'annexe_bdc'> = {
                docName: 'annexe_bdc',
                parameters,
            };
            result.push(input);
            context.pdfState['annexe_bdc'] = { input, output: undefined };
        }

        if (showEcoPub) {
            const input: api.DocParameters<'contrat_pub'> = {
                docName: 'contrat_pub',
                parameters: {
                    montant_contrat_pub_chiffre: montantEcopub + '',
                    client_date_naissance: '', //steps.step1.userBirthday.value,
                    client_lieu_naissance: '', //steps.step1.userBirtplace.value,
                    client_nationalite: '', //steps.step1.nationality,
                },
            };
            result.push(input);
            context.pdfState['contrat_pub'] = { input, output: undefined };
        }

        return result;
    };

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        if (annexeDetailList.length > 0 && context.pdfState['annexe_bdc'].input === undefined) {
            console.log('arePdfInitialized annexe_bdc false ');
            return false;
        }
        if (showEcoPub && context.pdfState['contrat_pub'].input === undefined) {
            console.log('arePdfInitialized contrat_pub false ');
            return false;
        }
        console.log('arePdfInitialized true ');
        return true;
    };

    const validateAndNavigate = async (): Promise<void> => {
        setLoader(true);
        setPdfLoader(false);
        try {
            // si on a initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }

            const houseIsOld = storageService.getAudit().step2.buildingAge.value;
            const fulltva = themesTools.hasFullTVA(context.themes);
            const shouldShowTva = houseIsOld && !fulltva;

            // puis on navigue.
            // si PV => on navigue vers ROUTE_SIM_RENDEMENT
            // sinon, Si la maison est est ancienne, ROUTE_SIM_TVA,
            // sinon, directement au financement.
            const dest =
                themesTools.getFirstPanneau(context.themes) !== undefined ? ROUTE_SIM_RENDEMENT : shouldShowTva ? ROUTE_SIM_TVA : ROUTE_SIM_FINANCEMENT;
            push(dest, context);

            setLoader(false);
        } catch (err) {
            //console.log((err as any).response.message);

            setLoader(false);
            setPdfLoader(false);

            // Modal contenant le message d'erreur
            AlertSwal.fire({
                title: 'Erreur',
                icon: 'error',
                html: <p>{api.ERROR_INDISPONIBLE}</p>,
                width: 600,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };

    return (
        <div className="container container-audit">
            <h1 className="main-title-mini">Annexe au {storageService.isCurrentAuditMar() ? 'devis' : 'bon de commande'}</h1>

            <div className="card card-audit-simulator annexe mb-5">
                <div className="card-header">
                    <IconBDC />
                    <h2>Annexe</h2>
                </div>

                <div className="card-body">
                    <div className="repeater-container">
                        <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
                            {({ values, handleBlur, setValues }) => {
                                return (
                                    <div className="repeater-line">
                                        <div className="line-designation">
                                            <label htmlFor="designation">Designation</label>
                                            <textarea
                                                value={values.designation}
                                                onBlur={handleBlur}
                                                id={'designation'}
                                                maxLength={200}
                                                rows={rows}
                                                onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => enlargeYourTextarea(event)}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                    setValues({
                                                        ...values,
                                                        designation: event.target.value,
                                                    });
                                                }}
                                            >
                                                {values.designation}
                                            </textarea>
                                        </div>

                                        <div className="line-prix">
                                            <label htmlFor="prix">Prix</label>
                                            <input
                                                type="number"
                                                onBlur={handleBlur}
                                                step={0.01}
                                                id={'prix'}
                                                value={values.prix}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValues({
                                                        ...values,
                                                        prix: event.target.value,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="line-button">
                                            <button
                                                className={`btn btn-valider ${values.designation.length === 0 || values.prix.length === 0 ? 'disabled' : ''}`}
                                                type="button"
                                                onClick={() => addToAnnexeDetail(values, setValues)}
                                            >
                                                Valider
                                            </button>
                                        </div>
                                    </div>
                                );
                            }}
                        </Formik>

                        {annexeDetailList.map((item: AnnexeDetail, index: number) => (
                            <div className="repeater-line validate" key={item.id}>
                                <div className="line-designation">
                                    <textarea value={item.designation} disabled={true} id={`designation-${index}`} rows={parseInt(item.lines)}>
                                        {item.designation}
                                    </textarea>
                                </div>

                                <div className="line-prix">
                                    {item.isAides ? (
                                        <input type="text" disabled={true} id={`aides-${index}`} value={item.prix} />
                                    ) : (
                                        <input type="text" disabled={true} id={`prix-${index}`} value={currencyFormat(parseFloat(item.prix), true)} />
                                    )}
                                </div>

                                <div className="line-button">
                                    <button
                                        className="btn btn-supprimer"
                                        type="button"
                                        id={`line-suppr-${item.id}`}
                                        onClick={() => removeToAnnexeDetail(item.id)}
                                    >
                                        <IconPoubelle fill="red" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="btn-grp justify-content-end">
                <Link
                    to={{
                        pathname: storageService.isCurrentAuditMar() ? ROUTE_SIM_DEVIS : ROUTE_SIM_BON_DE_COMMANDE,
                        state: context,
                    }}
                    className="btn btn-retour"
                >
                    Retour
                </Link>
                <div className={`${annexeDetailList.length === 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                    <button
                        type="button"
                        className={`btn btn-bdc-modal${annexeDetailList.length === 0 ? ' disabled' : ''}`}
                        onClick={pdfModal}
                        disabled={loader || pdfLoader}
                    >
                        {pdfLoader && <Loader />}
                        <span style={{ opacity: pdfLoader ? '0' : '1' }}>
                            {annexeDetailList.length > 1 ? <>Visualiser les documents</> : <>Visualiser le document</>}
                        </span>
                    </button>
                </div>
                <div className={`${annexeDetailList.length === 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                    <button className="btn btn-continue" onClick={validateAndNavigate} disabled={loader || pdfLoader}>
                        {loader && <Loader />}
                        <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Annexe;
