import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as storageService from '../../services/localStorageService';

import * as apiParticulierService from '../../services/apiParticulierService';

import { heaterTypeCorrespondance, heaterFeatureCorrespondance } from '../../services/tools/correspondancesRecap';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import { ReactComponent as LoaderSVG } from '../../assets/icons/loader.svg';
import { sendAllImages } from '../../services/tools/imageHelper';
import { ROUTE_AUDIT_AL_RECAP_INELIGIBLE, ROUTE_AUDIT_AL_RECAP_INELIGIBLE_PV, ROUTE_AUDIT_AL_SIGNATURE } from '../../routing/paths';

import './recap.scss';

// Images
import Pump1 from '../../assets/images/recapitulatif/1_PAC_AIR_EAU.png';
import Pump2 from '../../assets/images/recapitulatif/2_PAC_AIR_EAU_BALLON.png';
import Pump3 from '../../assets/images/recapitulatif/3_PAC_AIR_AIR_BALLON_Split.png';
//import Pump4 from '../../assets/images/recapitulatif/4_ISOLATION.png';
import Pump5 from '../../assets/images/recapitulatif/5_PHOTOVOLTAIQUE.png';
import Pump6 from '../../assets/images/recapitulatif/pv-ballon-brasseur.jpg';
import Pump7 from '../../assets/images/recapitulatif/pv-brasseur.jpg';
// READ ME ACTION LOGEMENT
//
// Lorsqu'on valide le process action logement il y a plusieurs résultats possibles
// 1 - eligible (globalement, une question de pognon) => Recap.tsx (on est dedans)
// 2 - ineligible,
//    2.1 - Le WS Icall retourne des panneau photovoltaique => RecapIneligiblePhotovoltaique.tsx
//    2.2 - Le WS retourne une error 400, ou des donénes invalides => RecapIneligible.tsx
//           (dans ce dernier cas, on affiche simplement un phrase)
//           2.2.1 - murs isolés
//           2.2.2 - murs non isolés.

const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

// Display HTML + counter
const SwalComponent = () => {
    const counter = useCounter(120);

    return (
        <div>
            Merci de patienter, cette opération peut prendre jusqu'à 2 minutes
            <div className="swal2-loader mt-3 mx-auto d-flex"></div>
            <p className="mt-3">{counter}</p>
        </div>
    );
};

const Recap: React.FC = () => {
    const counter = useCounter(15);

    const AlertSwal = withReactContent(Swal);

    const { push } = useHistory();

    const [actionLogementJSON, setActionLogementJSON] = useState();

    const [recapitulatif, setRecapitulatif] = useState('');

    const whichPumpToDisplay = (reference: string) => {
        let image = '';
        // TODO : obsolète références.
        switch (reference) {
            // Image 1
            case 'AL-AIREAU-8':
            case 'AL-AIREAU-11':
            case 'AL-AIREAU-14':
            case 'AL-AIREAU-16':
            case 'AL PAC Air/Eau 11kW':
                image = Pump1;
                break;
            // Image 2
            case 'AL-AIREAU-8-BALLON':
            case 'AL-AIREAU-11-BALLON':
            case 'AL-AIREAU-14-BALLON':
            case 'AL-AIREAU-16-BALLON':
            case 'AL-AIREAUBT-8-BALLON':
            case 'AL-AIREAUBT-11-BALLON':
            case 'AL-AIREAUBT-14-BALLON':
            case 'AL-AIREAUBT-16-BALLON':
                image = Pump2;
                break;
            // Image 3
            case 'AL PAC Air/Air 7 Splits + BALLON':
            case 'AL-AIRAIR-3-BALLON':
            case 'AL-AIRAIR-4-BALLON':
            case 'AL-AIRAIR-5-BALLON':
            case 'AL-AIRAIR-6-BALLON':
            case 'AL-AIRAIR-7-BALLON':
            case 'AL PAC Air/Air 6 Splits + BALLON':
            case 'AL PAC Air/Air 4 Splits + BALLON':
            case 'AL PAC Air/Air 7 Splits + BALLON - AL PAC Air/Eau 11kW - BALLON (Hors package) CET CHAPPEE TD ECO 180 AI-E - Isolation des combles':
                image = Pump3;
                break;
            // Image 5
            case 'AL-PV-10-BALLON':
                image = Pump5;
                break;
            // Image 6
            case 'AL-DOM-PV':
                image = Pump6;
                break;
            // Image 7
            case 'AL-DOM-SB':
                image = Pump7;
                break;
            // Par défaut
            default:
                image = Pump1;
        }

        return image;
    };

    /**
     * Fonction pour l'envoi de la validation
     */
    const launchActionLogementValidateRequest = async () => {
        // Affichage écran de chargement
        AlertSwal.fire({
            title: 'Génération du bon de commande',
            html: <SwalComponent />,
            // didOpen: () => {
            //     Swal.showLoading();
            // },
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        try {
            const responseActionLogementValidate = await apiParticulierService.postActionLogementValidateData(actionLogementJSON);
            // Fermeture écran de chargement
            setTimeout(() => {
                Swal.close();
            }, 1000);
            // Redirection vers la route de signature
            push(ROUTE_AUDIT_AL_SIGNATURE + '?token=' + responseActionLogementValidate?.response?.data?.accessToken);
            return responseActionLogementValidate?.response?.data?.accessToken;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const agent = storageService.getCurrentAgent();
        const agentCode = agent.agentCode;
        const agentPassword = agent.agentPassword;

        /**
         * envoie les images et les plans à l'api, et sauvergarde les urls dans le local storage
         */
        sendAllImages();

        // POUR A-L, garder l'ancienne méthode d'appel (touchy)

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const sendAudit = async () => {
            try {
                const flowId = localStorage.getItem('flowId');

                if (!flowId) {
                    throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
                }

                // Lancement de la requête
                return apiParticulierService.sendAudit(flowId);
            } catch (error) {
                console.error(error);
                throw error;
            }
        };

        // OLD WAY
        // const launchAuditRequest = async () => {
        //     try {
        //         const auditJson = storageService.stepListToAuditAndClient();

        //         // Lancement de la requête
        //         return apiParticulierService.postAuditData(auditJson);
        //     } catch (error) {
        //         console.error(error);
        //         throw error;
        //     }
        // };

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const requestRdvNoSignature = async () => {
            let date = new Date();
            date.setDate(date.getDate() + 1);

            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }

            // Préparation du formData pour la requête
            let rdvJson: any = {
                rdv: {
                    startDate: date.toISOString().slice(0, 10),
                    startTime: date.toISOString().slice(11, 16),
                },
            };

            // Lancement de la requête
            try {
                return await apiParticulierService.requestRdvNoSignature(flowId, rdvJson);
            } catch (error) {
                throw error;
            }
        };
        // OLD WAY
        // const launchRdvRequest = async (auditId: string) => {
        //     let date = new Date();
        //     date.setDate(date.getDate() + 1);

        //     // Préparation du formData pour la requête
        //     let rdvJson: any = {
        //         clientIdentity,
        //         rdv: {
        //             startDate: date.toISOString().slice(0, 10),
        //             startTime: date.toISOString().slice(11, 16),
        //         },
        //         auditId: parseInt(auditId),
        //     };

        //     // Lancement de la requête
        //     try {
        //         return await apiParticulierService.postRdvData(rdvJson);
        //     } catch (error) {
        //         throw error;
        //     }
        // };

        /**
         * Fonction pour l'envoi de la requête du rendez-vous
         */
        const launchActionLogementRecapRequest = async (auditId: string) => {
            // Tableau de conversion type d'énergie - Type de chauffage principal
            const heaterTypeCorrespondances = heaterTypeCorrespondance;

            // Tableau de conversion - Type d'émetteur
            const heaterFeatureCorrespondances = heaterFeatureCorrespondance;

            // Exemple fonctionnel
            // let actionLogementJSON: any = {
            //     "choix-amo":"1",
            //     "isolation-murs":"1",
            //     "type-energie":"1",
            //     "type-chauffage":"3",
            //     "nbr-piece-chauffage":"3",
            //     "surface":"115",
            //     "annee-construction":"2002",
            //     "chaudiere-marque":"Atlantic",
            //     "chaudiere-puissance":"25",
            //     "chaudiere-condensation":"1",
            //     "combles":"2",
            //     "isolation-surface":"120",
            //     "auditId": parseInt(auditId)
            // }

            // Préparation du formData pour la requête
            let actionLogementJSON: any = {
                'choix-amo': '1', // Le laisser à 1
                'isolation-murs': storageService.getAudit().step6?.exteriorWallInsulationAge?.value !== '' ? '1' : '2',
                // Type de chauffage principal
                'type-energie':
                    storageService.getAudit().step3?.heaterSubscriptionGas.value === '42'
                        ? '3'
                        : heaterTypeCorrespondances[storageService.getAudit().step3?.heaterType?.value]
                        ? heaterTypeCorrespondances[storageService.getAudit().step3?.heaterType?.value]
                        : '999',
                // Type d'émetteur
                'type-chauffage': heaterFeatureCorrespondances[storageService.getAudit().step3?.heaterFeature?.value]
                    ? heaterFeatureCorrespondances[storageService.getAudit().step3?.heaterFeature?.value]
                    : '999',
                'nbr-piece-chauffage': storageService.getAudit().step3?.roomTypeDetails?.value.length.toString(), // Nombre de pièces déclarées dans l'étape 3
                surface: storageService.getAudit().step2?.SHab?.value.toString(),
                'annee-construction': storageService.getAudit().step2?.houseAge?.value,
                'chaudiere-marque': storageService.getAudit().step3?.heaterBrand?.value,
                'chaudiere-puissance': storageService.getAudit().step3?.heaterGeneratorPower?.value.toString(),
                'chaudiere-condensation': storageService.getAudit().step3.condensingHeater.value === '1' ? '1' : '2',
                combles: storageService.getAudit().step2.ceilingType.value === '2' ? '1' : '2',
                'isolation-surface': '40',
                auditId: parseInt(auditId),
            };

            // Injection de l'audit dans la variable pour utlisation ultèrieure
            setActionLogementJSON(actionLogementJSON);
            localStorage.setItem(`actionLogementJSON`, JSON.stringify(actionLogementJSON));

            // Lancement de la requête
            // may throw
            return await apiParticulierService.postActionLogementRecapData(actionLogementJSON);
        };

        /**
         *
         */
        async function loadRequested() {
            if (recapitulatif === '') {
                try {
                    // La on fait directement 4 requete de suite,
                    // sachant que quelque soit le résultat de l'audit, il faut faire le RDV et le recap
                    // Ensuite fait le if(audit.eligibleAL)

                    // Reconnexion
                    await apiParticulierService.identify(agentCode, agentPassword);
                    // Envoi des données de l'audit
                    await apiParticulierService.updateAuditDataService();
                    // Envoi de l'audit
                    const responseAudit = await sendAudit();

                    const auditId = responseAudit?.response?.data?.auditId;

                    // Envoi du RDV
                    await requestRdvNoSignature();

                    // Envoi demande de récapitulatif Action Logement
                    const responseRecap = await launchActionLogementRecapRequest(auditId);

                    // la prochaine ligne est pour les besoins de test.
                    // Elle va forcer la réponse inelligible
                    //if (responseAudit && responseAudit?.response && responseAudit?.response?.data) responseAudit.response.data.eligibiliteAL = false;
                    //console.log('eligible AL : ' + responseAudit?.response?.data?.eligibiliteAL);

                    // Cas client éligible
                    if (responseAudit?.response?.data?.eligibiliteAL) {
                        // Client conforme
                        if (responseRecap.status === 'OK') {
                            setRecapitulatif(responseRecap.response.data);
                            // Client non conforme (ex: isolation)
                        } else {
                            push(ROUTE_AUDIT_AL_RECAP_INELIGIBLE);
                        }
                        // Cas client non-éligible
                    } else {
                        // Client conforme
                        // Save the item to local storage before to call setIneligible.
                        // Else effect will be calculated and next page loader _before_ localStorage.setItem would finish. and localStorage.getIem will be called before.
                        if (responseRecap.status === 'OK') {
                            localStorage.setItem(`RecapIneligible`, JSON.stringify(responseRecap.response.data));
                            push(ROUTE_AUDIT_AL_RECAP_INELIGIBLE_PV);
                        } else {
                            localStorage.setItem(`RecapIneligible`, JSON.stringify(null));
                            push(ROUTE_AUDIT_AL_RECAP_INELIGIBLE);
                        }
                    }
                } catch (error) {
                    // Nominal case that could happen !
                    // Would happen if recap return an 400 + no preco avalaible.
                    localStorage.setItem(`RecapIneligible`, JSON.stringify(null));
                    push(ROUTE_AUDIT_AL_RECAP_INELIGIBLE);
                }
            }
        }

        loadRequested();
    }, [push, recapitulatif]);

    return (
        <>
            {!recapitulatif && (
                <div className="container text-center" id="loading">
                    <h1 className="main-title-mini">Envoi de l'audit en cours</h1>
                    <p>Merci de patienter le temps que vos données saisies soient analysées</p>
                    <LoaderSVG width={80} height={80} />
                    {counter > 0 && <p className="countdown">{counter}</p>}
                </div>
            )}

            {recapitulatif && (
                <div className="container">
                    <h1 className="main-title-mini">Récapitulatif</h1>
                    <h2 className="main-subtitle">Votre dossier est sur le point d’être finalisé</h2>

                    <div className="row my-4">
                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card card-recap">
                                <div className="card-title">
                                    <h2>Materiel recommandé</h2>
                                    <p>Selon les données renseignées dans votre audit, vous trouverez ci-dessous l’installation recommandée.</p>
                                </div>

                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-6">
                                            <div className="recap-pompe-title">
                                                <em>{(recapitulatif as any)['Package']}</em>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="recap-pompe">
                                                <img src={whichPumpToDisplay((recapitulatif as any)['Référence'])} alt={(recapitulatif as any)['Référence']} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                            <div className="card card-recap">
                                <div className="card-title">
                                    <h2>Devis et aides éligibles</h2>
                                </div>

                                <div className="card-body">
                                    <div className="invoice-main">
                                        Prix devis TTC <span>{(recapitulatif as any)['Prix devis TTC']}</span>
                                    </div>
                                    <div className="invoice-main">
                                        Montant des aides cumulées <span>{(recapitulatif as any)['Montant des aides cumulées']}</span>
                                    </div>

                                    <ul className="invoice-list">
                                        {(recapitulatif as any)['Montant Prime CEE'] !== undefined ? (
                                            <>
                                                <li>Montant Prime CEE</li>
                                                <li>{(recapitulatif as any)['Montant Prime CEE']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Aide Action Logement'] !== undefined ? (
                                            <>
                                                <li>Montant aides action logement</li>
                                                <li>{(recapitulatif as any)['Montant Aide Action Logement']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Prime CEE Ballon'] !== undefined ? (
                                            <>
                                                <li>Montant Prime CEE Ballon</li>
                                                <li>{(recapitulatif as any)['Montant Prime CEE Ballon']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}

                                        {(recapitulatif as any)['Montant Prime Coup de Pouce'] !== undefined ? (
                                            <>
                                                <li>Montant Prime Coup de Pouce</li>
                                                <li>{(recapitulatif as any)['Montant Prime Coup de Pouce']}</li>
                                            </>
                                        ) : (
                                            <>
                                                <li style={{ listStyle: 'none' }}></li>
                                                <li></li>
                                            </>
                                        )}
                                    </ul>

                                    <div className="invoice-total">
                                        Net à payer <span>{(recapitulatif as any)['Net à payer']}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-3 mb-lg-0">
                            <div className="card card-recap">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="card-title min-h-80">
                                                <h2>Liste des documents à préparer et à charger à l’étape suivante</h2>
                                            </div>

                                            <div className="card-body">
                                                <div className="recap-list-container">
                                                    <ul className="recap-list recap-list-column">
                                                        {(recapitulatif as any)['Docs à insérer']?.map((item: any) => (
                                                            <li key={item}>{item}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="card-title min-h-80">
                                                <h2>Liste des documents à générer à l'étape suivante</h2>
                                            </div>

                                            <div className="card-body">
                                                <ul className="recap-list">
                                                    {(recapitulatif as any)['Docs à générer']?.map((item: any) => (
                                                        <li key={item}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="navigation text-center pt-4 my-4">
                        <button onClick={launchActionLogementValidateRequest} className="btn btn-primary btn-maxi">
                            Finaliser mon dossier
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Recap;
