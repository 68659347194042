import React from 'react';
import { ReactComponent as IconLoader } from '../../assets/icons/loader-primary.svg';

const DpeSkeletonItem: React.FC = () => {
    return (
        <div className="dpe-item skeleton">
            <div className="skeleton-icon">
                <IconLoader width={40} height={40} />
            </div>
            <div className="skeleton-content">
                <div className="skeleton-title"></div>
                <div className="skeleton-result"></div>
            </div>
        </div>
    );
};

export default DpeSkeletonItem;
