import { useEffect, useState } from 'react';
import { LabelledString } from './TypeHelper';
import AuditTemplate from '../../assets/json/template-audit.json';
import * as storageService from '../../services/localStorageService';

// #region Step8
/**
 * Liste des images de l'étape 8 en base64 à purger
 */
export const picturesNames = [
    'buildingPictureGeneral',
    'buildingPictureNorth',
    'buildingPictureEast',
    'buildingPictureSouth',
    'buildingPictureWest',
    'buildingPictureOther',
    'buildingPictureBalcony',
    'buildingPicturesSolarMask',
    'buildingPicturesheaterGeneratorPower',
    'buildingPicturesheaterFeature',
    'buildingPicturesenergyWater',
    'buildingPictureRoofing',
    'buildingPicturesPathology1',
    'buildingPicturesPathology2',
    'buildingPicturesPathology3',
    'buildingPicturesPathology4',
    'buildingPicturesPathology5',
    'buildingPicturesPathology6',
    'buildingPicturesPathology7',
    'buildingPictureCeilingInsulationPresence',
    'buildingPictureFloorInsulationPresence',
    'buildingPictureExteriorWall',
    'buildingPictureUnheatedRoom',
    'buildingPictureDoor1',
    'buildingPictureDoor2',
    'buildingPictureDoorUnheatedRoom1Presence',
    'buildingPictureBuildingWindows1',
    'buildingPictureBuildingWindows2',
    'buildingPictureDoorWindows1',
    'energyCostsElectricInvoice1',
    'energyCostsElectricInvoice2',
    'energyCostsElectricInvoice3',
    'energyCostsGasInvoice1',
    'energyCostsGasInvoice2',
    'energyCostsGasInvoice3',
] as const;

export type PictureName = (typeof picturesNames)[number];

export type Picture = {
    desc: string;
    /** La valeur en base64 */
    value?: string | undefined;
    /** L'url de l'image */
    url?: string | undefined;
};

export type Step8Pictures = Record<PictureName, Picture>;

/**
 * Retourne les photos de l'étape 8 et la validité de l'audit
 * @param audit l'audit pour lequel on veut les photos, si undefined, l'audit sera chargé depuis le local storage
 * @returns les photos de l'étape 8 et la validité de l'audit
 */
export const getStep8 = (audit: any | undefined = undefined): { photos: Step8Pictures; isValid: boolean } => {
    if (!audit) audit = storageService.getAudit();
    const step8 = { ...audit.step8 };
    const isValid = audit.step8.isValid;
    // Suppression de la valeur isValid, pour transformer step8 en Step8Pictures
    delete step8.isValid;

    const photos: Step8Pictures = step8;
    return { photos, isValid };
};

// #endregion

// #region calcul de surface
type presenceSS = 'avecSS' | 'horsSS';
type presenceCA = 'avecCA' | 'horsCA';

export type LevelCountMode = `${presenceSS}${presenceCA}`;

/**
 * retourne le nombre de niveaux suivant differentes règles.
 * @param flatAudit l'audit pour lequel on veut le mode.
 * @param mode par défaut, c'est audit.floorCount directement
 * @returns le nombre de niveaux selon le mode de calcul.
 */
export const getLevelCount = (flatAudit: any, mode: LevelCountMode = 'avecSSavecCA'): number => {
    if (!flatAudit || flatAudit.floorCount === undefined) {
        return 1;
    }
    // audit.floorCount correspond à avecSSavecCA
    let floorCount = +flatAudit.floorCount.value;

    switch (mode) {
        case 'horsSShorsCA':
            // Typiquement pour la surface des murs, hauteur de la goutière
            // supprimer les combles aménagés.
            if (flatAudit && flatAudit.ceilingType && flatAudit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            // supprimer le sous sol.
            if (flatAudit && flatAudit.floorType && flatAudit.floorType.value_label === 'Plancher sur sous sol') floorCount--;
            break;

        case 'horsSSavecCA':
            // supprimer le sous sol.
            // Cardonnel
            if (flatAudit && flatAudit.floorType && flatAudit.floorType.value_label === 'Plancher sur sous sol') floorCount--;

            break;

        case 'avecSShorsCA':
            // supprimer les combles aménagés.
            if (flatAudit && flatAudit.ceilingType && flatAudit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            break;

        case 'avecSSavecCA':
        default:
            // rien, c'est le cas par défaut.
            break;
    }
    // console.log('floor count (mode : ' + mode + ') : ' + floorCount);
    return floorCount;
};

const surfaceDunNiveau = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;
    let surfaceNiv0 = 0;
    let shape = audit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    const baseRect = +audit.buildingWidth.value * +audit.buildingLength.value;

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
            surfaceNiv0 = baseRect;
            break;
        case 11: // 'En L',
            surfaceNiv0 = baseRect - shape.l1 * (audit.buildingWidth.value - shape.l2);

            break;
        case 12: // 'En L inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;
        case 21: // 'En T',
        case 22: // 'En T inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4;
            break;
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6;
            break;
        case 51: // 'En X',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6 - shape.l7 * shape.l8;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;

        case 52: // 'En H',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l5 * shape.l7;
            break;
        case 99: // 'Autre forme',
            surfaceNiv0 = baseRect;
            break;
        default:
            surfaceNiv0 = 0;
            break;
    }
    if (isNaN(surfaceNiv0)) return baseRect;
    return surfaceNiv0;
};

const perimetreDunNiveau = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;
    let perimetreNiv0 = 0;
    let shape = audit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
        case 11: // 'En L',
        case 12: // 'En L inversé',
        case 21: // 'En T',
        case 22: // 'En T inversé',
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
        case 51: // 'En X',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2;
            break;

        case 52: // 'En H',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2 + shape.l7 + shape.l7;
            break;
        case 99: // 'Autre forme',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;
    }
    if (isNaN(perimetreNiv0)) return 0;
    return perimetreNiv0;
};
export const getSurfaceDesMurs = (audit: any): number => {
    if (audit.buildingPlans === undefined) return 0;

    let height = audit.buildingPlans.value[0].height;

    let contourNiv0 = perimetreDunNiveau(audit);
    // on calcule le nombre de niveaux :
    const floorCount = getLevelCount(audit, 'horsSShorsCA');

    // Le contour du niveau 0 est considéré comme le même que les autres niveaux.
    // La hauteur des murs est dans shape
    const surfaceMurs = floorCount * contourNiv0 * height;
    // console.log('surface des murs (fnêtre incluses) = ' + surfaceMurs);
    const surfaceMursHorsOuvrants = surfaceMurs - getSurfaceDesFenetres(audit) - getSurfaceDesPortes(audit);

    return surfaceMursHorsOuvrants;
};
export const getSurfaceDesFenetres = (audit: any): number => {
    var surface = 0;

    if (audit.buildingWindows1details !== undefined && audit.buildingWindows1details.value !== undefined) {
        const N = audit.buildingWindows1details.value.N;
        const NE = audit.buildingWindows1details.value.NE;
        const E = audit.buildingWindows1details.value.E;
        const SE = audit.buildingWindows1details.value.SE;
        const S = audit.buildingWindows1details.value.S;
        const SO = audit.buildingWindows1details.value.SO;
        const O = audit.buildingWindows1details.value.O;
        const NO = audit.buildingWindows1details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }
    if (audit.buildingWindows2details !== undefined && audit.buildingWindows2details.value !== undefined) {
        const N = audit.buildingWindows2details.value.N;
        const NE = audit.buildingWindows2details.value.NE;
        const E = audit.buildingWindows2details.value.E;
        const SE = audit.buildingWindows2details.value.SE;
        const S = audit.buildingWindows2details.value.S;
        const SO = audit.buildingWindows2details.value.SO;
        const O = audit.buildingWindows2details.value.O;
        const NO = audit.buildingWindows2details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }

    if (audit.buildingDoorWindows1details !== undefined && audit.buildingDoorWindows1details.value !== undefined) {
        const N = audit.buildingDoorWindows1details.value.N;
        const NE = audit.buildingDoorWindows1details.value.NE;
        const E = audit.buildingDoorWindows1details.value.E;
        const SE = audit.buildingDoorWindows1details.value.SE;
        const S = audit.buildingDoorWindows1details.value.S;
        const SO = audit.buildingDoorWindows1details.value.SO;
        const O = audit.buildingDoorWindows1details.value.O;
        const NO = audit.buildingDoorWindows1details.value.NO;
        if (N !== undefined) surface += N.count * N.surface;
        if (NE !== undefined) surface += NE.count * NE.surface;
        if (E !== undefined) surface += E.count * E.surface;
        if (SE !== undefined) surface += SE.count * SE.surface;
        if (S !== undefined) surface += S.count * S.surface;
        if (SO !== undefined) surface += SO.count * SO.surface;
        if (O !== undefined) surface += O.count * O.surface;
        if (NO !== undefined) surface += NO.count * NO.surface;
    }
    // console.log('surface des fenêtres = ' + surface);
    return surface;
};

export const getSurfaceDesPortes = (audit: any): number => {
    // TODO, visiblement on ne sait aps calculer la surface des portes.
    const SURFACE_D_UNE_PORTE = 2.0; // standard arbitraire en m² pour une porte de 90cm X 200cm
    let porteCount = 1;

    if (audit && audit.buildingDoor2Presence && audit.buildingDoor2Presence.value === true) porteCount++;

    return porteCount * SURFACE_D_UNE_PORTE;
};
/** Alias surface rempant */
export const getSurfaceToiture = (audit: any): number => {
    // Surface à isoler :
    // surface = Sruface d'un niveau / cosinus(inclinaison)
    var cos = 1;
    if (audit.tilt !== undefined) {
        const angleDegres = +audit.tilt.value_label.slice(0, -1);
        const angleRad = angleDegres * (Math.PI / 180);
        // Si l'angle fait 90° (par pourrissage des données), on a cos = 0, et crash à la division.
        cos = Math.cos(angleRad);
    }
    if (cos === 0) return 0; // ou autre valeur à la con
    return surfaceDunNiveau(audit) / cos;
};
/** Alias surface rempant */
export const getSurfaceRampants = getSurfaceToiture;

// Isoler les combles aménagés === isoler les rempants === isoler le toit.
// isoler les combles non aménagés === isoler le plancher des combles (on a pas l'intention de les chauffer.)

export const getSurfacePlancherComble = (audit: any): number => {
    return surfaceDunNiveau(audit);
};
export const getSurfacePlancher = (audit: any): number => {
    // if (!audit || !audit.SHON || !audit.floorCount || audit.floorCount.value === 0) return 0;
    // return +audit.SHON.value / +audit.floorCount.value;
    return surfaceDunNiveau(audit);
};
// #endregion

// #region divers

export const canInstallPV = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label !== 'Appartement' && audit.projectType?.value_label !== 'Immeuble collectif';
};
export const isAppartement = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Appartement';
};
export const isMaison = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Maison individuelle';
};

// #endregion

// TODO, tout ce qu'il y a partir de la devrait se trouver ailleurs.
// Ce sont des outils génériques. Ce fichier ne devrait contenir que des outils liés à l'audit.

// Unique keys
export const randomKeyGen = (): string => {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 15; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

// Modal counter
export const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

export const removeItemAtIndexWithCopy = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

// Create dynamic select list
export const createDynamicList = (min: number, max: number): LabelledString[] => {
    const list = [];

    for (let i = min; i <= max; i++) {
        const item: LabelledString = {
            label: `${i}`,
            value: `${i}`,
        };

        list.push(item);
    }

    return list;
};

// Extract number from string
export const extractNumberFromString = (input: string): number | null => {
    if (typeof input !== 'string' || !input) {
        return null;
    }

    const parts = input.split(' ');
    let number: number | null = null;

    for (const part of parts) {
        if (!isNaN(parseInt(part, 10))) {
            number = parseInt(part, 10);
            break;
        }
    }

    return number;
};

export const formatNumberWithLeadingZero = (num: number) => {
    if (num >= 1 && num <= 9) {
        return `0${num}`;
    } else {
        return num.toString();
    }
};

/** recursively log a json object, with collapsible output
 * WARNING : this log is UGLY for perf, do not use massively.
 */
export const prettyLog = (key: string | undefined, value: unknown, open = false) => {
    if (value === null) {
        console.log(key ? key + ' : null' : 'null');
        return;
    }
    if (value === undefined) {
        console.log(key ? key + ' : undefined' : 'undefined');
        return;
    }

    if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'symbol') {
        console.log(key ? key + ' : ' : '', value);
        return;
    }
    if (typeof value === 'string') {
        console.log(key ? key + ' : ' : '', '"' + value + '"');
        return;
    }
    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            open ? console.group(key ? key + ' : [' : '[') : console.groupCollapsed(key ? key + ' : [' : '[');
            const array = value as Array<unknown>;
            for (const elem of array) {
                // recursive call
                prettyLog(undefined, elem, open);
            }
            console.log(']');
        } else {
            open ? console.group(key ? key + ' : {' : '{') : console.groupCollapsed(key ? key + ' : {' : '{');
            for (const subkey of Object.keys(value as object)) {
                const elem = (value as Record<string, unknown>)[subkey];
                // recursive call
                prettyLog(subkey, elem, open);
            }
            console.log('}');
        }

        console.groupEnd();
    }
};
export const removeItemAtIndex = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const emptyAudit = () => {
    const audit = storageService.getAudit();
    if (!audit) return;

    // Keep step1 and currentStep
    const step1 = audit.step1;
    const currentStep = audit.currentStep;

    // Reset with empty template
    const template = AuditTemplate;

    // Restore step1 and important properties
    template.step1 = step1;
    template.currentStep = currentStep;

    // Empty DPE and GES
    template.step1.etiquetteDPE.value = '';
    template.step1.etiquetteGES.value = '';

    // Save new audit
    storageService.setAudit(template);
};
