import { DocumentEntry } from '../components/Document/DocumentList';
import { Mar } from './apiFlowService';
import * as api from './apiParticulierService';
import { CreateContractOutput } from './apiTypes';
import { RenovationDAmpleurParams } from './calculs/aides/aideRenovationDAmpleurDefs';
import { capitalizeFirstLetter } from './tools/TypeHelper';

export const draDocOutputToDocumentEntry = (doc: DraDocOutput | undefined): DocumentEntry | undefined => {
    if (!doc) return undefined;
    const title = capitalizeFirstLetter(knownDraDocTypeToString(doc.comment as KnownDraDocType));
    return {
        title,
        comment: title,
        url: doc.document.url,
        extension: doc.document.extension,
    };
};

export const knownDraDocType = [
    'piece_identite', // nécessaire
    'avis_imposition', // nécessaire
    'taxe_fonciere', // nécessaire
    'justificatif_domicile',
    'mandat_anah_mpr',
    'mar',
    'mar_summary',
    'autres',
] as const;
export type KnownDraDocType = (typeof knownDraDocType)[number];
export const knownDraDocTypeToString = (type: KnownDraDocType | string): string => {
    switch (type) {
        case 'piece_identite': // nécessaire
            return "Pièce d'identité";
        case 'avis_imposition': // nécessaire
            return "Avis d'imposition";
        case 'taxe_fonciere': // nécessaire
            return 'Taxe foncière';
        case 'justificatif_domicile':
            return 'Justificatif de domicile';
        case 'mandat_anah_mpr':
            return 'Mandat Anah / MaPrimeRénov';
        case 'mar':
            return 'Document MAR';

        case 'mar_summary':
            return 'Synthèse MAR';
        case 'autres':
            return 'Autres';
        default:
            return type;
    }
};

export type DraDocOutput = {
    id: string;
    createdAt: string;
    comment: KnownDraDocType | string;
    document: api.Document;
};

export const dossierRenoDAmpleurStateNames = [
    'Created',
    'ReadyForContactMar',
    'Ineligible',
    'WaintingForContactMar', // faute de frappe, retro compatiilité, à virer bientot.
    'WaitingForContactMar',
    'WaitingForPlanification',
    'WaitingForIntervention',
    'WaitingForGrantRequest',
    'WaitingForGrant',
    'Completed',
    'Cancelled',
    'GrantRejected',
] as const;
export type DossierRenoDAmpleurState = (typeof dossierRenoDAmpleurStateNames)[number];

export const dossierRenoDAmpleurStateToString = (state: DossierRenoDAmpleurState): string => {
    switch (state) {
        case 'Created':
            return 'Créé';
        case 'Ineligible':
            return 'Inéligible';
        case 'ReadyForContactMar':
            return 'Prêt à envoyer au MAR';
        case 'WaitingForContactMar': // faute de frappe, retro compatiilité, à virer bientot.
        case 'WaintingForContactMar':
            return 'En attente de contact MAR';
        case 'WaitingForPlanification':
            return 'En attente de planification';
        case 'WaitingForIntervention':
            return "En attente d'intervention";
        case 'WaitingForGrantRequest':
            return 'En attente de demande d’octroi';
        case 'WaitingForGrant':
            return 'En attente d’octroi';
        case 'Completed':
            return 'Terminé';
        case 'Cancelled':
            return 'Annulé';
        case 'GrantRejected':
            return 'Octroi refusé';
    }
};

export type DossierRenoDAmpleur = {
    id: string;
    createdAt: string;
    updatedAt: string;
    flowId: string;
    renoDampleurParams?: RenovationDAmpleurParams | undefined | null;
    mar?: Mar | null;

    clientInformed: boolean;
    clientMprOpened: boolean;
    comment: string[] | undefined;

    marSelectedAt: string | null;
    marContactedAt: string | null;

    draDocs: Array<DraDocOutput>;

    state: DossierRenoDAmpleurState;
    interventionAt: string | null;
    marAuditReview: string[];
    cancelledReason: string | null;
    cancelledAt: string | null;

    grantRequestedAt: string | null;
    grantedAt: string | null;
    grantRejectedAt: string | null;
};

/**
 * permet de mettre à jour les données utilisateur du DRA,
 * mais seulement les champs acceptés concernant ce front.
 */
export type UpdateDraUser = Partial<Pick<DossierRenoDAmpleur, 'renoDampleurParams' | 'clientInformed' | 'clientMprOpened' | 'comment'>>;

export const updateDra = async (draId: string, params: UpdateDraUser): Promise<void> => {
    await api.putJsonData(`dossierRenoDAmpleur/${draId}`, { ...params });
};

export const sendDraToMar = async (draId: string, comment: string | undefined): Promise<void> => {
    await api.postJsonData(`dossierRenoDAmpleur/${draId}/send-to-mar`, { comment });
};
export const cancelDra = async (draId: string, cancelledReason: string | undefined): Promise<void> => {
    await api.postJsonData(`dossierRenoDAmpleur/${draId}/cancel`, { cancelledReason });
};

export const consentAnahSign = async (draId: string): Promise<CreateContractOutput<'mandat_anah'> | CreateContractOutput<'mandat_mpr'>> => {
    const response = await api.postJsonData(`dossierRenoDAmpleur/${draId}/mandat/sign`);
    if (!response) throw response;
    return response as CreateContractOutput<'mandat_anah'> | CreateContractOutput<'mandat_mpr'>;
};

export const anahConsentDownload = async (draId: string, netheosUuid: string): Promise<{ id: string }> => {
    const response = await api.postJsonData(`dossierRenoDAmpleur/${draId}/mandat/download`, { netheosUuid });
    if (!response) throw response;
    return response as { id: string };
};

export const associateDraDocument = async (draId: string, documentId: string, comment: string): Promise<void> => {
    return await api.postJsonData(`dossierRenoDAmpleur/${draId}/associate-doc`, { documentId, comment });
};
export const getDraDocuments = async (draId: string): Promise<Array<DraDocOutput>> => {
    return await api.getData(`dossierRenoDAmpleur/${draId}/associated-doc`);
};
export const deleteDraDocument = async (draId: string, documentId: string): Promise<void> => {
    return await api.deleteData(`dossierRenoDAmpleur/${draId}/associated-doc/many`, { ids: [documentId] });
};
