import { DpeEtiquette } from './types';

export const DpeUnit = { DPE: 'kWh/m²/an', GES: 'kgCO₂/m²/an' } as const;

export type DpeEtiquetteSeuil = { [key in DpeEtiquette]: { min: number; max: number } };
export const DpeEtiquetteSeuilDPE: DpeEtiquetteSeuil = {
    A: { min: 0, max: 50 },
    B: { min: 51, max: 90 },
    C: { min: 91, max: 150 },
    D: { min: 151, max: 230 },
    E: { min: 231, max: 330 },
    F: { min: 331, max: 450 },
    G: { min: 451, max: 10000 },
};

export const DpeEtiquetteSeuilGES: DpeEtiquetteSeuil = {
    A: { min: 0, max: 5 },
    B: { min: 6, max: 10 },
    C: { min: 11, max: 20 },
    D: { min: 21, max: 35 },
    E: { min: 36, max: 55 },
    F: { min: 56, max: 80 },
    G: { min: 81, max: 10000 },
};

export const getEtiquetteGlobale = (etiquetteDPE: DpeEtiquette | '' | undefined, etiquetteGES: DpeEtiquette | '' | undefined): DpeEtiquette | undefined => {
    const badDpe = etiquetteDPE === '' || etiquetteDPE === undefined;
    const badGes = etiquetteGES === '' || etiquetteGES === undefined;
    // C'est la plus défavorable des deux !
    return badDpe ? (badGes ? undefined : etiquetteGES) : badGes ? etiquetteDPE : etiquetteDPE > etiquetteGES ? etiquetteDPE : etiquetteGES;
};

export const getEtiquette = (value: number, thresholds: DpeEtiquetteSeuil): DpeEtiquette => {
    return (Object.keys(thresholds) as DpeEtiquette[]).find((key) => value >= thresholds[key].min && value <= thresholds[key].max)!;
};

export const getEtiquetteDpe = (energyValue: number): DpeEtiquette => {
    const energyCategory = getEtiquette(energyValue, DpeEtiquetteSeuilDPE);
    return energyCategory;
};

export const getEtiquetteGes = (gesValue: number): DpeEtiquette => {
    const gesCategory = getEtiquette(gesValue, DpeEtiquetteSeuilGES);
    return gesCategory;
};
