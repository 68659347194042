import React, { useEffect, useRef, useState } from 'react';
import { FormRow, FormRowMultiline } from '../../../components/FormRow/FormRow';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as apiFlow from '../../../services/apiFlowService';
import * as routes from '../../../routing/paths';
import { FormikProps } from 'formik';
import MailMarForm, { MailMarFormData } from './MailMarModale';
import { dossierRenoDAmpleurStateToString } from '../../../services/apiDRAService';
import { dateFormat } from '../../../services/tools/TypeHelper';
import CancelForm, { CancelFormData } from './cancelDossierRenoDampleurModale';
import * as apiDRA from '../../../services/apiDRAService';
import DraMakeItReady from './DraMakeItReady';

import '../../../assets/style/checkbox.scss';
import '../../../assets/style/button.scss';

interface DossierRenoDampleurProps {
    dossierRenoDAmpleur: apiDRA.DossierRenoDAmpleur;
    isReadonly: boolean;
    load: (to: string, target: '_self' | '_blank' | undefined) => void;
}

const DossierRenoDampleur: React.FC<DossierRenoDampleurProps> = (props: DossierRenoDampleurProps) => {
    const AlertSwal = withReactContent(Swal);
    const [mandatAnahDate, setMandatAnahDate] = useState<string | undefined>(undefined);

    const hasRenovationDampleur =
        props.dossierRenoDAmpleur.renoDampleurParams !== null &&
        props.dossierRenoDAmpleur.renoDampleurParams !== undefined &&
        Object.keys(props.dossierRenoDAmpleur.renoDampleurParams).length !== 0;
    const isCustomerEligible =
        !(hasRenovationDampleur && props.dossierRenoDAmpleur?.renoDampleurParams?.elligible === false) && props.dossierRenoDAmpleur.state !== 'Ineligible';
    const marShouldContactCustomer = hasRenovationDampleur && props.dossierRenoDAmpleur?.renoDampleurParams?.marShouldContactCustomer === true;

    const showMarInfos =
        isCustomerEligible &&
        marShouldContactCustomer &&
        props.dossierRenoDAmpleur?.mar &&
        (props.dossierRenoDAmpleur?.state === 'Created' ||
            props.dossierRenoDAmpleur?.state === 'ReadyForContactMar' ||
            props.dossierRenoDAmpleur?.state === 'Completed' ||
            props.dossierRenoDAmpleur?.state === 'WaintingForContactMar' || // obsolete a virer bientot.
            props.dossierRenoDAmpleur?.state === 'WaitingForContactMar' ||
            props.dossierRenoDAmpleur?.state === 'WaitingForPlanification' ||
            props.dossierRenoDAmpleur?.state === 'WaitingForIntervention' ||
            props.dossierRenoDAmpleur.state === 'WaitingForGrantRequest' ||
            props.dossierRenoDAmpleur.state === 'WaitingForGrant');

    const dossierOuvert =
        props.dossierRenoDAmpleur.state === 'Created' ||
        props.dossierRenoDAmpleur.state === 'ReadyForContactMar' ||
        props.dossierRenoDAmpleur.state === 'WaintingForContactMar' || // obsolete a virer bientot.
        props.dossierRenoDAmpleur.state === 'WaitingForContactMar' ||
        props.dossierRenoDAmpleur.state === 'WaitingForIntervention' ||
        props.dossierRenoDAmpleur.state === 'WaitingForPlanification' ||
        props.dossierRenoDAmpleur.state === 'WaitingForGrantRequest' ||
        props.dossierRenoDAmpleur.state === 'WaitingForGrant';

    const showBtnCancel =
        props.dossierRenoDAmpleur.state !== 'Ineligible' &&
        props.dossierRenoDAmpleur.state !== 'Cancelled' &&
        props.dossierRenoDAmpleur.state !== 'GrantRejected';
    const showBtnDuplicate = isCustomerEligible && marShouldContactCustomer;
    const showBtnSendMail = isCustomerEligible && marShouldContactCustomer && props.dossierRenoDAmpleur.state === 'ReadyForContactMar';

    const setDraDocuments = (draDocuments: Array<apiDRA.DraDocOutput>) => {
        //setDraRow(draDocuments.map(draDocOutputToDocumentEntry));

        const mandatAnah = draDocuments.find((doc) => doc.comment === 'mandat_anah_mpr');
        setMandatAnahDate(mandatAnah?.createdAt);
    };

    useEffect(() => {
        if (props.dossierRenoDAmpleur && props.dossierRenoDAmpleur.draDocs) {
            setDraDocuments(props.dossierRenoDAmpleur.draDocs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let mailToMarFormikRef = useRef<FormikProps<MailMarFormData>>(null); // Utilisation de la référence Formik
    let cancelFormikRef = useRef<FormikProps<CancelFormData>>(null); // Utilisation de la référence Formik

    const envoyerMailAuMar = async (): Promise<void> => {
        if (!props.dossierRenoDAmpleur.renoDampleurParams || props.dossierRenoDAmpleur.renoDampleurParams.elligible !== true) return;
        if (!isCustomerEligible) return;

        const reno = props.dossierRenoDAmpleur.renoDampleurParams;
        const scenarioSummary =
            reno.scenariosText !== null && reno.scenariosText !== undefined && reno.scenariosText.length !== 0
                ? reno.scenariosText[reno.scenariosText.length - 1]
                : '';

        const initialValues: MailMarFormData = {
            comment: undefined,
        };

        await AlertSwal.fire({
            title: <h2>Envoyer un email de demande d'intervention d'un accompagnateur Rénov'</h2>,
            html: (
                <MailMarForm
                    ref={mailToMarFormikRef}
                    initialValues={initialValues}
                    scenarioPersoText={scenarioSummary}
                    onClose={() => {
                        AlertSwal.close();
                    }}
                    onSubmit={(values: MailMarFormData) => {
                        try {
                            AlertSwal.clickConfirm();
                        } catch (error) {
                            AlertSwal.showValidationMessage('Le formulaire contient des erreurs.');
                        }
                    }}
                />
            ),
            width: 960,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            preConfirm: async (): Promise<MailMarFormData | null> => {
                if (mailToMarFormikRef.current) {
                    await mailToMarFormikRef.current.submitForm();
                }

                if (!mailToMarFormikRef.current?.isValid) {
                    Swal.showValidationMessage("Veuillez compléter le formulaire avant d'envoyer.");
                    return null;
                }

                // Déplacer l'appel API ici
                if (mailToMarFormikRef.current?.values && props.dossierRenoDAmpleur?.flowId) {
                    try {
                        // console.log('appel api avec = ' + JSON.stringify(formikRef.current.values.comment, null, 2));
                        apiDRA.sendDraToMar(props.dossierRenoDAmpleur.id, mailToMarFormikRef.current.values.comment).then(() => {
                            // on le fait aps dans await car on veut que le code continue
                            // Recharger la page après l'envoi réussi
                            window.location.reload();
                        });
                    } catch (error) {
                        console.error("Erreur lors de l'envoi du mail au MAR:", error);
                        Swal.showValidationMessage("Une erreur s'est produite lors de l'envoi du mail.");
                        return null;
                    }
                }

                return mailToMarFormikRef.current?.values || null;
            },
        });
    };
    const duplicateAuditMar = async (): Promise<void> => {
        AlertSwal.fire({
            title: <strong>Duplication d'audit pour la rénovation d'ampleur</strong>,
            html: (
                <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>
                    {' '}
                    <p>
                        Vous vous apprêtez à dupliquer cet audit <u>pour la rénovation d'ampleur</u>. L'audit dupliqué apparaîtra dans la liste des audits.
                        Merci de confirmer cette action.
                    </p>
                </div>
            ),
            width: 960,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Dupliquer pour la rénovation d'ampleur",
            cancelButtonText: 'Annuler',
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-continue min-width',
                cancelButton: 'btn btn-retour min-width',
            },
        }).then((response) => {
            if (response.isConfirmed && props.dossierRenoDAmpleur?.flowId) {
                apiFlow
                    .duplicateAudit(props.dossierRenoDAmpleur.flowId, true)
                    .then(() => {
                        props.load(routes.ROUTE_EA_LIST, '_self');
                    })
                    .catch(() => {
                        AlertSwal.fire({
                            html: (
                                <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>
                                    {' '}
                                    <p>Une erreur s'est produite, la duplication a échouée.</p>
                                </div>
                            ),
                            width: 960,
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            customClass: {
                                confirmButton: 'btn btn-continue min-width',
                            },
                        });
                    });
            }
        });
    };

    const cancelRenovationAmpleur = async (): Promise<void> => {
        if (!dossierOuvert) return;

        const initialValues: CancelFormData = {
            cancelledReason: '',
        };
        await AlertSwal.fire({
            title: <h2>Annuler le dossier</h2>,
            html: (
                <CancelForm
                    ref={cancelFormikRef}
                    initialValues={initialValues}
                    onClose={() => {
                        AlertSwal.close();
                    }}
                    onSubmit={(values: CancelFormData) => {
                        try {
                            AlertSwal.clickConfirm();
                        } catch (error) {
                            AlertSwal.showValidationMessage('Le formulaire contient des erreurs.');
                        }
                    }}
                />
            ),
            width: 960,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            preConfirm: async (): Promise<CancelFormData | null> => {
                if (cancelFormikRef.current) {
                    await cancelFormikRef.current.submitForm();
                }

                if (!cancelFormikRef.current?.isValid) {
                    Swal.showValidationMessage("Veuillez compléter le formulaire avant d'envoyer.");
                    return null;
                }

                // Déplacer l'appel API ici
                if (cancelFormikRef.current?.values && props.dossierRenoDAmpleur?.flowId) {
                    try {
                        // console.log('appel api avec = ' + JSON.stringify(formikRef.current.values.comment, null, 2));
                        apiDRA.cancelDra(props.dossierRenoDAmpleur.id, cancelFormikRef.current.values.cancelledReason).then(() => {
                            // on le fait aps dans await car on veut que le code continue
                            // Recharger la page après l'envoi réussi
                            window.location.reload();
                        });
                    } catch (error) {
                        console.error("Erreur lors de l'annulation du dossier:", error);
                        Swal.showValidationMessage("Une erreur s'est produite lors de l'annulation du dossier.");
                        return null;
                    }
                }

                return cancelFormikRef.current?.values || null;
            },
        });
    };

    if (!hasRenovationDampleur) return null;

    return (
        <div>
            <div className="container-detail py-4 mb-4">
                <div className="row">
                    <h2 className="d-flex justify-content-between w-100">
                        Rénovation d'Ampleur
                        {!props.isReadonly && (
                            <div className="btn-grp">
                                {showBtnCancel && (
                                    <button className="btn btn-change-pref position-static mw-100" onClick={cancelRenovationAmpleur}>
                                        Annuler le dossier
                                    </button>
                                )}

                                {showBtnDuplicate && (
                                    <button className="btn btn-change-pref position-static mw-100" onClick={duplicateAuditMar}>
                                        Dupliquer
                                    </button>
                                )}
                                {showBtnSendMail && (
                                    <button className="btn btn-change-pref position-static mw-100" onClick={envoyerMailAuMar}>
                                        Envoyer un mail au MAR
                                    </button>
                                )}
                            </div>
                        )}
                    </h2>

                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <div className="form-inline">
                            <FormRow
                                label="Etat du dossier"
                                value={isCustomerEligible ? dossierRenoDAmpleurStateToString(props.dossierRenoDAmpleur.state) : 'Inéligible'}
                            />
                            {isCustomerEligible && (
                                <>
                                    <FormRow
                                        label="Client éligible"
                                        value={isCustomerEligible === true ? 'Oui' : isCustomerEligible === false ? 'Non' : 'Pas encore vérifié'}
                                    />
                                    <FormRow label="Demande de contact MAR" value={marShouldContactCustomer ? 'Oui' : 'Non'} />
                                    <FormRow label="Mandat Anah signé le" value={dateFormat(mandatAnahDate)} />
                                </>
                            )}
                        </div>
                    </div>

                    {showMarInfos && (
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <div className="form-inline">
                                <>
                                    <FormRow label="Société MAR" value={props.dossierRenoDAmpleur?.mar?.company ?? '-'} />
                                    <FormRow label="Prénom MAR" value={props.dossierRenoDAmpleur?.mar?.firstname ?? '-'} />
                                    <FormRow label="Email MAR" value={props.dossierRenoDAmpleur?.mar?.email ?? '-'} />
                                    <FormRow label="Nom MAR" value={props.dossierRenoDAmpleur?.mar?.lastname ?? '-'} />
                                    <FormRow label="Téléphone MAR" value={props.dossierRenoDAmpleur?.mar?.phoneNumber ?? '-'} />
                                </>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <div className="form-inline">
                            {
                                <div className="border rounded p-3 mb-3">
                                    <DraMakeItReady dossierRenoDAmpleur={props.dossierRenoDAmpleur} />
                                </div>
                            }
                            {isCustomerEligible && marShouldContactCustomer && (
                                <>
                                    {props.dossierRenoDAmpleur.marSelectedAt && (
                                        <FormRow label="Date d'envoi d'email au MAR" value={dateFormat(props.dossierRenoDAmpleur.marSelectedAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.marContactedAt && (
                                        <FormRow label="Date de demande d'intervention MAR" value={dateFormat(props.dossierRenoDAmpleur.marContactedAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.interventionAt && (
                                        <FormRow label="Date d'intervention du MAR prévue le" value={dateFormat(props.dossierRenoDAmpleur.interventionAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.marAuditReview && props.dossierRenoDAmpleur.marAuditReview.length > 0 && (
                                        <FormRowMultiline label="Commentaires du MAR" values={props.dossierRenoDAmpleur.marAuditReview} />
                                    )}
                                    {props.dossierRenoDAmpleur.grantRequestedAt && (
                                        <FormRow label="Date de demande de subvention" value={dateFormat(props.dossierRenoDAmpleur.grantRequestedAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.grantedAt && (
                                        <FormRow label="Date d'accord de la subvention" value={dateFormat(props.dossierRenoDAmpleur.grantedAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.grantRejectedAt && (
                                        <FormRow label="Date de rejet de la subvention" value={dateFormat(props.dossierRenoDAmpleur.grantRejectedAt)} />
                                    )}

                                    {props.dossierRenoDAmpleur.cancelledAt && (
                                        <FormRow label="Date d'annulation du dossier" value={dateFormat(props.dossierRenoDAmpleur.cancelledAt)} />
                                    )}
                                    {props.dossierRenoDAmpleur.cancelledReason && (
                                        <FormRow label="Raison d'annulation du dossier" value={props.dossierRenoDAmpleur.cancelledReason} />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <DocumentList title="Liste des documents du dossier Reno D'ampleur" rows={[draRow]} AddDocument={undefined} /> */}
        </div>
    );
};

export default DossierRenoDampleur;
