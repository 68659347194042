import { ReactComponent as IconEye } from '../../assets/icons/espace-agent/icon-eye.svg';
import { ReactComponent as IconDownload } from '../../assets/icons/espace-agent/icon-download.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DocumentsViewer } from './DocumentsViewer';

// Style
import '../../pages/espace-agent/list-audit/ListAudit.scss';

const viewableDocs = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'ico', 'webp'];
const downloadableDocs = ['zip', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

export type DocumentEntry = {
    title: string;
    url: string;
    extension: string;
    comment?: string | undefined;
};
export type DocumentRow = Array<DocumentEntry>;

type DocumentsProps = {
    title: string;
    rows: DocumentRow[];
    AddDocument?: (() => void) | undefined;
};

const DocumentList: React.FC<DocumentsProps> = (props: DocumentsProps) => {
    const AlertSwal = withReactContent(Swal);
    // Initialiser l'icône avec les props nécessaires

    const showDoc = (doc: DocumentEntry) => {
        // Prevent document title null
        if (!doc || !doc.title || !doc.url) return;

        AlertSwal.fire({
            title: '',
            html: <DocumentsViewer doc={doc} />,
            width: 900,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
        });
    };

    const downloadDoc = (doc: DocumentEntry) => {
        if (!doc) return;

        // Create dynamic <a> element
        const link = document.createElement('a');
        link.href = doc.url;
        //link.download = props.file.fileName; // Nom de fichier souhaité
        link.download = doc.title; // Nom de fichier souhaité

        document.body.appendChild(link);
        link.click();

        // Destroy element after download
        document.body.removeChild(link);
    };

    return (
        <div className="container-detail py-4 mb-4">
            <div className="row mb-4">
                <h2>{props.title}</h2>
                {/* {props.AddDocument && (
                    <div className="btn-group ms-auto">
                        <button type="button" className="btn btn-save" onClick={props.AddDocument}>
                            Ajouter un document
                        </button>
                    </div>
                )} */}
            </div>
            {props.rows.map((row, index) => {
                return (
                    <div className="row mb-3" key={index}>
                        {row.map((doc, index2) => {
                            //console.log('doc ' + index2);
                            const isLast = index === row.length - 1;
                            const divClassName = isLast ? 'col-12 col-md-3' : 'col-12 col-md-3 mb-4';
                            const btnClassName = 'btn btn-orange btn-orange--icon w-100';
                            if (viewableDocs.includes(doc.extension)) {
                                return (
                                    <div key={index2} className={divClassName}>
                                        <button type="button" className={btnClassName} onClick={() => showDoc(doc)}>
                                            <IconEye width={24} fill="#FFF" />
                                            {doc.comment}
                                            {doc.title}
                                        </button>
                                    </div>
                                );
                            } else if (downloadableDocs.includes(doc.extension)) {
                                return (
                                    <div key={index2} className={divClassName}>
                                        <button type="button" className={btnClassName} onClick={() => downloadDoc(doc)}>
                                            <IconDownload fill="#FFF" />
                                            {doc.title}
                                        </button>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={index2} className={divClassName}>
                                        <button type="button" className={btnClassName}>
                                            {doc.comment}
                                            <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {doc.title}
                                            </span>
                                        </button>
                                    </div>
                                );
                            }
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default DocumentList;
