import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';

import { ReactComponent as LoaderSVG } from '../../../assets/icons/loader.svg';
import { CreateContractOutput } from '../../../services/apiTypes';

export type NetheosEnedisPropsContext = {
    flowId: string;
    pdlNumber: string;
    emptyNetheosContract: CreateContractOutput<'consentement_enedis'>;
};

type NetheosEnedisProps = {
    token: string;
    context: NetheosEnedisPropsContext;
    onEnedisConsentSigned: (context: NetheosEnedisPropsContext) => Promise<boolean>;
};

export const NetheosEnedis: React.FC<NetheosEnedisProps> = (props: NetheosEnedisProps) => {
    const signbookRef = useRef<any>(null);
    const [waitUpload, setWaitUpload] = useState<boolean>(false);

    function onErrorEventMessageFn(event: any) {
        // Errors detected by the SignBook (server errors, invalid state, unsupported browser, etc.)
        if (event.cause === 'REJECTED_STATE' || event.cause === 'ACCEPTED_STATE' || event.cause === 'SUSPENDED_STATE' || event.cause === 'WAITING_STATE') {
            // Do not deal with these "errors", this is just for backwards compatibility purpose...
            return;
        }
        console.log('netheos signature error event:', event.cause);
    }

    // Retours event iFrame
    function onExitEventMessageFn(event: any) {
        // TODO Fermer la popup avec isConfirmed à false.
        console.log('netheos signature exit.');
        Swal.close({ isConfirmed: false, isDenied: true, isDismissed: true });
    }

    const onClientFileEventMessage = async (event: any) => {
        // Event coming from eKeynox SaaS with data concerning the state of the client file
        if (
            (event.changedState && event.changedState.from === 'PENDING' && (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED')) ||
            event.participantState === 'WAITING'
        ) {
            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error('Impossible de valider le rdv, parcours utilisateur inexistant');
            }
            // TODO Fermer la popup avec isConfirmed à true.
            console.log('netheos signature success.');
            // CALL BACK
            setWaitUpload(true);
            const success = await props.onEnedisConsentSigned(props.context);
            setWaitUpload(false);
            // The close container popup
            Swal.close({ isConfirmed: success, isDenied: false, isDismissed: false });
        } else {
            if (event.state !== 'PENDING') {
                console.log('netheos signature State:', event.state);
            } else {
                console.log('netheos signature State:', event.state);
            }
        }
    };

    const onUserEventMessage = (event: any) => {
        console.log('onUserEventMessage = ' + JSON.stringify(event, null, 2));
        //     onUserEventMessage = {
        //     "type": "userEvent",
        //     "action": "REFUSE_SIGNATURE",
        //     "context": {
        //       "userIndex": 1,
        //       "page": "signature"
        //     }
        //   }
        if (event && event.action === 'REFUSE_SIGNATURE') {
            console.log('netheos signature refus de signature ');
            Swal.close({ isConfirmed: false, isDenied: false, isDismissed: false });
        }
    };

    // PI :
    // NthSignbook.config :
    // var defaultConfig = {
    //     iframeSelectorId: undefined, // iframe element Id is mandatory
    //     token: undefined, // user's token is mandatory (must be filled by param URL or by this property
    //     url: undefined, // url of the target signbook page is mandatory
    //     options: {
    //       lang: undefined, // language code example 'fr'
    //       renderMode: undefined, // 'tiny' | 'pretty'
    //       vidRequestMode: undefined, // 'redirect' | 'popup' if undefined then popup mode will be used
    //       displayedPages: undefined, // 'signature' | 'attachments' | 'signature,attachments'
    //       documentCategories: undefined, // 'ID_DOCUMENT' | 'BANKING_DOCUMENT' | 'ADDRESS_DOCUMENT' | 'INCOME_DOCUMENT' | 'UNDEFINED'
    //       callbackURL: undefined, // to override the callback url instead of taking window.location.href that will be passed to the signbook
    //       delegateeToken: undefined // delegatee user's token
    //     },
    //     onDisplayEventMessage: function () {},
    //     onUserEventMessage: function () {},
    //     onClientFileEventMessage: function () {},
    //     onExitEventMessage: function () {},
    //     onErrorEventMessage: function () {},
    //   };

    useEffect(() => {
        const initializeSignbook = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-new, no-undef
            signbookRef.current = new NthSignbook({
                iframeSelectorId: 'enedis',
                url: `${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page`,
                token: props.token,
                options: {
                    renderMode: 'pretty',
                },
                onExitEventMessage: onExitEventMessageFn,
                onErrorEventMessage: onErrorEventMessageFn,
                onClientFileEventMessage: onClientFileEventMessage,
                onDisplayEventMessage: () => {}, //(event: any) => {console.log('onDisplayEventMessage = ' + JSON.stringify(event, null, 2));},
                onUserEventMessage: onUserEventMessage,
            });
        };
        const destroySignbook = () => {
            if (signbookRef.current) {
                signbookRef.current.destroy();
            }
        };

        initializeSignbook();

        // Clean up event listeners when the component unmounts
        return () => {
            destroySignbook();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {waitUpload ? (
                <div className="text-center">
                    <LoaderSVG width={80} height={80} />
                    <p className="mb-0">
                        Mise à jour de votre consentement de collecte de données Enedis.
                        <br />
                        Cette action peut durer plusieurs secondes, merci de patienter.
                    </p>
                </div>
            ) : (
                <iframe id="enedis" title="Enedis" width="100%" height="500px" allow="microphone; camera" style={{ border: '0', overflow: 'hidden' }} />
            )}
        </>
    );
};
