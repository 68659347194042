import React, { ChangeEvent, useState } from 'react';
import { useField, Field } from 'formik';
import imageCompression from 'browser-image-compression';

import './FileUpload.scss';

// Icon
import { ReactComponent as IconUpload } from '../../assets/icons/pre-visite/icon-upload.svg';
import { ReactComponent as IconCheck } from '../../assets/icons/pre-visite/icon-check.svg';

interface UploadFileProps {
    label: string;
    name: string;
    onChange?: (file: File) => void;
    className?: string;
    isDisabled?: boolean;
    style?: React.CSSProperties;
}

const FileUpload: React.FC<UploadFileProps> = ({ label, name, onChange, className, isDisabled, style }) => {
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [field, , helpers] = useField(name);
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(isDisabled || false);

    const compressImage = async (file: File): Promise<File> => {
        // Debug
        // console.log(`Before compression - File size (${file.name}): ${file.size / 1024} KB`);

        const options = {
            maxSizeMB: 5, // Maximum size in Mb
            maxWidthOrHeight: 1000, // Max width or height
            useWebWorker: true, // Better performance
        };

        try {
            const compressedFile = await imageCompression(file, options);
            // Debug
            // console.log(`After compression - File size (${compressedFile.name}): ${compressedFile.size / 1024} KB`);

            return compressedFile as File;
        } catch (error) {
            return file;
        }
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            // Check file type
            const fileType = files[0].type;

            // If file type is application/pdf
            if (fileType === 'application/pdf') {
                helpers.setValue(files[0]);
                setUploaded(true);
                if (onChange) {
                    onChange(files[0]);
                }
                return;
            }

            // Compress file before uploading
            const compressedFile = await compressImage(files[0]);

            helpers.setValue(compressedFile);
            setUploaded(true);
            setUploadDisabled(true);
            if (onChange) {
                onChange(compressedFile);
            }
        }
    };

    const handleBlur = () => {
        helpers.setTouched(true);
    };

    const acceptedFiles: string = 'image/jpg, image/jpeg, image/png, application/PDF';

    return (
        <div className={`file-uploader ${className}`} data-upload={uploaded ? 'filled' : 'empty'} style={style}>
            <label className="file-uploader__label" htmlFor={name}>
                {field.value ? <>{field.value.name}</> : <>{label}</>}
            </label>
            <span className="file-uploader__info">(jpg, jpeg, png, pdf)</span>
            <div className="icon">
                <IconUpload fill="#0072a2" />
            </div>
            <Field
                type="file"
                name={name}
                id={name}
                accept={acceptedFiles}
                onChange={handleFileChange}
                onBlur={handleBlur}
                value={undefined} // Prevent formik from displaying the file name
                className="file-uploader__input"
                disabled={isDisabled ? uploadDisabled : false}
            />
            {field.value && (
                <div className="icon--filled">
                    <IconCheck fill="white" />
                </div>
            )}
        </div>
    );
};

export default FileUpload;
