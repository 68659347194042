import * as defs from './aideRenovationDAmpleurDefs';
// import { TUScenarios } from './tuScenario';

import * as ticketService from '../../calculs/ticket';
import * as themesService from '../../calculs/theme';
import * as themesTools from '../../calculs/themesTools';

import { clearAllPackageHelp } from '../../../pages/audit-simulator/ticketUpdater';
import { PackageSelector } from '../packageSelectorSpecialRules';
import { currencyFormat, parseCurrency } from '../../tools/TypeHelper';
// import { scenarioToNumbers, scenarioWithPriceToNumbers } from './tuScenario';
import { PackagesEntries } from '../../apiParticulierService';
import { PackageData } from '../package';
// import { prettyLog } from '../../tools/auditTools';

export const isElligible = (flatAudit: any): defs.RenovationDAmpleur => {
    //console.log('isElligible entered');
    const notElligible: defs.RenovationDAmpleur = { elligible: false, marShouldContactCustomer: false };
    // console.log('  => check is reno globale elligible');
    if (!flatAudit) {
        // console.log('  => Not elligible : audit null');
        return notElligible;
    }
    // J'élimine tous les cas défavorables, au fur et a mesure
    // Si j'arrive en bas, c'est que je suis élligible.

    // si ce n'est PAS une maison individuelle => j'élimine
    if (!flatAudit.projectType || !flatAudit.projectType.value_label || flatAudit.projectType.value_label !== 'Maison individuelle') {
        // console.log("  => Not elligible :!== 'Maison individuelle' ");
        return notElligible;
    }
    // si je ne suis PAS propriétaire => j'élimine
    if (!flatAudit.projectStatus || !flatAudit.projectStatus.value_label || flatAudit.projectStatus.value_label !== 'Propriétaire') {
        // console.log("  => Not elligible : !== 'Propriétaire'");
        return notElligible;
    }
    // si la maisons a MOINS DE 15 ans => j'élimine
    if (!flatAudit.houseAge || !flatAudit.houseAge.value) {
        // console.log('  => Not elligible : !audit.houseAge.value ');
        return notElligible;
    }
    if (new Date().getFullYear() - +flatAudit.houseAge.value < 15) {
        // console.log('  => Not elligible : maison récente');
        return notElligible;
    }

    const comble = !flatAudit.ceilingInsulationPresence || flatAudit.ceilingInsulationPresence.value === true;
    const rampant = !flatAudit.rampantIsolated || flatAudit.rampantIsolated.value === true;
    const mur = !flatAudit.exteriorWallInsulationPresence || flatAudit.exteriorWallInsulationPresence.value === true;
    const plancher = !flatAudit.floorInsulationPresence || flatAudit.floorInsulationPresence.value === true;

    // si on a toutes ces isolations, on est pas élligible
    //&& rampant
    if ((rampant || comble) && mur && plancher) {
        console.log("  => Not elligible : trop d'isolation ");
        return notElligible;
    }

    if (!flatAudit.department || flatAudit.department.value === undefined || flatAudit.department.value.length < 2) {
        // console.log('  => Not elligible : !department');
        return notElligible;
    }
    const department = flatAudit.department.value.slice(0, 2);

    let nombrePersonne = 1; // le déclarant
    if (flatAudit.userMaritalStatus && flatAudit.userMaritalStatus.value && flatAudit.userMaritalStatus.value !== '1')
        // celibataire
        nombrePersonne++;
    if (flatAudit.children && flatAudit.children.value !== undefined) nombrePersonne += +flatAudit.children.value;

    // Si le revenu n'est pas renseigné (on devrait prendre 0 ?) => j'élimine
    if (!flatAudit.taxIncome || flatAudit.taxIncome.value === undefined) {
        // console.log('  => Not elligible : !tax');
        return notElligible;
    }

    const revenu = +flatAudit.taxIncome.value + (flatAudit.taxIncome2 && flatAudit.taxIncome2.value !== undefined ? +flatAudit.taxIncome2.value : 0);

    const catRevenu = defs.getCategorieRevenus(nombrePersonne, revenu, department);
    const scenarios = createScenarios(flatAudit);
    return {
        elligible: true,
        // a ce stade on ne sait pas encore ! ca se décidera apr la popup.
        marShouldContactCustomer: undefined,
        categorieRevenus: catRevenu,
        scenarios,
        scenariosText: scenariosToString(scenarios),
        renoTextes: defs.RENO_TEXT_PAR_REVENUS[catRevenu],
        plafonds: defs.RENO_PLAFOND_TEXT,

        // seront définis plus tard.
        scenariosExt: undefined,
        scenariosExtText: undefined,
    };
};

export const getMontantAideRenoDAmpleur = (reno: defs.RenovationDAmpleur, params: defs.RenovationDAmpleurParams): [max: number, ratio: number] | undefined => {
    if (!reno.elligible) return undefined;

    const max = defs.RENO_PLAFOND_CONST[params.niveau];
    let ratio = defs.RENO_VALUES[reno.categorieRevenus][params.niveau];
    if (params.sortiePassoire) ratio += defs.BONIFICATION_PASSOIRE;
    return [max, ratio];
};
export const getFraisRenoDAmpleur = (): number => {
    return defs.RENO_COUT_PRESTATION_MAR;
};
export const getMontantAideFraisRenoDAmpleur = (reno: defs.RenovationDAmpleur, params: defs.RenovationDAmpleurParams): number | undefined => {
    if (!reno.elligible) return undefined;
    let ratio = defs.RENO_VALUES[reno.categorieRevenus].aideFraisExpertise;
    const plafond = defs.RENO_PLAFOND_CONST.aideFraisExpertise;

    return plafond * ratio;
};

// Pour tester les différents scénarios, sans refaire la saisie de 10 audits.
// TUScenarios();

export const createScenarios = (audit: any): Array<defs.Scenario> => {
    const scenario1: defs.Scenario = {
        'Isolation Combles': false,
        'Isolation Rampants': false,
        'Isolation des planchers bas': false,
        'Isolation des murs exterieurs': false,
        'Pompe à chaleur air eau': false,
        'Pompe à chaleur air air': false,
        'Ballon Thermodynamique': false,
        'Ventilation mécanique double flux (VMC)': false,
        "Porte d'entée isolante": false,
    };

    // Etat actuel :

    const comblesNonAmenaged = audit.ceilingType !== undefined && audit.ceilingType.value === '0';
    const comblesAmenaged = audit.ceilingType !== undefined && audit.ceilingType.value === '2';
    // const toitureTerrasse = audit.ceilingType !== undefined && audit.ceilingType.value === '3';
    // const plafondMitoyen = audit.ceilingType !== undefined && audit.ceilingType.value === '4';

    let comble: boolean;
    let rampant: boolean;

    //prettyLog('audit', audit, true);
    // logique un peu tordue :
    // si les combles sont isolé, on met true.
    // si les rampants sont isolés, on met true.
    // si on ni comble ni rampant, (genre plafond mittoyens) on met true quand même, parce qui nous interresse ce sont les false.
    if (comblesNonAmenaged) {
        const currentYear = new Date().getFullYear();
        let presenceComble = true;
        if (audit.ceilingInsulationPresence === undefined || audit.ceilingInsulationPresence.value === false) presenceComble = false;
        // si l'epaisseur est < 25 cm
        if (audit.ceilingInsulationThickness === undefined || +audit.ceilingInsulationThickness.value < 25) presenceComble = false;
        // si l'isolation des comble est trop ancienne > 20 ans, on considère a refaire :
        if (audit.ceilingInsulationAge !== undefined && +audit.ceilingInsulationAge.value < currentYear - 20) presenceComble = false;

        comble = presenceComble;
        rampant = false;
    } else if (comblesAmenaged) {
        comble = false;
        rampant = audit.rampantIsolated && audit.rampantIsolated.value === true;
    } else {
        // dans le cas ou on a pas de combles, on met true, on considère que c'est comme si l'isolation existait.
        comble = true;
        rampant = true;
    }
    // a partir de la on sait qu'on a les variables !combles, et !rampants qui sont celle qu'on devrait avoir en sortie des scénarios

    const needIsolationPlancher = audit.floorType !== undefined && (audit.floorType.value === '2' || audit.floorType.value === '3');
    const HasIsoPlancher =
        audit.floorType !== undefined &&
        (audit.floorType.value === '2' || audit.floorType.value === '3') &&
        audit.floorInsulationPresence !== undefined &&
        audit.floorInsulationPresence.value === true;
    //console.log('plancher ' + HasIsoPlancher);

    const murExt =
        audit.exteriorWallInsulationPresence !== undefined &&
        audit.exteriorWallInsulationPresence.value === true &&
        audit.exteriorWallInsulationExterior !== undefined &&
        audit.exteriorWallInsulationExterior.value === true;
    const murIntSup12 =
        audit.exteriorWallInsulationPresence !== undefined &&
        audit.exteriorWallInsulationPresence.value === true &&
        audit.exteriorWallInsulationExterior !== undefined &&
        audit.exteriorWallInsulationExterior.value === false &&
        audit.exteriorWallInsulationThickness !== undefined &&
        audit.exteriorWallInsulationThickness.value >= 12;
    const mur = murExt || murIntSup12;
    const combleOuRamapant = comble === true || rampant === true;

    //console.log('create scenario combleOuRamapant ' + combleOuRamapant + ' plancher ' + HasIsoPlancher + ' mur ' + mur);
    // on met e place le scénario d'isolation :
    // on sait qu'on a au moins un des 3 types d'isolation qui n'est pas déjà installé, car sinon, on est pas elligible.
    if (combleOuRamapant && (!needIsolationPlancher || HasIsoPlancher) && mur) return [];

    if (!comble && !rampant) {
        // on a ni combles ni rampants, on propose en fonction de l'aménagement de combles
        scenario1['Isolation Rampants'] = comblesAmenaged && !rampant; // 1
        scenario1['Isolation Combles'] = comblesNonAmenaged && !comble; // 1

        scenario1['Isolation des planchers bas'] = needIsolationPlancher && !HasIsoPlancher; // on prend si pas existant
        // On a déjà le 1 (que ce soit comble ou rampant), on élimine les murs
        scenario1['Isolation des murs exterieurs'] = false;
        // si on a le 2 (plancher), on ne propose pas la porte, sinon, on la propose

        scenario1["Porte d'entée isolante"] = !scenario1['Isolation des planchers bas'];
    } else {
        // On a déjà soit les combles soit les rampants
        // on ne propose aucun de ces deux la.
        scenario1['Isolation Rampants'] = false; // 1
        scenario1['Isolation Combles'] = false; // 1
        // on propose le plancher si pas existant
        scenario1['Isolation des planchers bas'] = needIsolationPlancher && !HasIsoPlancher; // 2

        // si on a déja le plancher isolé (2), et déjà les comble et/ou rampant isolés (1)
        // alors on propose les murs si disponibles
        // sinon, on va proposé le plancher, donc pas les murs.
        scenario1['Isolation des murs exterieurs'] = scenario1['Isolation des planchers bas'] ? false : !mur;
        // pour la porte, comme on a pas besoin d'ajouter le 1, on popose la porte !
        scenario1["Porte d'entée isolante"] = true; // 7
    }

    // maintenant, la pompe à chaleur :
    const pompe = (audit.heaterType !== undefined && audit.heaterType.value === '3') || (audit.heaterType2 !== undefined && audit.heaterType2.value === '3');
    const combustible = audit.heaterType !== undefined && (audit.heaterType.value === '4' || audit.heaterType.value === '6'); // gaz ou fioul
    const elec = audit.heaterType !== undefined && audit.heaterType.value === '0'; // elec

    // console.log('audit.heaterType ' + (audit.heaterType ? 'defined' : 'undefined') + '  combustible ' + combustible + ' elec ' + elec);
    if (!pompe) {
        scenario1['Pompe à chaleur air eau'] = combustible;
        scenario1['Pompe à chaleur air air'] = !combustible && elec; // todo Check
        // Si les

        scenario1['Ventilation mécanique double flux (VMC)'] = !scenario1['Pompe à chaleur air eau'] && !scenario1['Pompe à chaleur air air'];
    } else {
        // on a déjà une pompe
        scenario1['Pompe à chaleur air eau'] = false;
        scenario1['Pompe à chaleur air air'] = false;
        // on compense avec une ventilation 6
        scenario1['Ventilation mécanique double flux (VMC)'] = true;
    }

    const chaufeEauThermoDynamique = audit.heaterHotWaterThermodynamic !== undefined && audit.heaterHotWaterThermodynamic.value === true;
    if (!chaufeEauThermoDynamique) {
        scenario1['Ballon Thermodynamique'] = true;
    } else {
        scenario1['Ballon Thermodynamique'] = false;
        // on compense avec 6,7,8
        scenario1['Ventilation mécanique double flux (VMC)'] = true; // 6
        scenario1["Porte d'entée isolante"] = true; //  7
    }

    // On aimerait proposer (ou pas) la vmc, c'est déjà calculé.
    // mais on s'assure que c'est pas déjà installé
    // si on a une double flux
    const hasDoubleFlux =
        audit.ventilationType !== undefined &&
        +audit.ventilationType.value === 5 &&
        audit.ventilationInstallationDate !== undefined &&
        audit.ventilationInstallationDate.value === true;

    scenario1['Ventilation mécanique double flux (VMC)'] = scenario1['Ventilation mécanique double flux (VMC)'] && !hasDoubleFlux; // 6

    const scenarioTous: defs.Scenario = {
        'Isolation Combles': scenario1['Isolation Combles'], // toujours égal au scénario 1
        'Isolation Rampants': scenario1['Isolation Rampants'], // toujours égal au scénario 1
        'Isolation des planchers bas': scenario1['Isolation des planchers bas'],
        'Isolation des murs exterieurs': !mur,
        'Pompe à chaleur air eau': scenario1['Pompe à chaleur air eau'],
        'Pompe à chaleur air air': scenario1['Pompe à chaleur air air'], // vérifier si c'est pas plus compliqué
        'Ballon Thermodynamique': !chaufeEauThermoDynamique,
        'Ventilation mécanique double flux (VMC)': !hasDoubleFlux,
        "Porte d'entée isolante": true,
    };

    return [scenario1, scenarioTous];
    // return [scenario1];
    //return [scenarioTous];
};

export const scenariosToString = (scenarios: Array<defs.Scenario>): Array<string> => {
    const stringnified: Array<string> = scenarios.map((s) => scenarioToString(s));
    const set = new Set(stringnified);
    return Array.from(set);
};

export const scenarioToString = (scenario: defs.Scenario): string => {
    const result = new Array<string>();
    Object.entries(scenario).forEach((value: [string, boolean]) => {
        if (value && value[1]) result.push(value[0]);
    });
    return result.join(', ');
};

export const scenarioExtToString = (scenario: defs.ScenarioExt): [string, string] => {
    const total = 'Total HT ' + currencyFormat(scenario.totalHt) + '. Reste à charge ' + currencyFormat(scenario.resteACharge) + '.';
    const products = new Array<string>();
    for (const name of defs.scenarioShortNamesNames) {
        if (scenario[name].inclus) products.push(name + ' (' + currencyFormat(scenario[name].prixHt) + ')');
    }
    return [products.join(', ') + '.', total];
};
// TUScenarios();

export const packageSelectorForRenoDAmpleurMar: PackageSelector = (themes: Array<themesService.Theme>) => {
    // On fait un gros clone profond, pour que tout soit en lecture écriture.
    const allThemeClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;

    // On déselectionne photovoltaique et MMA .
    for (const theme of allThemeClone) {
        // Dans economie d'energie, on déselectione, PV et chgt comportemental.
        if (theme.themeType === "Economie d'énergie") {
            if (!theme.subThemes) continue;
            for (const subTheme of theme.subThemes) {
                const type = subTheme.subThemeType as themesService.SubThemeType;
                if (type === 'Photovoltaïque' || type === 'Changement comportemental') {
                    for (const pack of subTheme.packages) {
                        pack.applicable = false;
                    }
                }
            }
        } else if (theme.themeType === 'Services') {
            if (!theme.subThemes) continue;
            for (const subTheme of theme.subThemes) {
                const type = subTheme.subThemeType as themesService.SubThemeType;
                if (type === 'Photovoltaïque' || type === 'Garantie de revenu solaire' || type === 'Changement comportemental') {
                    for (const pack of subTheme.packages) {
                        pack.applicable = false;
                    }
                }
            }
        } else {
            // On dégate tout le reste.
            if (!theme.subThemes) continue;
            for (const subTheme of theme.subThemes) {
                for (const pack of subTheme.packages) {
                    pack.applicable = false;
                }
            }
        }
    }

    return allThemeClone;
};

export const upgradeReno = async (reno: defs.RenovationDAmpleurElligible, themes: Array<themesService.Theme>): Promise<defs.RenovationDAmpleurElligible> => {
    const renoClone = { ...reno };
    // console.log('upgrade scenario entered');
    let allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;

    // efface les aides qui existait déjà
    clearAllPackageHelp(allThemesClone);
    //clearAllPackageHelp(allThemesClone2);
    const auditId = localStorage.getItem('auditId')!;

    let clone = packageSelectorForRenoDAmpleurMar(allThemesClone);

    renoClone.scenariosExt = new Array<defs.ScenarioExt>();
    renoClone.scenariosExtText = new Array<[string, string]>();

    // let i = 0;
    for (const scenar of renoClone.scenarios) {
        // apply filter for package, for scnéario1.
        const [partialscenarWithPrices, packages] = buildScenarioWithPrice(scenar, clone);
        // Obtient le ticket du WS.
        //const packages = themesService.getSelectedPackagesWithQty(clone);
        const scenarWithPrices: defs.ScenarioWithPrice = {
            'Isolation Combles': { inclus: false, prixHt: 0 },
            'Isolation Rampants': { inclus: false, prixHt: 0 },
            'Isolation des planchers bas': { inclus: false, prixHt: 0 },
            'Isolation des murs exterieurs': { inclus: false, prixHt: 0 },
            'Pompe à chaleur air eau': { inclus: false, prixHt: 0 },
            'Pompe à chaleur air air': { inclus: false, prixHt: 0 },
            'Ballon Thermodynamique': { inclus: false, prixHt: 0 },
            'Ventilation mécanique double flux (VMC)': { inclus: false, prixHt: 0 },
            "Porte d'entée isolante": { inclus: false, prixHt: 0 },
            ...partialscenarWithPrices,
        };
        // console.log('scénario ' + i + ' : ' + scenarioToNumbers(scenar));
        // console.log('           : ' + scenarioWithPriceToNumbers(scenarWithPrices));

        // appel du WS icoll
        const ticket = await ticketService.simulateDevis(auditId, packages);

        // défaut pour les scénarios chiffrés.
        const renoDAmpleurParams: defs.RenovationDAmpleurParams = { niveau: 'gain4classes', sortiePassoire: false };
        // créer les aides locales !
        // filtrer les non compatibles avec RA.
        // recalculer les deux aides RA avec le montant ht du devis.
        const aidesLocales: ticketService.AidesLocalesList = {
            // ...aidesLocalesWithoutRA,

            //[ticketService.FRAIS_EXPERTISE_RENO_AMPLEUR]: ticketService.createFraisRenoDAmpleur(renoClone, renoDAmpleurParams, true),
            //[ticketService.AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR]: ticketService.createAideFraisRenoDAmpleur(renoClone, renoDAmpleurParams, true),
            [ticketService.AIDE_RENO_AMPLEUR]: ticketService.createAideRenoDAmpleur(renoClone, renoDAmpleurParams, ticket, true),
        };

        // A partir de la on connait les aides applicable sur _ce_ ticket.
        // On complete avec les aides locales, et on recalcule le ticket.
        // Comme on simule une rénovation d'ampleur : on force IsMar = true (dernier paramètre.)
        ticketService.manageLocaleAide(ticket, aidesLocales, true);
        ticketService.recalculTicket(ticket, true);
        //ticket.
        const scenarioExt: defs.ScenarioExt = {
            totalHt: parseCurrency(ticket.montant_vente_ht),
            resteACharge: parseCurrency(ticket.reste_a_charge),
            ...scenarWithPrices,
        };
        // console.log(
        //     'ht : ' + scenarioExt.totalHt + ' ttc : ' + ticket.montant_vente + ' aides : ' + ticket.montant_aides_cumul + 'rac : ' + scenarioExt.resteACharge
        // );

        renoClone.scenariosExt.push(scenarioExt);
        renoClone.scenariosExtText.push(scenarioExtToString(scenarioExt));
        // i++;
    }

    return renoClone;
};

export const buildScenarioWithPrice = (
    scenario: defs.Scenario,
    allThemesClone: Array<themesService.Theme>
): [Partial<defs.ScenarioWithPrice>, PackagesEntries] => {
    type X = { inclus: boolean; prixHt: number; package: PackageData };
    const arrayOfResult: { [key in defs.ScenarioShortNames]: Array<X> } = {
        'Isolation Rampants': new Array<X>(),
        'Isolation Combles': new Array<X>(),
        'Isolation des planchers bas': new Array<X>(),
        'Isolation des murs exterieurs': new Array<X>(),
        'Pompe à chaleur air eau': new Array<X>(),
        'Pompe à chaleur air air': new Array<X>(),
        'Ballon Thermodynamique': new Array<X>(),
        'Ventilation mécanique double flux (VMC)': new Array<X>(),
        "Porte d'entée isolante": new Array<X>(),
    };

    const entries: PackagesEntries = [];

    for (const theme of allThemesClone) {
        for (const subTheme of theme.subThemes!) {
            for (const article of subTheme.packages) {
                const name: defs.ScenarioShortNames | undefined = article.scenarioNameForRA;
                if (!name) continue;
                arrayOfResult[name].push({ inclus: scenario[name], prixHt: article.priceHt, package: article });
                // if (scenario[name] === false) continue;
                // entries.push(themesService.getPackageWithQty(article));
            }
        }
    }

    // array of result,
    // dans chaque entrée, il peut y avoir, 0, 1 ou plusieurs package.
    // il faut prendr ele bon.
    const result: Partial<defs.ScenarioExt> = {};
    for (const key of Object.keys(arrayOfResult)) {
        const name = key as defs.ScenarioShortNames;

        // Si y en a pas on continue
        if (!arrayOfResult[name] || arrayOfResult[name].length === 0) continue;
        // si y en a qu'un on le prend
        if (arrayOfResult[name].length === 1) {
            result[name] = { inclus: arrayOfResult[name][0].inclus, prixHt: arrayOfResult[name][0].prixHt };
            if (scenario[name]) entries.push(themesTools.getPackageWithQty(arrayOfResult[name][0].package));
            continue;
        }
        // console.log('selection multiple pour ' + name);
        // si il y en a plusieurs, on cherche les selectionnés (package.applicable)
        // si il y en a un de selected, on le prend.
        const selectedCount = arrayOfResult[name].reduce((count, item) => {
            return item.package.applicable ? count + 1 : count;
        }, 0);

        let selectedX: X | undefined = undefined;
        // Si il n'y en a qu'un de selectionné (ce qui devrait toujours être le cas)
        if (selectedCount === 1) {
            selectedX = arrayOfResult[name].find((x) => x.package.applicable === true);
        } else {
            // console.log('selection count !== 1 pour ' + name);
        }

        // Dans tous les autres cas, on est dans la merde, on prend le premier !
        if (!selectedX) selectedX = arrayOfResult[name][0];

        result[name] = { inclus: selectedX.inclus, prixHt: selectedX.prixHt };
        if (scenario[name]) entries.push(themesTools.getPackageWithQty(selectedX.package));
    }

    // prettyLog('result', result);
    // prettyLog('entries', entries);
    return [result, entries];
};
//TUScenarios();
