import React, { useEffect, useState } from 'react';
import { dpeLineToAudit, DpeLogEntry } from '../../services/dpe/dpe';
import { DpeEtiquette, DpeLine } from '../../services/dpe/types';
import * as storageService from '../../services/localStorageService';
import DpeSkeletonItem from './DpeSkeletonItem';
import DpeEtiquetteComponent from './DpeEtiquette';

// Icons
import SchemaDPE from '../../assets/images/diagnostic_dpe.jpeg';
import { ReactComponent as IconLoader } from '../../assets/icons/loader-primary.svg';
import { ReactComponent as IconCheckList } from '../../assets/icons/icon-check-list.svg';

// Styles
import './DpeLoader.scss';

interface DpeLoaderProps {
    dpe: DpeLine;
    close: (value: React.SetStateAction<boolean>) => void;
}

const EtiquetteContent: React.FC<{ etiquette: string; etiquetteGES: string; close: (value: React.SetStateAction<boolean>) => void }> = ({
    etiquette,
    etiquetteGES,
    close,
}) => {
    return (
        <>
            <div className="etiquette-content">
                <h2>Votre classement DPE</h2>
                <DpeEtiquetteComponent etiquetteDPE={etiquette as DpeEtiquette} etiquetteGES={etiquetteGES as DpeEtiquette} />
                <p className="etiquette-content-item-description">
                    Classement de votre habitation selon votre dernier DPE. Si des travaux ont été réalisés depuis, ce DPE devra être mis à jour.
                </p>
            </div>

            <div className="schema-dpe">
                <h3 className="schema-dpe-title">Légende des valeurs possibles</h3>
                <img src={SchemaDPE} alt="DPE" className="img-fluid" />
            </div>

            <div className="btn-grp mt-auto justify-content-center">
                <button type="button" className="btn btn-continue" onClick={() => close(true)}>
                    Poursuivre mon audit
                </button>
            </div>
        </>
    );
};

const DpeLoader: React.FC<DpeLoaderProps> = (props: DpeLoaderProps) => {
    const [loadedItems, setLoadedItems] = useState<number>(0);
    const [pendingLogs, setPendingLogs] = useState<DpeLogEntry[]>([]);
    const [etiquette, setEtiquette] = useState<string>('');
    const [etiquetteGES, setEtiquetteGES] = useState<string>('');
    const [showEtiquetteDPE, setShowEtiquetteDPE] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allItemsLoaded, setAllItemsLoaded] = useState(false);

    useEffect(() => {
        dpeLineToAudit(props.dpe).then((result) => {
            const filteredLogs = result.logs
                .filter((log) => !log.title?.includes("Identification de l'étiquette DPE") && !log.title?.includes("Identification de l'étiquette GES"))
                .slice(0, 9);
            setPendingLogs(filteredLogs);
            setEtiquette(result.DpeAudit.step1.etiquetteDPE?.value || '');
            setEtiquetteGES(result.DpeAudit.step1.etiquetteGES?.value || '');
        });
    }, [props.dpe]);

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadedItems((prev) => {
                if (prev >= pendingLogs.length) {
                    clearInterval(timer);
                    setAllItemsLoaded(true);
                    return prev;
                }
                return prev + 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [pendingLogs.length]);

    useEffect(() => {
        if (allItemsLoaded && pendingLogs.length > 0) {
            setIsLoading(true);
            const timer = setTimeout(() => {
                setShowEtiquetteDPE(true);
                setIsLoading(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [allItemsLoaded, pendingLogs.length]);

    useEffect(() => {
        dpeLineToAudit(props.dpe).then((result) => {
            let audit = storageService.getAudit();
            audit.step1.etiquetteDPE = result.DpeAudit.step1.etiquetteDPE;
            audit.step1.etiquetteGES = result.DpeAudit.step1.etiquetteGES;
            audit.step2 = result.DpeAudit.step2;
            audit.step3 = result.DpeAudit.step3;
            audit.step4 = result.DpeAudit.step4;
            audit.step6 = result.DpeAudit.step6;
            storageService.setAudit(audit);
            setEtiquette(props.dpe['Etiquette_DPE'] || '');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (showEtiquetteDPE) {
        return (
            <div className="dpe-loader h-100">
                <EtiquetteContent etiquette={etiquette} etiquetteGES={etiquetteGES} close={props.close} />
            </div>
        );
    }

    return (
        <div className="dpe-loader h-100">
            <h2 className="dpe-loader-title">
                Récupération des données liées
                <br />à votre habitation
            </h2>
            {pendingLogs.map((log, index) => {
                if (index < loadedItems) {
                    return (
                        <div key={index} className="dpe-item loaded">
                            <div className="dpe-item-icon">
                                <IconCheckList width={24} height={24} fill="#fd5c21" />
                            </div>
                            <div className="dpe-item-content">
                                <div className="dpe-item-header">
                                    <h3 className="dpe-item-title">{log.title}</h3>
                                </div>
                                <div className="dpe-item-result">{log.message}</div>
                            </div>
                        </div>
                    );
                }

                return <DpeSkeletonItem key={index} />;
            })}

            {isLoading && (
                <div className="dpe-loader-loading text-center mt-4">
                    <IconLoader width={40} height={40} />
                </div>
            )}
        </div>
    );
};

export default DpeLoader;
