import React from 'react';
import { DpeEtiquette } from '../../services/dpe/types';
import { DpeUnit, DpeEtiquetteSeuilDPE, DpeEtiquetteSeuilGES, getEtiquetteGlobale } from '../../services/dpe/dpeTools';

interface DpeEtiquetteProps {
    etiquetteDPE: DpeEtiquette | undefined;
    etiquetteGES: DpeEtiquette | undefined;
}

const DpeEtiquetteComponent: React.FC<DpeEtiquetteProps> = ({ etiquetteDPE, etiquetteGES }) => {
    const etiquetteGlobale = getEtiquetteGlobale(etiquetteDPE, etiquetteGES);

    return (
        <div className="dpe-etiquette">
            {etiquetteGlobale && (
                <div className="dpe-etiquette-content">
                    <div className="dpe-etiquette-content-item">
                        <h4 className="dpe-etiquette-content-item-title">Consommation énergétique</h4>
                        <div className="etiquette-seuil">
                            {etiquetteDPE && DpeEtiquetteSeuilDPE[etiquetteDPE].min ? (
                                <>
                                    <span>{DpeEtiquetteSeuilDPE[etiquetteDPE].min}</span>
                                    {DpeUnit['DPE']}
                                </>
                            ) : null}
                        </div>
                        <div className={`etiquette etiquette-dpe etiquette-${etiquetteDPE}`}>{etiquetteDPE}</div>
                    </div>
                    <div className="dpe-etiquette-content-item">
                        <h4 className="dpe-etiquette-content-item-title">Emissions de gaz à effet de serre</h4>
                        <div className="etiquette-seuil">
                            {etiquetteGES && DpeEtiquetteSeuilGES[etiquetteGES].min ? (
                                <>
                                    <span>{DpeEtiquetteSeuilGES[etiquetteGES].min}</span>
                                    {DpeUnit['GES']}
                                </>
                            ) : null}
                        </div>
                        <div className={`etiquette etiquette-ges etiquette-${etiquetteGES}`}>{etiquetteGES}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DpeEtiquetteComponent;
