import { RecoilState, atom } from 'recoil';
import * as themesService from '../../../services/calculs/theme';
import * as ticketService from '../../../services/calculs/ticket';
import { localStorageEffect } from '../effects/localStorageEffect';
import { RenovationDAmpleurParams, RenovationDAmpleurTarget } from '../../calculs/aides/aideRenovationDAmpleurDefs';

/** l'atome qui contient les  themes (des groupes logiques de packages) */
export const themesStateAtom = atom<Array<themesService.Theme>>({
    key: 'themes',
    default: themesService.EMPTY_THEMES,
    effects_UNSTABLE: [localStorageEffect('bdc_themes')],
});
/** l'atome qui contient les themes (des groupes logiques de packages) */
export const currentThemeIndexAtom: RecoilState<number> = atom({ key: 'currentThemeIndex', default: 0 });

/** l'atome du ticket, le du WS et modifié localement */
export const ticketStateAtom: RecoilState<ticketService.TicketData> = atom({ key: 'Ticket', default: ticketService.EMPTY_TICKET });

/** l'atome du bouton valider du ticket,*/
export const ticketValidateDisableStateAtom: RecoilState<boolean> = atom<boolean>({ key: 'ticketValidateDisable', default: false });

/** l'atome des aides locales (l'aide eco publicitaire et tout), qu'on ajoute aussi dans le ticket. */
export const aidesLocalesListStateAtom: RecoilState<ticketService.AidesLocalesList> = atom<ticketService.AidesLocalesList>({
    key: 'AidesLocales',
    default: undefined,
});

/** l'atome de l'aide eco publicitaire, qu'on ajoute aussi dans le ticket. */
export const aideRenovationDAmpleurParamsStateAtom: RecoilState<RenovationDAmpleurParams> = atom<RenovationDAmpleurParams>({
    key: 'elligibleRenoGlobale',
    default: { elligible: false, marShouldContactCustomer: false },
});
export const aideRenovationDAmpleurTargetStateAtom: RecoilState<RenovationDAmpleurTarget> = atom<RenovationDAmpleurTarget>({
    key: 'renoGlobaleParams',
    default: { niveau: 'gain2classes', sortiePassoire: true },
});

/** l'atome du loading du ticket lorsqu'on fait un call api */
export const ticketLoaderAtom: RecoilState<boolean> = atom<boolean>({ key: 'ticketLoader', default: true });
