import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';
import CheckboxService from '../../components/checkbox/checkboxService';
import {
    ventilationInstallationDate,
    heaterOutdoorProbe,
    heaterProgrammer,
    heaterAlternateSystem,
    heaterHotWaterIndependent,
    photovoltaique,
    heaterHotWaterThermodynamic,
    heaterHotSolarWaterThermodynamic,
    heaterHotWaterIncludedAccumulation,
    heaterHotWaterIncludedInstant,
    heaterHealWholeHouse,
    multipleHydronicZoneHeatingSystem,
} from './services/handleCheckbox';

import { ReactComponent as IconLightBulb } from '../../assets/icons/icon-light-bulb.svg';
import { ReactComponent as IconLightPlug } from '../../assets/icons/icon-plug.svg';
import { ReactComponent as IconFan } from '../../assets/icons/icon-fan.svg';
import { ReactComponent as IconBoiler } from '../../assets/icons/icon-boiler.svg';
import { ReactComponent as IconPhotovoltaique } from '../../assets/icons/icon-photovoltaique.svg';
import { ReactComponent as IconEnergy } from '../../assets/icons/icon-energy-contract.svg';
import { ReactComponent as IconThermometer } from '../../assets/icons/icon-thermometer.svg';

import * as Yup from 'yup';

import { domTomCheck } from '../../services/tools/domTomServices';

import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';
import ReactTooltip from 'react-tooltip';
import Select, { components, OptionProps } from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
    allDepts,
    condensingHeaterList,
    energyWaterList,
    heaterFeatureList,
    heaterGeneratorEmplacementList,
    heaterHotWaterElectricalEmplacementList,
    heaterHotWaterElectricalOrientationList,
    heaterHotWaterIndependentBathHeaterEmplacementList,
    heaterSubscriptionElectricityList,
    heaterSubscriptionGasList,
    heaterTypeList,
    otherProvider,
    photovoltaiqueConsumptionList,
    photovoltaiquePowerList,
    ventilationTypeList,
} from './EnergySystemInformationsEnums';
import { ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE } from '../../routing/paths';
import { useStepNavigation } from '../../routing/StepNavigationContext';
import { renderDefaultValueSelect } from '../../services/tools/selectValue';

const EnergySystemInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    const descriptifPieceAChauffer = useRef(null);

    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const AlertSwal = withReactContent(Swal);

    const buildingPicturesheaterGeneratorPower = useRef(null);
    const buildingPicturesheaterFeature = useRef(null);
    const buildingPicturesenergyWater = useRef(null);

    let providersList: { value: string; label: string; departement: string[]; electricity: boolean; gas: boolean }[] = [
        /**
         * label : fournisseur
         * departement : departement
         * electrcity : true/false
         * gas: true/false
         */
        {
            value: '1',
            label: 'Alpiq',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '2',
            label: 'Alterma',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '3',
            label: 'Antargaz',
            departement: allDepts,
            electricity: true,
            gas: false,
        },
        {
            value: '5',
            label: 'Barry',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '6',
            label: 'Bulb',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '7',
            label: 'Dyneff',
            departement: allDepts,
            electricity: true,
            gas: false,
        },
        {
            value: '8',
            label: 'EDF',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '9',
            label: 'Ekwateur',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '10',
            label: 'Elecocite',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '11',
            label: 'Electricité de provence',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '12',
            label: 'Enercop',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '13',
            label: `Energie d'ici`,
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '14',
            label: 'Energie du Santerre',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '15',
            label: 'Energies Leclercs',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '16',
            label: 'Engie',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '17',
            label: 'Eni',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '18',
            label: 'Gaz de Bordeaux',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '19',
            label: 'Gedia',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '20',
            label: 'GEG',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '21',
            label: 'Greenyellow',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '22',
            label: 'Iberdrola',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '23',
            label: 'Ilek',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '24',
            label: 'JPME',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '25',
            label: 'Lucia',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '26',
            label: 'Mega energie',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '27',
            label: 'Mint energie',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '28',
            label: 'OHM energie',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '29',
            label: 'OVO energy',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '30',
            label: 'Planete oui',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '31',
            label: 'Plum energie',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '32',
            label: 'Proxcelia',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '33',
            label: 'Selia',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '34',
            label: 'Sowee',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '35',
            label: 'Synelva SAS',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '36',
            label: 'Total direct energie',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '37',
            label: 'Urban solar energy',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '38',
            label: 'Vatenfall',
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '39',
            label: 'Wekiwi',
            departement: allDepts,
            electricity: false,
            gas: true,
        },
        {
            value: '40',
            label: 'Sorégies',
            departement: ['86'],
            electricity: true,
            gas: true,
        },
        // {
        //   value: '41',
        //   label: 'GEG',
        //   departement: allDepts,
        //   electricity: false,
        //   gas: true
        // },
        {
            value: '42',
            label: 'Gaz de Bordeaux',
            departement: allDepts,
            electricity: true,
            gas: false,
        },
        {
            value: '43',
            label: 'Séolis',
            departement: ['79'],
            electricity: true,
            gas: true,
        },
        // {
        //   value: '44',
        //   label: 'Alterma',
        //   departement: allDepts,
        //   electricity: true,
        //   gas: true
        // },
        {
            value: '45',
            label: `Usine d'Electricité de Metz (UEM)`,
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '46',
            label: `Gedia`,
            departement: allDepts,
            electricity: true,
            gas: true,
        },
        {
            value: '47',
            label: `SICAE`,
            departement: ['78', '28', '60', '02', '91', '80', '95', '70', '10'],
            electricity: true,
            gas: true,
        },
        {
            value: '48',
            label: `Société d'Electrification Rurale du Carmausin`,
            departement: ['81'],
            electricity: true,
            gas: true,
        },
        {
            value: '49',
            label: `Energie Développement Services du Briançonnais`,
            departement: ['05'],
            electricity: false,
            gas: true,
        },
        {
            value: '50',
            label: `Régie du Syndicat Electrique Intercommunal du Pays Chartrain`,
            departement: ['28'],
            electricity: true,
            gas: true,
        },
        {
            value: '51',
            label: `Energies et services de Seyssel`,
            departement: ['74'],
            electricity: false,
            gas: true,
        },
        {
            value: '52',
            label: `Energie Service de Belledonne`,
            departement: ['38'],
            electricity: false,
            gas: true,
        },
        {
            value: '53',
            label: `Sorea`,
            departement: ['29'],
            electricity: false,
            gas: true,
        },
        {
            value: '54',
            label: `Régie de Niederbronn Reichshoffen`,
            departement: ['67'],
            electricity: false,
            gas: true,
        },
        {
            value: '55',
            label: `Régie d'électricité d'Elbeuf`,
            departement: ['76'],
            electricity: false,
            gas: true,
        },
        {
            value: '56',
            label: `Régie d'électricité de Thônes`,
            departement: ['74'],
            electricity: false,
            gas: true,
        },
        {
            value: '57',
            label: `Régie d'électricité de Loos`,
            departement: ['59'],
            electricity: false,
            gas: true,
        },
        {
            value: '58',
            label: `Energies et services Hagondange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '59',
            label: `Energies Services Creutzwald`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '60',
            label: `Energies et services Rombas`,
            departement: ['57'],
            electricity: true,
            gas: true,
        },
        {
            value: '61',
            label: `Energies et services Bitche`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '62',
            label: `Energies et services Clouange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '63',
            label: `Régie communale de Montdidier`,
            departement: ['80'],
            electricity: false,
            gas: true,
        },
        {
            value: '64',
            label: `Régie électrique de Sallanches`,
            departement: ['74'],
            electricity: false,
            gas: true,
        },
        {
            value: '65',
            label: `Régie électrique de Tignes`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '66',
            label: `RME de la Bresse`,
            departement: ['88'],
            electricity: false,
            gas: true,
        },
        {
            value: '67',
            label: `Régie électrique de La Cabanasse`,
            departement: ['66'],
            electricity: false,
            gas: true,
        },
        {
            value: '68',
            label: `Régie Municipale de Bonnevillee`,
            departement: ['74'],
            electricity: true,
            gas: true,
        },
        {
            value: '69',
            label: `Régie d'électricité de Montataire`,
            departement: ['60'],
            electricity: false,
            gas: true,
        },
        {
            value: '70',
            label: `Régie Amnéville`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '71',
            label: `Ene'o - Energies & Services Occitans`,
            departement: ['81'],
            electricity: true,
            gas: true,
        },
        {
            value: '72',
            label: `Régie d'électricité de Marange-Silvange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '73',
            label: `Régie communale d'eau et d'électricité de Mitry-Mory`,
            departement: ['77'],
            electricity: false,
            gas: true,
        },
        {
            value: '74',
            label: `Régie communale d'électricité de Gattières`,
            departement: ['06'],
            electricity: false,
            gas: true,
        },
        {
            value: '75',
            label: `Régie d'électricité d'Uckange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '76',
            label: `Coopérative d'électricité de St Martin de Londres`,
            departement: ['34'],
            electricity: false,
            gas: true,
        },
        {
            value: '77',
            label: `SICAP Pithiviers`,
            departement: ['45'],
            electricity: false,
            gas: true,
        },
        {
            value: '78',
            label: `Gazelec de Péronne`,
            departement: ['80'],
            electricity: true,
            gas: true,
        },
        {
            value: '79',
            label: `Régie municipale d'électricité de Bazas`,
            departement: ['33'],
            electricity: true,
            gas: true,
        },
        {
            value: '80',
            label: `Régie municipale électrique de Beauvois-en-Cambrésis`,
            departement: ['05'],
            electricity: false,
            gas: true,
        },
        {
            value: '81',
            label: `Régie électrique d'Avrieux`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '82',
            label: `Régie électrique communale d'Aussois`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '83',
            label: `Régie électrique de Bessans`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '84',
            label: `Régie électrique de Bonneval-sur-Arc`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '85',
            label: `Régie d'électricité de Bozel`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '86',
            label: `Régie municipale de Cazouls-lès-Béziers`,
            departement: ['34'],
            electricity: false,
            gas: true,
        },
        {
            value: '87',
            label: `Vialis, fournisseur d'énergie à Colmar`,
            departement: ['68'],
            electricity: true,
            gas: true,
        },
        {
            value: '87',
            label: `Gascogne Energies Services`,
            departement: ['40'],
            electricity: true,
            gas: true,
        },
        {
            value: '88',
            label: `EBM Saint-Louis`,
            departement: ['68'],
            electricity: true,
            gas: true,
        },
        {
            value: '89',
            label: `Usines municipales d'Erstein`,
            departement: ['67'],
            electricity: false,
            gas: true,
        },
        {
            value: '90',
            label: `Régie électrique municipale de Fontaine-au-Pire`,
            departement: ['59'],
            electricity: false,
            gas: true,
        },
        {
            value: '91',
            label: `Régie municipale d'électricité de Gandrange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '92',
            label: `Gignac Energie`,
            departement: ['34'],
            electricity: false,
            gas: true,
        },
        {
            value: '93',
            label: `Régie municipale d'électricité d'Hombourg Haut`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '94',
            label: `Hunelec`,
            departement: ['68'],
            electricity: false,
            gas: true,
        },
        {
            value: '95',
            label: `Régie mucipale d'électricité de La Chambre`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '96',
            label: `Régie Municipale d'Electricité de Petit-Cœur`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '97',
            label: `Syndicat Intercommunal d'Electricité de Labergement-Sainte-Marie`,
            departement: ['25'],
            electricity: false,
            gas: true,
        },
        {
            value: '98',
            label: `Energies et Services Lavaur`,
            departement: ['81'],
            electricity: true,
            gas: true,
        },
        {
            value: '99',
            label: `Régie Electrique de Montvalezan La Rosière`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '100',
            label: `Régie électrique de Fontaine-le-Puits`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '101',
            label: `UEM Neuf-Brisach`,
            departement: ['68'],
            electricity: false,
            gas: true,
        },
        {
            value: '102',
            label: `Régie communale d'électricité de Montois-la-Montagne`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '103',
            label: `Régie d'électricité d'Orelle`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '104',
            label: `Energies & Services de Pierrevillers`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '105',
            label: `Régie municipale d'électricité de Pontamafrey-Montpascal`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '106',
            label: `Régie électrique municipale de Prats-de-Mollo La Preste`,
            departement: ['66'],
            electricity: false,
            gas: true,
        },
        {
            value: '107',
            label: `Régie municipale d'énergie électrique de Quillan`,
            departement: ['11'],
            electricity: false,
            gas: true,
        },
        {
            value: '108',
            label: `Régie d'électricité de Redange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '109',
            label: `Energis Saint-Avold`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '110',
            label: `Régie municipale d'électricité de Saint-Avre`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '111',
            label: `Régie électrique de Sainte-Foy Tarentaise`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '112',
            label: `Régie d'électricité de Sainte Marie aux Chênes`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '113',
            label: `Régie électrique de Sainte-Marie-de-Cuines`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '114',
            label: `Régie municipale d'électricité de Saint-Martin-sur-la-Chambre`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '115',
            label: `Régie municipale d'électricité de Tours-en-Savoie`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '116',
            label: `Régie d'électricité de Saint-Michel-de-Maurienne`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '117',
            label: `Régie municipale d'électricité de Saint-Privat-la-Montagne`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '118',
            label: `Régie électrique de Salins-les-Bains`,
            departement: ['39'],
            electricity: false,
            gas: true,
        },
        {
            value: '119',
            label: `Energies & Services Sarre-Union`,
            departement: ['67'],
            electricity: false,
            gas: true,
        },
        {
            value: '120',
            label: `Régie communale d'électricité de Saulnes`,
            departement: ['54'],
            electricity: false,
            gas: true,
        },
        {
            value: '121',
            label: `Energies & Services Schoeneck`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '122',
            label: `Régie municipale d'électricité de Talange`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '123',
            label: `Régie électrique de Valmeinier`,
            departement: ['73'],
            electricity: true,
            gas: true,
        },
        {
            value: '124',
            label: `Régie électrique de Villarlurin`,
            departement: ['73'],
            electricity: true,
            gas: true,
        },
        {
            value: '125',
            label: `Régie municipale d'électricité de Villarodin-Bourget`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '126',
            label: `Régie électrique de Villaroger`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '127',
            label: `Centrale Vonderscheer de Villé`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '128',
            label: `Coopérative d'électricité de Villiers-sur-Marne`,
            departement: ['94'],
            electricity: false,
            gas: true,
        },
        {
            value: '129',
            label: `Energies & Services Lannemezan`,
            departement: ['65'],
            electricity: true,
            gas: true,
        },
        {
            value: '130',
            label: `Régie municipale multiservices de la Réole`,
            departement: ['33'],
            electricity: true,
            gas: true,
        },
        {
            value: '131',
            label: `Régie municipale d'électricité de Vinay`,
            departement: ['38'],
            electricity: false,
            gas: true,
        },
        {
            value: '132',
            label: `Régie municipale électrique de Saint-Léonard de Noblat`,
            departement: ['87'],
            electricity: false,
            gas: true,
        },
        {
            value: '133',
            label: `Régie électrique des Houches`,
            departement: ['74'],
            electricity: false,
            gas: true,
        },
        {
            value: '134',
            label: `Régie électrique de Gervans`,
            departement: ['26'],
            electricity: false,
            gas: true,
        },
        {
            value: '135',
            label: `Régie municipale d'électricité de Montesquieu-Volvestre`,
            departement: ['33'],
            electricity: false,
            gas: true,
        },
        {
            value: '136',
            label: `Régies municipales de Capvern`,
            departement: ['65'],
            electricity: false,
            gas: true,
        },
        {
            value: '137',
            label: `SIVU de Luz-St-Sauveur, Esquièze-Sère et Esterre`,
            departement: ['65'],
            electricity: false,
            gas: true,
        },
        {
            value: '138',
            label: `Régie municipale d'électricité de Laruns`,
            departement: ['64'],
            electricity: false,
            gas: true,
        },
        {
            value: '139',
            label: `SDED d'Erôme`,
            departement: ['26'],
            electricity: false,
            gas: true,
        },
        {
            value: '140',
            label: `Régie d'électricité de Cazères`,
            departement: ['31'],
            electricity: false,
            gas: true,
        },
        {
            value: '141',
            label: `Régie municipale d'électricité de Martres-Tolosane`,
            departement: ['31'],
            electricity: false,
            gas: true,
        },
        {
            value: '142',
            label: `Régie électrique municipale d'Ilhet`,
            departement: ['65'],
            electricity: false,
            gas: true,
        },
        {
            value: '143',
            label: `Régie d'électricité du syndicat du sud de la Réole`,
            departement: ['33'],
            electricity: false,
            gas: true,
        },
        {
            value: '144',
            label: `Régie d'électricité de Roquebillière`,
            departement: ['06'],
            electricity: false,
            gas: true,
        },
        {
            value: '145',
            label: `Régie d'électricité de Saint-Quirc`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '146',
            label: `Régie municipale d'électricité de Saverdun`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '147',
            label: `Régie électrique de Villeneuve-au-Chêne`,
            departement: ['10'],
            electricity: false,
            gas: true,
        },
        {
            value: '148',
            label: `Régie municipale d'électricité de Miramont-les-Comminges`,
            departement: ['31'],
            electricity: false,
            gas: true,
        },
        {
            value: '149',
            label: `Régie électrique municipale de St Laurent de Cerdans`,
            departement: ['66'],
            electricity: false,
            gas: true,
        },
        {
            value: '150',
            label: `Régie municipale d'électricité de Mazères`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '151',
            label: `Régie municipale de Tarascon-sur-Ariège`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '152',
            label: `Régie municipale d'électricité de Varilhes`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '153',
            label: `Régie municipale d'Arignac`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '154',
            label: `Régie municipale de l'Hospitalet-près-l'Andorre`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '155',
            label: `Régie municipale de Quié`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '156',
            label: `Régie municipale de Mérens-les-Vals`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '157',
            label: `Régie municipale de Vicdessos`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '158',
            label: `Régie municipale de Mercus-et-Garrabet`,
            departement: ['09'],
            electricity: false,
            gas: true,
        },
        {
            value: '159',
            label: `Régie d'électricité du Morel`,
            departement: ['72'],
            electricity: false,
            gas: true,
        },
        {
            value: '160',
            label: `SER des cantons de Lassigny et limitrophes`,
            departement: ['60'],
            electricity: false,
            gas: true,
        },
        {
            value: '161',
            label: `Energies & Services de Moyeuvre-Petite`,
            departement: ['57'],
            electricity: false,
            gas: true,
        },
        {
            value: '162',
            label: `Régie électrique de la Chapelle`,
            departement: ['73'],
            electricity: false,
            gas: true,
        },
        {
            value: '163',
            label: `Régie municipale d'électricité de Cambounet-sur-le-Sor`,
            departement: ['81'],
            electricity: false,
            gas: true,
        },
        {
            value: '164',
            label: `EEWF - Eau et électricité de Wallis et Futuna`,
            departement: ['98'],
            electricity: false,
            gas: true,
        },
        {
            value: '165',
            label: `ES Strasbourg`,
            departement: ['67'],
            electricity: true,
            gas: true,
        },
    ];

    providersList = Object.assign(
        providersList.sort((a, b) => a.label.toString().localeCompare(b.label)),
        otherProvider
    );

    const currentZipCode: string = storageService.getAudit().step1.userZipCode.value;
    const slicedZipCode: string = currentZipCode.slice(0, 2);
    const shonMaxSize: number = storageService.getAudit().step2.SHON.value;

    // Replace HeaterTypeList
    const domTomHeaterTypeList = () => {
        let tmp = [...heaterTypeList];

        if (domTomCheck(currentZipCode) !== undefined) {
            tmp.unshift({ label: 'Pas de chauffage', value: '999', included: ['0', '1', '2', '3', '4', '5'] });
        }

        return tmp;
    };

    // Vérifier le type de VMC en fonction du code postal
    const domTomVmc = () => {
        let tmp = [...ventilationTypeList];

        if (domTomCheck(currentZipCode) !== undefined) {
            // Vider ventilationTypeList
            tmp = [];

            // Injecter de nouvelles données dedans
            tmp.push(
                {
                    label: "Brasseur d'air",
                    value: '8',
                },
                {
                    label: 'Pas de ventilation mécanique',
                    value: '7',
                }
            );
        }

        return tmp;
    };

    /**
     * Pour le changement de couleurs du react-select
     */
    const colourStyles = {
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#76ca4f' : null,
                color: isFocused ? '#ffffff' : '#1F2F3D',
            };
        },
    };

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;

        // Mise à jour du formulaire
        if (event.hasOwnProperty('target')) {
            handleChange(event);
        }

        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                if (event.hasOwnProperty('target')) {
                    return property.value === event.target.value;
                }
                return property.value === event.value;
            });
            return storageService.setAuditValue(step, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }

        // Cas standard
        return storageService.setAuditValue(step, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    const currentYearMinus = (year: number) => {
        return new Date().getFullYear() - year;
    };

    /**
     * 1. Maison Individuelle
     * 2. Appartemment
     * 3. Immeuble Collectif
     * 4. Bâtiment
     */
    const buildingType = storageService.getAudit().step2.projectType.value;

    /**
     * Type de compteur électrique
     * 1 - Monophasé
     * 2 - Triphasé
     * 3 - Non renseigné
     */
    const electricMeterList: { value: string; label: string }[] = [
        {
            value: '1',
            label: 'Monophasé',
        },
        {
            value: '2',
            label: 'Triphasé',
        },
        {
            value: '3',
            label: 'Non renseigné',
        },
    ];

    /**
     * Valeur lors de l'initialisation de la page
     */
    const initialValues = {
        heaterType: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterType', `Type d’énergie`, PropertyType['withLabel']),
        heaterSubscription: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterSubscription',
            `Abonnements et consommations`,
            PropertyType['withLabel']
        ),
        heaterSubscriptionElectricity: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterSubscriptionElectricity',
            `Abonnements électrique`,
            PropertyType['withLabel']
        ),
        heaterFeature: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterFeature', `Emetteur`, PropertyType['withLabel']),
        heaterHealWholeHouse: storageService.getAudit().step3.heaterHealWholeHouse.value
            ? storageService.getAudit().step3.heaterHealWholeHouse.value === '1'
            : '',
        multipleHydronicZoneHeatingSystem: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'multipleHydronicZoneHeatingSystem',
            'Le générateur alimente t’il des radiateurs aux étages et ou sous-sol ?',
            PropertyType['boolean']
        ),
        heaterSurface: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterSurface', `Surface chauffée par ce système`, PropertyType['number']),
        heaterType2: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterType2', `Type d’énergie secondaire`, PropertyType['withLabel']),
        heaterGeneratorPower: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterGeneratorPower', `Puissance`, PropertyType['number']),
        heaterGeneratorIdenticalNumber: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterGeneratorIdenticalNumber',
            `Nombre identique`,
            PropertyType['number']
        ),
        heaterGeneratorEmplacement: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterGeneratorEmplacement',
            `Emplacement`,
            PropertyType['withLabel']
        ),
        heaterOutdoorProbe: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterOutdoorProbe',
            `Présence d’une sonde exterieure`,
            PropertyType['boolean']
        ),
        heaterProgrammer: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterProgrammer', `Présence d’un programmateur`, PropertyType['boolean']),
        heaterAlternateSystem: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterAlternateSystem',
            `Existence d’un système d’appoint ?`,
            PropertyType['boolean']
        ),
        heaterAlternateSystemDescription: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterAlternateSystemDescription',
            `Description du système d'apoint`,
            PropertyType['basic']
        ),
        energyWaterType:
            storageService.getAudit().step3.heaterHotWaterElectrical.value === false && storageService.getAudit().step3.heaterHotWaterIncluded.value === false
                ? ''
                : storageService.getAudit().step3.heaterHotWaterElectrical.value === true
                ? '1'
                : '2',
        heaterHotWaterElectricalOrientation: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterElectricalOrientation',
            `Orientation du chauffe-eau`,
            PropertyType['withLabel']
        ),
        heaterHotWaterStorageCapacity: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterStorageCapacity',
            `Capacité de stockage`,
            PropertyType['number']
        ),
        heaterHotWaterElectricalEmplacement: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterElectricalEmplacement',
            `Emplacement`,
            PropertyType['withLabel']
        ),
        heaterHotWaterThermodynamic: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterThermodynamic',
            `Chauffe-eau thermodynamique`,
            PropertyType['boolean']
        ),
        heaterHotSolarWaterThermodynamic: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotSolarWaterThermodynamic',
            `Chauffe-eau solaire thermodynamique`,
            PropertyType['boolean']
        ),
        heaterHotWaterIndependent: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterHotWaterIndependent', `Indépendant`, PropertyType['boolean']),
        heaterHotWaterIndependentBathHeaterEmplacement: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterIndependentBathHeaterEmplacement',
            `Emplacement`,
            PropertyType['withLabel']
        ),
        heaterHotWaterIncludedStorageCapacity: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterIncludedStorageCapacity',
            `Capacité de stockage`,
            PropertyType['number']
        ),
        heaterHotWaterIncludedAccumulation: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterIncludedAccumulation',
            `Accumulation`,
            PropertyType['boolean']
        ),
        heaterHotWaterIncludedInstant: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'heaterHotWaterIncludedInstant',
            `Instantané`,
            PropertyType['boolean']
        ),
        ventilationType: storageService.checkPropertyExistThenCreateOrRender(3, 'ventilationType', `Type de ventilation`, PropertyType['withLabel']),
        ventilationInstallationDate: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'ventilationInstallationDate',
            `L'installation a été effectuée avant ${currentYearMinus(3)}`,
            PropertyType['boolean']
        ),
        photovoltaique: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'photovoltaique',
            `Présence d’une installation photovoltaique`,
            PropertyType['boolean']
        ),
        photovoltaiquePower: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'photovoltaiquePower',
            `Puissance de l’installation photovoltaique`,
            PropertyType['withLabel']
        ),
        photovoltaiqueConsumption: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'photovoltaiqueConsumption',
            `Mode de consommation photovoltaique`,
            PropertyType['withLabel']
        ),
        electricityProvider: storageService.checkPropertyExistThenCreateOrRender(
            3,
            'electricityProvider',
            `Fournisseur d'électricité`,
            PropertyType['withLabel']
        ),
        electricMeter: storageService.checkPropertyExistThenCreateOrRender(3, 'electricMeter', `Type de compteur électrique`, PropertyType['withLabel']),
        gasProvider: storageService.checkPropertyExistThenCreateOrRender(3, 'gasProvider', `Fournisseur de gaz`, PropertyType['withLabel']),
        heaterSubscriptionGas: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterSubscriptionGas', `Fournisseur de Gaz`, PropertyType['withLabel']),
        heaterBrand: storageService.checkPropertyExistThenCreateOrRender(3, 'heaterBrand', `Marque de la chaudière`, PropertyType['basic']),
        condensingHeater: storageService.checkPropertyExistThenCreateOrRender(3, 'condensingHeater', `Chaudière à condensation`, PropertyType['withLabel']),
        roomTypeDetails: storageService.checkPropertyExistThenCreateOrRender(3, 'roomTypeDetails', `Détail des bâtiments`, PropertyType['buildingDetail']),
        roomType: storageService.checkPropertyExistThenCreateOrRender(3, 'roomType', `Nom/Type de pièce`, PropertyType['basic']),
        roomLength: storageService.checkPropertyExistThenCreateOrRender(3, 'roomLength', 'Longueur (m)', PropertyType['number']),
        roomWidth: storageService.checkPropertyExistThenCreateOrRender(3, 'roomWidth', `Largeur (m)`, PropertyType['number']),
        roomHeight: storageService.checkPropertyExistThenCreateOrRender(3, 'roomHeight', `Hauteur (m)`, PropertyType['number']),

        buildingPicturesheaterGeneratorPower: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesheaterGeneratorPower',
            `Photo de la puissance energetique`,
            PropertyType['photo']
        ),
        buildingPicturesheaterFeature: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesheaterFeature',
            `Photo de l'émetteur`,
            PropertyType['photo']
        ),
        buildingPicturesenergyWater: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesenergyWater',
            `Photo de Eau chaude sanitaire`,
            PropertyType['photo']
        ),
    };

    /**
     * Schéma de validation
     */
    const validationSchema = Yup.object({
        heaterType: Yup.string().required(`Le choix du type d'énergie utilisée pour le chauffage est obligatoire`),
        heaterSubscription: Yup.string().when('heaterType', {
            is: (value: string) => ['0', '4', '999'].includes(value),
            then: Yup.string().required(`Le choix de l'abonnement est obligatoire`),
        }),
        heaterSubscriptionElectricity: Yup.string(),
        heaterFeature: Yup.string()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.string().required(`Le choix de l'émetteur est obligatoire`),
            }),
        heaterHealWholeHouse: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte un système secondaire de chauffage`)
            .test('heaterHealWholeHouse', `Vous n'avez pas précisé si l'habitation comporte un système secondaire de chauffage`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        heaterSurface: Yup.number()
            .nullable()
            .notRequired()
            .when('heaterHealWholeHouse', {
                is: (value: string) => ['1', true, 'true'].includes(value),
                then: Yup.number()
                    .min(1, 'La surface minimale chauffée par le système secondaire est de 1')
                    .max(shonMaxSize, 'La surface maximale chauffée par le système secondaire ne peut pas être supérieure à la surface SHON')
                    .typeError('La surface chauffée par le système secondaire est obligatoire')
                    .required('La surface chauffée par le système secondaire est obligatoire'),
            }),
        heaterType2: Yup.string().when('heaterHealWholeHouse', {
            is: (value: string) => ['1', true, 'true'].includes(value),
            then: Yup.string().required(`Le choix du type d'énergie secondaire est obligatoire`),
        }),
        multipleHydronicZoneHeatingSystem: Yup.boolean()
            .notRequired()
            .when('heaterFeature', {
                is: (value: string) => value === '6',
                then: Yup.boolean().required('Le générateur alimente t’il des radiateurs aux étages et ou sous-sol est obligatoire'),
            }),
        heaterGeneratorPower: Yup.number()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.number()
                    .min(0.1, 'La puissance minimale est de 0.1')
                    .typeError('La puissance du générateur est obligatoire')
                    .required('La puissance du générateur est obligatoire'),
            })
            .when(['heaterFeature', 'heaterType'], {
                is: (value: string, heaterType: string) => ['0', '1', '2'].includes(value) && heaterType !== '999',
                then: Yup.number()
                    .min(0.1, 'La puissance minimale du générateur est de 0.1')
                    .max(9, 'La puissance du générateur est limité à 9')
                    .typeError('La puissance du générateur est obligatoire')
                    .required('La puissance du générateur est obligatoire'),
            })
            .when(['heaterFeature', 'heaterType'], {
                is: (value: string, heaterType: string) => ['4', '5', '6', '7', '8'].includes(value) && heaterType !== '999',
                then: Yup.number()
                    .min(0.1, 'La puissance minimale du générateur est de 0.1')
                    .max(100, 'La puissance du générateur est limité à 100')
                    .typeError('La puissance du générateur est obligatoire')
                    .required('La puissance du générateur est obligatoire'),
            }),
        heaterGeneratorIdenticalNumber: Yup.number()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.number()
                    .min(0, 'Le nombre de générateur identique minimal est de 0')
                    .max(30, 'Le nombre de générateur identique minimal est de 30')
                    .typeError('Le nombre de générateur identique minimal est obligatoire')
                    .required('Le nombre de générateur identique minimal est obligatoire'),
            })
            .when('heaterType', {
                // Cas Electrique & Réseau || Réseau de chaleur
                is: (value: string) => ['2', '5'].includes(value),
                then: Yup.number()
                    .min(1, 'Le nombre de générateurs identiques doit être de 1')
                    .max(1, 'Le nombre de générateurs identiques doit être de 1')
                    .typeError('Le nombre de générateurs identiques est obligatoire')
                    .required(`Le nombre de générateurs identiques est obligatoire`),
            })
            .when('heaterType', {
                // Cas Gaz || Bois || Fioul
                is: (value: string) => ['1', '4', '6'].includes(value),
                then: Yup.number()
                    .min(1, 'Le nombre de générateurs identiques doit être de 1')
                    .max(2, 'Le nombre de générateurs identiques doit être de 2')
                    .typeError('Le nombre de générateurs identiques est obligatoire')
                    .required(`Le nombre de générateurs identiques est obligatoire`),
            }),
        heaterGeneratorEmplacement: Yup.string()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.string().required(`Le choix de l'emplacement du générateur est obligatoire`),
            }),
        heaterOutdoorProbe: Yup.boolean()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le générateur comporte une sonde exterieure`)
                    .test('heaterOutdoorProbe', `Vous n'avez pas précisé si le générateur comporte une sonde exterieure`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        heaterProgrammer: Yup.boolean()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le générateur comporte un programmateur`)
                    .test('heaterProgrammer', `Vous n'avez pas précisé si le générateur comporte un programmateur`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        heaterAlternateSystem: Yup.boolean()
            .notRequired()
            .when('heaterType', {
                is: (value: string) => value !== '999',
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte un système d'appoint`)
                    .test('heaterAlternateSystem', `Vous n'avez pas précisé si l'habitation comporte un système d'appoint`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        heaterHotWaterIncludedAccumulation: Yup.boolean()
            .notRequired()
            .when('energyWaterType', {
                is: (value: string) => ['2'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le système d'eau chaude sanitaire dispose d'un système d'accumulation`)
                    .test(
                        'heaterHotWaterIncludedAccumulation',
                        `Vous n'avez pas précisé si le système d'eau chaude sanitaire dispose d'un système d'accumulation`,
                        (value) => {
                            return [true, false].includes(value as boolean);
                        }
                    ),
            }),
        heaterAlternateSystemDescription: Yup.string().when('heaterAlternateSystem', {
            is: (value: string) => ['1', true, 'true'].includes(value),
            then: Yup.string().required(`La description du système d'appoint est obligatoire`),
        }),
        energyWaterType: Yup.string().required(`Le choix du type d'énergie pour l'eau sanitaire est obligatoire`),
        heaterHotWaterElectricalOrientation: Yup.string().when('energyWaterType', {
            is: (value: string) => ['1'].includes(value),
            then: Yup.string().required(`Le choix d'orientation du chauffe-eau est obligatoire`),
        }),
        heaterHotWaterStorageCapacity: Yup.number()
            .nullable()
            .optional()
            .when('energyWaterType', {
                is: (value: string) => ['1'].includes(value),
                then: Yup.number()
                    .min(0, 'La capacité minimale est de 0')
                    .typeError('La capacité de stockage est obligatoire')
                    .required(`La capacité de stockage est obligatoire`),
            }),
        heaterHotWaterElectricalEmplacement: Yup.string().when(['energyWaterType', 'heaterType'], {
            is: (value: string, heaterType: string) => ['1'].includes(value) && heaterType !== '999',
            then: Yup.string().required(`Le choix de l'emplacement est obligatoire`),
        }),
        heaterHotSolarWaterThermodynamic: Yup.boolean()
            .notRequired()
            .when('energyWaterType', {
                is: (value: string) => ['1'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte un chauffe-eau solaire thermodynamique`)
                    .test(
                        'heaterHotSolarWaterThermodynamic',
                        `Vous n'avez pas précisé si l'habitation comporte un chauffe-eau solaire thermodynamique`,
                        (value) => {
                            return [true, false].includes(value as boolean);
                        }
                    ),
            }),
        heaterHotWaterThermodynamic: Yup.boolean()
            .notRequired()
            .when('energyWaterType', {
                is: (value: string) => ['1'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte un chauffe-eau thermodynamique`)
                    .test('heaterHotWaterThermodynamic', `Vous n'avez pas précisé si l'habitation comporte un chauffe-eau thermodynamique`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        heaterHotWaterIndependent: Yup.boolean()
            .required(`Vous n'avez pas préciser si l'habitation comporte un système indépendant de chauffe-eau`)
            .test('heaterHotWaterIndependent', `Vous n'avez pas préciser si l'habitation comporte un système indépendant de chauffe-eau`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        heaterHotWaterIndependentBathHeaterEmplacement: Yup.string().when('heaterHotWaterIndependent', {
            is: true,
            then: Yup.string().required(`Le choix de l'emplacement est obligatoire`),
        }),
        heaterHotWaterIncludedStorageCapacity: Yup.number()
            .nullable()
            .notRequired()
            .when('energyWaterType', {
                is: (value: string) => ['2'].includes(value),
                then: Yup.number()
                    .min(0, 'La capacité minimale est de 0')
                    .typeError('La capacité de stockage est obligatoire')
                    .required(`La capacité de stockage est obligatoire`),
            }),
        heaterHotWaterIncludedInstant: Yup.boolean()
            .notRequired()
            .when('energyWaterType', {
                is: (value: string) => ['2'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le système d'eau chaude sanitaire dispose d'un système instantané`)
                    .test(
                        'heaterHotWaterIncludedInstant',
                        `Vous n'avez pas précisé si le système d'eau chaude sanitaire dispose d'un système instantané`,
                        (value) => {
                            return [true, false].includes(value as boolean);
                        }
                    ),
            }),
        ventilationType: Yup.string().required(`Le choix du type de ventilation est obligatoire`),
        ventilationInstallationDate: Yup.boolean()
            .notRequired()
            .when('ventilationType', {
                is: (value: string) => ['5'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé la date d'installation du système de ventillation`)
                    .test('ventilationInstallationDate', `Vous n'avez pas précisé la date d'installation du système de ventillation`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        ventilationProblems: Yup.boolean(),
        photovoltaique: Yup.boolean()
            .notRequired()
            .when(storageService.getAudit().step2.projectType.value, {
                is: (value: string = storageService.getAudit().step2.projectType.value) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitaiton comporte une installation photovoltaïque`)
                    .test('photovoltaique', `Vous n'avez pas précisé si l'habitaiton comporte une installation photovoltaïque`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        photovoltaiquePower: Yup.string()
            .notRequired()
            .when('photovoltaique', {
                is: true,
                then: Yup.string().required(`La puissance de l'installation voltaique est obligatoire`),
            }),
        photovoltaiqueConsumption: Yup.string().notRequired().when('photovoltaique', {
            is: true,
            then: Yup.string(),
        }),
        electricityProvider: Yup.string().required(`Le fournisseur d'électrecité est obligatoire`),
        electricMeter: Yup.string().required(`Le type de compteur électrique est obligatoire`),
        heaterSubscriptionGas: Yup.string().notRequired(),
        gasProvider: Yup.string()
            .notRequired()
            .when('heaterSubscriptionGas', {
                is: (value: string) => (value === '0' ? '' : value),
                then: Yup.string().required(`Le fournisseur de Gaz est obligatoire`),
            }),
        heaterBrand: ['1', '2', '4'].includes(buildingType)
            ? Yup.string()
                  .notRequired()
                  .when(['heaterType', 'heaterType2', 'heaterFeature'], {
                      is: (heaterType: string, heaterType2: string, heaterFeature: string) =>
                          ['4', '6'].includes(heaterType) ||
                          ['4', '6'].includes(heaterType2) ||
                          ((['0'].includes(heaterType) || ['0'].includes(heaterType2)) && ['6'].includes(heaterFeature)) ||
                          ((['1'].includes(heaterType) || ['1'].includes(heaterType2)) && ['4', '5', '6'].includes(heaterFeature)),
                      then: Yup.string().required(`La marque de la chaudière est obligatoire`),
                  })
            : Yup.string().notRequired(),
        condensingHeater: ['1', '2', '4'].includes(buildingType)
            ? Yup.string()
                  .notRequired()
                  .when(['heaterType', 'heaterType2', 'heaterFeature'], {
                      is: (heaterType: string, heaterType2: string, heaterFeature: string) =>
                          ['4', '6'].includes(heaterType) ||
                          ['4', '6'].includes(heaterType2) ||
                          ((['0'].includes(heaterType) || ['0'].includes(heaterType2)) && ['6'].includes(heaterFeature)) ||
                          ((['1'].includes(heaterType) || ['1'].includes(heaterType2)) && ['4', '5', '6'].includes(heaterFeature)),
                      then: Yup.string().required(`La chaudière à condensation est obligatoire`),
                  })
            : Yup.string().notRequired(),
        roomTypeDetails:
            storageService.getAuditCampagne() === 'action_logement' || storageService.getAuditCampagne() === 'reste_a_charge'
                ? Yup.array()
                      .notRequired()
                      .when(['heaterType', 'heaterType2', 'heaterFeature'], {
                          is: (heaterType: string, heaterType2: string, heaterFeature: string) =>
                              (['0'].includes(heaterType) || ['0'].includes(heaterType2)) && !['6'].includes(heaterFeature),
                          then: Yup.array()
                              .notRequired()
                              .of(
                                  Yup.object().shape({
                                      roomType: Yup.string().max(30).required(`Le nom/type de pièce est obligatoire`),
                                      roomLength: Yup.number().nullable().max(50, `La longueur ne peut pas excéder 50`).required(`La longueur est obligatoire`),
                                      roomWidth: Yup.number().nullable().max(50, `La largeur ne peut pas excéder 50`).required(`La largeur est obligatoire`),
                                      roomHeight: Yup.number().nullable().max(10, `La hauteur ne peut pas excéder 10`).required(`La hauteur est obligatoire`),
                                      roomTypeLocked: Yup.boolean(),
                                  })
                              ),
                      })
                : Yup.array().notRequired(),

        buildingPicturesenergyWater: Yup.string(),
        buildingPicturesheaterGeneratorPower: Yup.string(),
        buildingPicturesheaterFeature: Yup.string(),
    });

    // Show/Hide heater brand according to heaterType/heaterType2 && heaterFeature
    const showHeaterBrand = (values: any) => {
        if (
            ['4', '6'].includes(values.heaterType) ||
            ['4', '6'].includes(values.heaterType2) ||
            ((['0'].includes(values.heaterType) || ['0'].includes(values.heaterType2)) && ['6'].includes(values.heaterFeature)) ||
            ((['1'].includes(values.heaterType) || ['1'].includes(values.heaterType2)) && ['4', '5', '6'].includes(values.heaterFeature))
        ) {
            return true;
        } else {
            return false;
        }
    };

    // Show/Hide roomTypeDetails according to heaterType/heaterType2 && heaterFeature
    const showRoomTypeDetails = (values: any) => {
        if ((['0'].includes(values.heaterType) || ['0'].includes(values.heaterType2)) && !['6'].includes(values.heaterFeature)) {
            return true;
        } else {
            return false;
        }
    };

    // Photos obligatoires, mais en fait pas tellement...
    const mandatoryPhotos: any[] = [
        {
            name: 'buildingPicturesenergyWater',
            errorMessage: "photo de l'eau chaude sanitaire",
        },
        {
            name: 'buildingPicturesheaterGeneratorPower',
            errorMessage: 'photo du générateur',
        },
        {
            name: 'buildingPicturesheaterFeature',
            errorMessage: "photo de l'émetteur",
        },
    ];

    const checkPhotos = (values: any) => {
        let photoCheck = false;

        mandatoryPhotos.forEach((photo: any) => {
            if (values[photo.name] === '') {
                photoCheck = true;
            }
        });
        return photoCheck;
    };

    const displayErrorsDialog = (errors: any) => {
        // Préparation liste d'étapes en erreur
        let errorsList: string[] = [];
        // Gestion des erreurs normales
        Object.keys(errors).forEach((field: any) => {
            // @ts-ignore
            if (typeof errors[field] === 'object') {
                // @ts-ignore
                errors[field].forEach((element: any) => {
                    for (let key in element) {
                        if (typeof element[key] === 'object') {
                            errorsList = [...errorsList, element[key].value];
                        } else {
                            errorsList = [...errorsList, element[key]];
                        }
                    }
                });
            } else {
                // @ts-ignore
                errorsList = [...errorsList, `${errors[field]}`];
            }
        });
        // Affichage message
        AlertSwal.fire({
            title: `Champs manquants`,
            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                        <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
            cancelButtonText: 'Continuer la saisie',
            showConfirmButton: false,
            showCancelButton: true,
            focusCancel: true,
            showClass: {
                popup: 'errors',
                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
            },
            customClass: {
                cancelButton: 'btn btn-primary maxi-min-width',
            },
        });
    };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            // isInitialValid={storageService.getAudit().step3.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
        >
            {({ values, errors, handleChange, isValid, setValues, setErrors, setFieldValue, touched, handleBlur }) => {
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(3, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (3 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                return (
                    <div className="container">
                        <h1 className="main-title-mini">3. Caractéristiques des systèmes énergétiques</h1>
                        <h2 className="main-subtitle">Type d'énergie, abonnement, chauffage…</h2>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconLightBulb fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Énergie de chauffage</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <label htmlFor="heaterType" className="form-label">
                                                Type d'énergie utilisée pour le chauffage<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(domTomHeaterTypeList(), values.heaterType)}
                                                options={domTomHeaterTypeList()}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="heaterType"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'heaterType', event.value, event.label);
                                                    storageService.setAuditValue(
                                                        3,
                                                        isValid,
                                                        'heaterGeneratorIdenticalNumber',
                                                        ['2', '5'].includes(event.value) ? 1 : null
                                                    );
                                                    // Mise à jour des champs associés
                                                    storageService.removeAuditValue(3, 'heaterFeature', true);
                                                    storageService.removeAuditValue(3, 'heaterType2', true);
                                                    // Purge localstorage champs étape 4
                                                    storageService.removeAuditValue(4, 'energyCostsGas');
                                                    storageService.removeAuditValue(4, 'energyCostsFuel');
                                                    storageService.removeAuditValue(4, 'energyCostsWood');
                                                    storageService.removeAuditValue(4, 'energyCostsGasFuelWood');
                                                    storageService.removeAuditValue(4, 'energyCostsElectric');
                                                    storageService.removeAuditValue(4, 'energyCostsSum');
                                                    // Déclaration de l'étape 4 invalide
                                                    storageService.setAuditStepValidity(4, false);
                                                    // Purge champs heaterSubscription + heaterFeature
                                                    setValues({
                                                        ...values,
                                                        heaterType: event.value,
                                                        heaterFeature: '',
                                                        heaterType2: '',
                                                        heaterGeneratorIdenticalNumber: ['2', '5'].includes(event.value) ? 1 : null,
                                                    });

                                                    // Vider la marque de la chaudière et Chaudière à condensation
                                                    if (!['1', '4', '6'].includes(event.value)) {
                                                        storageService.removeAuditValue(3, 'heaterBrand');
                                                        storageService.removeAuditValue(3, 'condensingHeater', true);

                                                        values.heaterBrand = '';
                                                        values.condensingHeater = '';

                                                        setValues({
                                                            ...values,
                                                            heaterType: event.value,
                                                        });
                                                    }

                                                    // Purge les factutres de Gaz
                                                    if (event.value !== '4') {
                                                        storageService.removeAuditValue(8, 'energyCostsGasInvoice1');
                                                        storageService.removeAuditValue(8, 'energyCostsGasInvoice2');
                                                        storageService.removeAuditValue(8, 'energyCostsGasInvoice3');

                                                        setValues({
                                                            ...values,
                                                            heaterType: event.value,
                                                            heaterFeature: '',
                                                            heaterType2: '',
                                                        });
                                                    }

                                                    // If zipCode is included in DOM TOM
                                                    if (event.value === '999') {
                                                        storageService.removeAuditValue(3, 'heaterFeature', true);
                                                        storageService.removeAuditValue(3, 'heaterGeneratorPower');
                                                        storageService.removeAuditValue(3, 'heaterGeneratorIdenticalNumber');
                                                        storageService.removeAuditValue(3, 'heaterGeneratorEmplacement', true);
                                                        storageService.removeAuditValue(3, 'heaterAlternateSystem');
                                                        storageService.removeAuditValue(3, 'heaterOutdoorProbe');
                                                        storageService.removeAuditValue(3, 'heaterProgrammer');

                                                        storageService.removeAuditValue(8, 'buildingPicturesheaterFeature');
                                                        storageService.removeAuditValue(8, 'buildingPicturesheaterGeneratorPower');

                                                        // Set new datas in LocalStorage
                                                        storageService.setAuditValue(
                                                            8,
                                                            isValid,
                                                            'buildingPicturesheaterGeneratorPower',
                                                            'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8'
                                                        );
                                                        storageService.setAuditValue(
                                                            8,
                                                            isValid,
                                                            'buildingPicturesheaterFeature',
                                                            'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8'
                                                        );
                                                        storageService.setAuditValue(3, isValid, 'heaterHealWholeHouse', '2', 'non');
                                                        storageService.setAuditValue(3, isValid, 'heaterHotWaterElectrical', true);

                                                        setValues({
                                                            ...values,
                                                            heaterType: event.value,
                                                            energyWaterType: '1',
                                                            heaterFeature: '',
                                                            heaterHealWholeHouse: false,
                                                            heaterGeneratorPower: '',
                                                            heaterGeneratorIdenticalNumber: '',
                                                            heaterGeneratorEmplacement: '',
                                                            heaterAlternateSystem: '',
                                                            heaterOutdoorProbe: '',
                                                            heaterProgrammer: '',

                                                            // 1x1 white png
                                                            buildingPicturesheaterGeneratorPower:
                                                                'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
                                                            buildingPicturesheaterFeature:
                                                                'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
                                                        });
                                                    }

                                                    // Event !== 999
                                                    if (event.value !== '999' && values.heaterType === '999') {
                                                        storageService.removeAuditValue(8, 'buildingPicturesheaterFeature');
                                                        storageService.removeAuditValue(8, 'buildingPicturesheaterGeneratorPower');

                                                        setValues({
                                                            ...values,
                                                            heaterType: event.value,
                                                            buildingPicturesheaterGeneratorPower: '',
                                                            buildingPicturesheaterFeature: '',
                                                        });
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.heaterType ? ' filled' : '') +
                                                    (touched.heaterType && errors.heaterType ? ' invalid' : '') +
                                                    (!values.heaterType ? ' required' : '')
                                                }
                                                placeholder={`Choisissez le type d'énergie`}
                                                styles={colourStyles}
                                                components={{
                                                    Option: (props: OptionProps<any, any>) => (
                                                        <components.Option
                                                            {...props}
                                                            className={
                                                                'custom-react-select' +
                                                                (!props.data.included.includes(storageService.getAudit().step2.projectType.value)
                                                                    ? ' d-none'
                                                                    : '')
                                                            }
                                                        >
                                                            {props.label}
                                                        </components.Option>
                                                    ),
                                                }}
                                            />
                                            {touched.heaterType && errors.heaterType && (
                                                <div id="heaterTypeFeedback" className="invalid-feedback">
                                                    {errors.heaterType}
                                                </div>
                                            )}
                                        </div>

                                        {values.heaterType !== '999' && (
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="heaterFeature" className="form-label label-flex">
                                                    Type d’émetteur<sup>*</sup>
                                                    <AddStepPhotos
                                                        prop={buildingPicturesheaterFeature}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPicturesheaterFeature'}
                                                        required={true}
                                                        disabled={auditSent}
                                                    />
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(heaterFeatureList, values.heaterFeature)}
                                                    options={heaterFeatureList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={!values.heaterType || auditSent}
                                                    inputId="heaterFeature"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        // Réinitialisation du champ associé
                                                        storageService.removeAuditValue(3, 'heaterGeneratorPower');
                                                        // Mise à jour du champ courant
                                                        storageService.setAuditValue(3, isValid, 'heaterFeature', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            heaterFeature: event.value,
                                                            heaterGeneratorPower: null,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.heaterFeature ? ' filled' : '') +
                                                        (touched.heaterFeature && errors.heaterFeature ? ' invalid' : '') +
                                                        (!values.heaterFeature ? ' required' : '')
                                                    }
                                                    placeholder={"Choisissez le type d'émetteur"}
                                                    styles={colourStyles}
                                                    components={{
                                                        Option: (props: OptionProps<any, any>) => (
                                                            <components.Option
                                                                {...props}
                                                                className={
                                                                    'custom-react-select' + (!props.data.included.includes(values.heaterType) ? ' d-none' : '')
                                                                }
                                                            >
                                                                {props.label}
                                                            </components.Option>
                                                        ),
                                                    }}
                                                />
                                                {touched.heaterFeature && errors.heaterFeature && (
                                                    <div className="invalid-feedback">{errors.heaterFeature}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="row align-items-center mb-3">
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={heaterHealWholeHouse}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'heaterHealWholeHouse'}
                                                propName={values.heaterHealWholeHouse}
                                                title={`Présence d’un système secondaire de chauffage ?`}
                                                disabled={!values.heaterType || values.heaterType === '999' || auditSent}
                                                border={true}
                                            />
                                        </div>
                                        {values.heaterFeature === '6' && (
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <CheckboxService
                                                    handleCheckbox={multipleHydronicZoneHeatingSystem}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'multipleHydronicZoneHeatingSystem'}
                                                    propName={values.multipleHydronicZoneHeatingSystem}
                                                    title={`Le générateur ( chaudière , pac, etc) alimente t’il des radiateurs aux étages et ou sous-sol ?`}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        {values.heaterHealWholeHouse && (
                                            <>
                                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                    <label htmlFor="heaterType2" className="form-label">
                                                        Type d’énergie secondaire<sup>*</sup>
                                                    </label>
                                                    <Select
                                                        defaultValue={renderDefaultValueSelect(heaterTypeList, values.heaterType2)}
                                                        options={heaterTypeList}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        isMulti={false}
                                                        inputId="heaterType2"
                                                        onBlur={handleBlur}
                                                        isDisabled={auditSent}
                                                        onChange={(event) => {
                                                            storageService.setAuditValue(3, isValid, 'heaterType2', event.value, event.label);
                                                            setValues({
                                                                ...values,
                                                                heaterType2: event.value,
                                                            });
                                                            // Purge localstorage champs étape 4
                                                            storageService.removeAuditValue(4, 'energyCostsGas');
                                                            storageService.removeAuditValue(4, 'energyCostsFuel');
                                                            storageService.removeAuditValue(4, 'energyCostsWood');
                                                            storageService.removeAuditValue(4, 'energyCostsGasFuelWood');
                                                            storageService.removeAuditValue(4, 'energyCostsElectric');
                                                            storageService.removeAuditValue(4, 'energyCostsSum');
                                                            // Déclaration de l'étape 4 invalide
                                                            storageService.setAuditStepValidity(4, false);

                                                            // Vider la marque de la chaudière et Chaudière à condensation
                                                            if (!['1', '4', '6'].includes(event.value)) {
                                                                storageService.removeAuditValue(3, 'heaterBrand');
                                                                storageService.removeAuditValue(3, 'condensingHeater', true);

                                                                values.heaterBrand = '';
                                                                values.condensingHeater = '';

                                                                setValues({
                                                                    ...values,
                                                                    heaterType2: event.value,
                                                                });
                                                            }
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.heaterType2 ? ' filled' : '') +
                                                            (touched.heaterType2 && errors.heaterType2 ? ' invalid' : '') +
                                                            (!values.heaterType2 ? ' required' : '')
                                                        }
                                                        placeholder={'Choisissez une énergie secondaire'}
                                                        styles={colourStyles}
                                                        components={{
                                                            Option: (props: OptionProps<any, any>) => (
                                                                <components.Option
                                                                    {...props}
                                                                    className={
                                                                        'custom-react-select' +
                                                                        (props.data.hidden ||
                                                                        values.heaterType === props.data.value ||
                                                                        !props.data.included.includes(storageService.getAudit().step2.projectType.value)
                                                                            ? ' d-none'
                                                                            : '')
                                                                    }
                                                                >
                                                                    {props.label}
                                                                </components.Option>
                                                            ),
                                                        }}
                                                    />
                                                    {touched.heaterType2 && errors.heaterType2 && (
                                                        <div id="heaterType2Feedback" className="invalid-feedback">
                                                            {errors.heaterType2}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="heaterSurface" className="form-label">
                                                        Surface chauffée par le système secondaire<sup>*</sup>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            className={
                                                                'form-control' +
                                                                (values.heaterSurface || values.heaterSurface === 0 ? ' filled' : '') +
                                                                (touched.heaterSurface && errors.heaterSurface ? ' invalid' : '') +
                                                                (!values.heaterSurface ? ' required' : '')
                                                            }
                                                            disabled={auditSent}
                                                            placeholder="90"
                                                            name="heaterSurface"
                                                            id="heaterSurface"
                                                            onBlur={handleBlur}
                                                            value={values.heaterSurface || ''}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 3, 'heaterSurface')}
                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        />
                                                        <span className="input-group-text">m²</span>
                                                    </div>
                                                    {touched.heaterSurface && errors.heaterSurface && (
                                                        <div className="invalid-feedback no-absolute">{errors.heaterSurface}</div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconEnergy fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Contrats d'énergie</h2>
                            </div>

                            <div className="card-body">
                                <fieldset className="w-100">
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-md-0 mb-3">
                                            <label htmlFor="heaterSubscription" className="form-label">
                                                Abonnement et consommation électrique<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(heaterSubscriptionElectricityList, values.heaterSubscription)}
                                                options={heaterSubscriptionElectricityList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="heaterSubscription"
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'heaterSubscription', event.value, event.label);
                                                    storageService.setAuditValue(3, isValid, 'heaterSubscriptionElectricity', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        heaterSubscription: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.heaterSubscription ? ' filled' : '') +
                                                    (touched.heaterSubscription && errors.heaterSubscription ? ' invalid' : '') +
                                                    (!values.heaterSubscription ? ' required' : '')
                                                }
                                                placeholder={"Choisissez l'abonnement"}
                                                styles={colourStyles}
                                                components={{
                                                    Option: (props: OptionProps<any, any>) => (
                                                        <components.Option
                                                            {...props}
                                                            className={
                                                                'custom-react-select' +
                                                                (!props.data.includedProjectType.includes(storageService.getAudit().step2.projectType.value)
                                                                    ? ' d-none'
                                                                    : '')
                                                            }
                                                        >
                                                            {props.label}
                                                        </components.Option>
                                                    ),
                                                }}
                                            />
                                            {touched.heaterSubscription && errors.heaterSubscription && (
                                                <div id="heaterSubscriptionFeedback" className="invalid-feedback">
                                                    {errors.heaterSubscription}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-12 col-md-4 mb-md-0 mb-3">
                                            <label htmlFor="electricityProvider">
                                                Fournisseur d'électricité<sup>*</sup>
                                            </label>

                                            <Select
                                                value={renderDefaultValueSelect(providersList, values.electricityProvider)}
                                                options={providersList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId="electricityProvider"
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'electricityProvider', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        electricityProvider: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.electricityProvider ? ' filled' : '') +
                                                    (touched.electricityProvider && errors.electricityProvider ? ' invalid' : '') +
                                                    (!values.electricityProvider ? ' required' : '')
                                                }
                                                placeholder={'ENGIE'}
                                                styles={colourStyles}
                                                components={{
                                                    Option: (props: OptionProps<any, any>) => (
                                                        <components.Option
                                                            {...props}
                                                            className={
                                                                'custom-react-select' +
                                                                (!props.data.departement.includes(slicedZipCode) || !props.data.electricity ? ' d-none' : '')
                                                            }
                                                        >
                                                            {props.label}
                                                        </components.Option>
                                                    ),
                                                }}
                                            />
                                            {touched.electricityProvider && errors.electricityProvider && (
                                                <div id="heaterSubscriptionFeedback" className="invalid-feedback">
                                                    {errors.electricityProvider}
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="electricMeter">
                                                Type de compteur électrique<sup>*</sup>
                                                <div className="help-text" data-tip data-for="helpElectricMeter">
                                                    <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                </div>
                                            </label>

                                            <Select
                                                value={renderDefaultValueSelect(electricMeterList, values.electricMeter)}
                                                options={electricMeterList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                inputId={'electricMeter'}
                                                onBlur={handleBlur}
                                                isDisabled={auditSent}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'electricMeter', event.value, event.label);

                                                    setValues({
                                                        ...values,
                                                        electricMeter: event.value,
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.electricMeter ? ' filled' : '') +
                                                    (touched.electricMeter && errors.electricMeter ? ' invalid' : '') +
                                                    (!values.electricMeter ? ' required' : '')
                                                }
                                                placeholder={'Monophasé'}
                                                styles={colourStyles}
                                            />

                                            {touched.electricMeter && errors.electricMeter && (
                                                <div id="electricMeterFeedback" className="invalid-feedback">
                                                    {errors.electricMeter}
                                                </div>
                                            )}

                                            <ReactTooltip id="helpElectricMeter" place="bottom" type={'light'} effect={'solid'}>
                                                <h3>Si vous ne savez pas, veuillez vérifier sur votre compteur :</h3>
                                                <ul>
                                                    <li>Le logement est en monophasé si il y a 2 fils : 1 neutre et 1 phase</li>
                                                    <li>Le logement est triphasé si il y a 4 fils : 1 neutre et 3 phases</li>
                                                </ul>
                                            </ReactTooltip>
                                        </div>
                                    </div>

                                    {(values.heaterType2 === '4' || values.heaterType === '4') && (
                                        <div className="row">
                                            <div className="col-12 col-md-4 mb-md-0 mb-3">
                                                <label htmlFor="heaterSubscriptionGas" className="form-label">
                                                    Abonnement et consommation de gaz
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(heaterSubscriptionGasList, values.heaterSubscriptionGas)}
                                                    options={heaterSubscriptionGasList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    inputId="heaterSubscriptionGas"
                                                    onBlur={handleBlur}
                                                    isDisabled={auditSent}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(3, isValid, 'heaterSubscriptionGas', event.value, event.label);

                                                        setValues({
                                                            ...values,
                                                            heaterSubscriptionGas: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.heaterSubscriptionGas ? ' filled' : '') +
                                                        (touched.heaterSubscriptionGas && errors.heaterSubscriptionGas ? ' invalid' : '') +
                                                        (!values.heaterSubscriptionGas ? ' required' : '')
                                                    }
                                                    placeholder={"Choisissez l'abonnement"}
                                                    styles={colourStyles}
                                                    components={{
                                                        Option: (props: OptionProps<any, any>) => (
                                                            <components.Option
                                                                {...props}
                                                                className={
                                                                    'custom-react-select' +
                                                                    (!props.data.includedProjectType.includes(storageService.getAudit().step2.projectType.value)
                                                                        ? ' d-none'
                                                                        : '')
                                                                }
                                                            >
                                                                {props.label}
                                                            </components.Option>
                                                        ),
                                                    }}
                                                />
                                                {touched.heaterSubscriptionGas && errors.heaterSubscriptionGas && (
                                                    <div id="heaterSubscriptionFeedback" className="invalid-feedback">
                                                        {errors.heaterSubscriptionGas}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-12 col-md-4">
                                                <label htmlFor="gasProvider">Fournisseur de Gaz</label>

                                                <Select
                                                    value={renderDefaultValueSelect(providersList, values.gasProvider)}
                                                    options={providersList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="gasProvider"
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(3, isValid, 'gasProvider', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            gasProvider: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.gasProvider ? ' filled' : '') +
                                                        (touched.gasProvider && errors.gasProvider ? ' invalid' : '') +
                                                        (!values.gasProvider ? ' required' : '')
                                                    }
                                                    placeholder={'GDF'}
                                                    styles={colourStyles}
                                                    components={{
                                                        Option: (props: OptionProps<any, any>) => (
                                                            <components.Option
                                                                {...props}
                                                                className={
                                                                    'custom-react-select' +
                                                                    (!props.data.departement.includes(slicedZipCode) || !props.data.gas ? ' d-none' : '')
                                                                }
                                                            >
                                                                {props.label}
                                                            </components.Option>
                                                        ),
                                                    }}
                                                />
                                                {touched.gasProvider && errors.gasProvider && (
                                                    <div id="heaterSubscriptionFeedback" className="invalid-feedback">
                                                        {errors.gasProvider}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </fieldset>
                            </div>
                        </div>

                        {values.heaterType !== '999' && (
                            <div className="card mb-5">
                                <div className="card-title">
                                    <IconLightPlug fill="#76ca4f" width={40} height={40} className="icon" />
                                    <div className="flex flex-column">
                                        <h2>Générateur (chaudière, PAC, convecteur electrique…)</h2>
                                        <p>Un générateur est l’appareil qui convertit l’énergie qu’on lui apporte en énergie thermique (chauffage)</p>
                                    </div>

                                    <AddStepPhotos
                                        prop={buildingPicturesheaterGeneratorPower}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        fieldValue={'buildingPicturesheaterGeneratorPower'}
                                        required={true}
                                        disabled={auditSent}
                                    />
                                </div>
                                <div className="card-body">
                                    <fieldset className="w-100" disabled={auditSent}>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="heaterGeneratorPower" className="form-label">
                                                    Puissance<sup>*</sup>
                                                    <div className="help-text" data-tip data-for="helpHeaterGeneratorPower">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        step={0.1}
                                                        min={0.1}
                                                        className={
                                                            'form-control' +
                                                            (values.heaterGeneratorPower || values.heaterGeneratorPower === 0 ? ' filled' : '') +
                                                            (touched.heaterGeneratorPower && errors.heaterGeneratorPower ? ' invalid' : '') +
                                                            (!values.heaterGeneratorPower ? ' required' : '')
                                                        }
                                                        placeholder="3"
                                                        name="heaterGeneratorPower"
                                                        id="heaterGeneratorPower"
                                                        onBlur={handleBlur}
                                                        value={values.heaterGeneratorPower || ''}
                                                        disabled={auditSent}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 3, 'heaterGeneratorPower')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">kW</span>
                                                </div>
                                                {touched.heaterGeneratorPower && errors.heaterGeneratorPower && (
                                                    <div className="invalid-feedback no-absolute">{errors.heaterGeneratorPower}</div>
                                                )}
                                                <ReactTooltip id="helpHeaterGeneratorPower" place="bottom" type={'light'} effect={'solid'}>
                                                    <h3>Calcul de la puissance (Information liée Chauffage principal) :</h3>
                                                    <p>
                                                        (1000 W = 1 kW - Renseigner le champs en kW)
                                                        <br />
                                                        <br />
                                                        Veuillez indiquer la moyenne de puissance du cumul des différents générateur.
                                                        <br />
                                                        <br />
                                                        Si l’habitation comporte 5 convecteurs (Radiateur Électrique) 2 x 1 kW + 3 x 2 kW Si l’habitation
                                                        comporte 5 convecteurs (Radiateur Électrique) 2 x 1 kW + 3 x 2 kW Si l’habitation comporte 5 convecteurs
                                                        (Radiateur Électrique) 2 x 1 kW + 3 x 2 kW (soit une puissance totale de 8 kW) veuillez indiquer le
                                                        résultat de la division: 8kW / par 5 (soit une puissance totale de 8 kW) veuillez indiquer le résultat
                                                        de la division: 8kW / par 5 (soit une puissance totale de 8 kW) veuillez indiquer le résultat de la
                                                        division: 8kW / par 5 convecteurs soit 1,6kW. Le nombre identique à renseigner sera de 5 (pour les 5
                                                        convecteurs).
                                                        <br />
                                                        <br />
                                                        Si l’habitation comporte 8 convecteurs électriques de 1,5 kW la puissance à indiquer est Si l’habitation
                                                        comporte 8 convecteurs électriques de 1,5 kW la puissance à indiquer est Si l’habitation comporte 8
                                                        convecteurs électriques de 1,5 kW la puissance à indiquer est 1,5 et le nombre identique à renseigner
                                                        sera de 8 (pour les 8 convecteurs)
                                                        <br />
                                                        <br />
                                                        Dans le cadre d’une chaudière ou d’une pompe à chaleur veuillez indiquer sa puissance. Dans le cadre
                                                        d’une chaudière ou d’une pompe à chaleur veuillez indiquer sa puissance. Dans le cadre d’une chaudière
                                                        ou d’une pompe à chaleur veuillez indiquer sa puissance. Par exemple 16 kW. Si vous n’avez qu’une
                                                        chaudière veuillez indiquer dans nombre Par exemple 16 kW. Si vous n’avez qu’une chaudière veuillez
                                                        indiquer dans nombre Par exemple 16 kW. Si vous n’avez qu’une chaudière veuillez indiquer dans nombre
                                                        identique 1.
                                                        <br />
                                                        <br />
                                                        Si vous avez 2 chaudières ou 2 pompes à chaleurs veuillez cumuler la puissance des 2, Si vous avez 2
                                                        chaudières ou 2 pompes à chaleurs veuillez cumuler la puissance des 2, Si vous avez 2 chaudières ou 2
                                                        pompes à chaleurs veuillez cumuler la puissance des 2, par exemple 16 kW +11 kW et prendre la moyenne en
                                                        réalisant la division par exemple 16 kW +11 kW et prendre la moyenne en réalisant la division par
                                                        exemple 16 kW +11 kW et prendre la moyenne en réalisant la division 27 kW (16 kW +11 kW ) divisé par 2
                                                        soit 13,5 kW. Nombre identique veuillez indiquer 2 27 kW (16 kW +11 kW ) divisé par 2 soit 13,5 kW.
                                                        Nombre identique veuillez indiquer 2 27 kW (16 kW +11 kW ) divisé par 2 soit 13,5 kW. Nombre identique
                                                        veuillez indiquer 2 pour le nombre de chaudière ou pompe à chaleur.
                                                    </p>
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="heaterGeneratorIdenticalNumber" className="form-label">
                                                    Nombre de générateurs<sup>*</sup>
                                                </label>
                                                <input
                                                    type="number"
                                                    min={1}
                                                    max={['1', '4', '6'].includes(values.heaterType) ? 2 : 30}
                                                    className={
                                                        'form-control' +
                                                        (values.heaterGeneratorIdenticalNumber || values.heaterGeneratorIdenticalNumber === 0
                                                            ? ' filled'
                                                            : '') +
                                                        (touched.heaterGeneratorIdenticalNumber && errors.heaterGeneratorIdenticalNumber ? ' invalid' : '') +
                                                        (!values.heaterGeneratorIdenticalNumber ? ' required' : '')
                                                    }
                                                    placeholder={['1', '4', '6'].includes(values.heaterType) ? '1 ou 2' : '1 à 30' || auditSent}
                                                    disabled={!values.heaterType || ['2', '5'].includes(values.heaterType)}
                                                    name="heaterGeneratorIdenticalNumber"
                                                    id="heaterGeneratorIdenticalNumber"
                                                    onBlur={handleBlur}
                                                    value={values.heaterGeneratorIdenticalNumber || ''}
                                                    onChange={(event) => {
                                                        const maxValue = ['1', '4', '6'].includes(values.heaterType) ? '2' : '30';
                                                        /**
                                                         * Vérification de la saisie et mise à jour si nécessaire
                                                         */
                                                        if (event.target.value !== '' && parseFloat(event.target.value) > parseInt(maxValue)) {
                                                            event.target.value = maxValue;
                                                        }
                                                        updateValue(event, handleChange, isValid, 3, 'heaterGeneratorIdenticalNumber');
                                                    }}
                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                />
                                                {touched.heaterGeneratorIdenticalNumber && errors.heaterGeneratorIdenticalNumber && (
                                                    <div className="invalid-feedback">{errors.heaterGeneratorIdenticalNumber}</div>
                                                )}
                                            </div>
                                        </div>
                                        {showHeaterBrand(values) && (
                                            //['1', '2', '4'].includes(buildingType) && (['1', '4', '6'].includes(values.heaterType) || ['1', '4', '6'].includes(values.heaterType2)) &&
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                    <label htmlFor="heaterBrand" className="form-label">
                                                        Marque de la chaudière<sup>*</sup>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="heaterBrand"
                                                        className={
                                                            'form-control' +
                                                            (values.heaterBrand || values.heaterBrand === 0 ? ' filled' : '') +
                                                            (touched.heaterBrand && errors.heaterBrand ? ' invalid' : '') +
                                                            (!values.heaterBrand ? ' required' : '')
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.heaterBrand || ''}
                                                        disabled={auditSent}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                heaterBrand: event.target.value,
                                                            });
                                                            storageService.setAuditValue(3, isValid, 'heaterBrand', event.target.value);
                                                        }}
                                                    />
                                                    {touched.heaterBrand && errors.heaterBrand && (
                                                        <div className="invalid-feedback no-absolute">{errors.heaterBrand}</div>
                                                    )}
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="condensingHeater" className="form-label">
                                                        Chaudière à condensation<sup>*</sup>
                                                    </label>
                                                    <Select
                                                        value={renderDefaultValueSelect(condensingHeaterList, values.condensingHeater)}
                                                        options={condensingHeaterList}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        isMulti={false}
                                                        inputId="condensingHeater"
                                                        onBlur={handleBlur}
                                                        isDisabled={auditSent}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                condensingHeater: event.value,
                                                            });

                                                            storageService.setAuditValue(3, isValid, 'condensingHeater', event.value, event.label);
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.condensingHeater ? ' filled' : '') +
                                                            (touched.condensingHeater && errors.condensingHeater ? ' invalid' : '') +
                                                            (!values.condensingHeater ? ' required' : '')
                                                        }
                                                        placeholder={'Oui'}
                                                        styles={colourStyles}
                                                    />
                                                    {touched.condensingHeater && errors.condensingHeater && (
                                                        <div className="invalid-feedback no-absolute">{errors.condensingHeater}</div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="heaterGeneratorEmplacement" className="form-label">
                                                    Emplacement<sup>*</sup>
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(heaterGeneratorEmplacementList, values.heaterGeneratorEmplacement)}
                                                    options={heaterGeneratorEmplacementList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="heaterGeneratorEmplacement"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(3, isValid, 'heaterGeneratorEmplacement', event.value, event.label);
                                                        setValues({
                                                            ...values,
                                                            heaterGeneratorEmplacement: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.heaterGeneratorEmplacement ? ' filled' : '') +
                                                        (touched.heaterGeneratorEmplacement && errors.heaterGeneratorEmplacement ? ' invalid' : '') +
                                                        (!values.heaterGeneratorEmplacement ? ' required' : '')
                                                    }
                                                    placeholder={"Choisissez l'emplacement"}
                                                    styles={colourStyles}
                                                />
                                                {touched.heaterGeneratorEmplacement && errors.heaterGeneratorEmplacement && (
                                                    <div id="heaterGeneratorEmplacementFeedback" className="invalid-feedback">
                                                        {errors.heaterGeneratorEmplacement}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={heaterAlternateSystem}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'heaterAlternateSystem'}
                                                    propName={values.heaterAlternateSystem}
                                                    title={`Existence d’un système d’appoint ?`}
                                                    alt={'Si oui, veuillez déclarer ses caractéristiques'}
                                                    emptyLabel={true}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <CheckboxService
                                                    handleCheckbox={heaterOutdoorProbe}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'heaterOutdoorProbe'}
                                                    propName={values.heaterOutdoorProbe}
                                                    title={`Présence d’une sonde extérieure ?`}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={heaterProgrammer}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'heaterProgrammer'}
                                                    propName={values.heaterProgrammer}
                                                    title={`Présence d’un programmateur ?`}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            {values.heaterAlternateSystem && (
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="heaterAlternateSystemDescription" className="form-label">
                                                        Description du système d’appoint<sup>*</sup>
                                                    </label>
                                                    <textarea
                                                        rows={5}
                                                        className={
                                                            'form-control' +
                                                            (values.heaterAlternateSystemDescription ? ' filled' : '') +
                                                            (touched.heaterAlternateSystemDescription && errors.heaterAlternateSystemDescription
                                                                ? ' invalid'
                                                                : '') +
                                                            (!values.heaterAlternateSystemDescription ? ' required' : '')
                                                        }
                                                        placeholder="Description du système d’appoint..."
                                                        name="heaterAlternateSystemDescription"
                                                        id="heaterAlternateSystemDescription"
                                                        onBlur={handleBlur}
                                                        value={values.heaterAlternateSystemDescription || ''}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 3, 'heaterAlternateSystemDescription')}
                                                        disabled={auditSent}
                                                    ></textarea>
                                                    {touched.heaterAlternateSystemDescription && errors.heaterAlternateSystemDescription && (
                                                        <div id="heaterAlternateSystemDescriptionFeedback" className="invalid-feedback">
                                                            {errors.heaterAlternateSystemDescription}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        )}

                        {(storageService.getAuditCampagne() === 'reste_a_charge' || storageService.getAuditCampagne() === 'action_logement') &&
                            showRoomTypeDetails(values) && (
                                <div ref={descriptifPieceAChauffer} className="card mb-5">
                                    <div className="card-title">
                                        <IconThermometer fill="#76ca4f" width={40} height={40} className="icon" />
                                        <h2>Descriptif des pièces à chauffer</h2>
                                    </div>

                                    <div className="card-body">
                                        <p className="table-detail">Détails des pièces à chauffer</p>
                                        <div className="table-separate">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '25%' }}>Nom/Type de pièce</th>
                                                        <th style={{ width: '25%' }}>Longueur (m)</th>
                                                        <th style={{ width: '25%' }}>Largeur (m)</th>
                                                        <th style={{ width: '25%' }}>Hauteur (m)</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>

                                                {Object.keys(values?.roomTypeDetails)
                                                    .slice(0, 20)
                                                    .map((item: any) => (
                                                        <tbody key={item}>
                                                            <tr>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="text"
                                                                            min={1}
                                                                            maxLength={30}
                                                                            id={'roomType-' + item}
                                                                            className={
                                                                                'form-control' +
                                                                                (values.roomTypeDetails[item].roomType ? ' filled' : '') +
                                                                                ((touched as any)['roomType-' + item] && !values.roomTypeDetails[item].roomType
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.roomTypeDetails[item].roomType ? ' required' : '')
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            placeholder="Salle de bain"
                                                                            disabled={auditSent || values?.roomTypeDetails[item]?.roomTypeLocked}
                                                                            value={values.roomTypeDetails[item].roomType}
                                                                            onChange={(event: any) => {
                                                                                let tmpRoomTypeDetails = values.roomTypeDetails;
                                                                                tmpRoomTypeDetails[item].roomType = event.target.value;

                                                                                setValues({
                                                                                    ...values,
                                                                                    roomTypeDetails: tmpRoomTypeDetails,
                                                                                });

                                                                                storageService.setAuditValue(3, isValid, 'roomTypeDetails', tmpRoomTypeDetails);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="number"
                                                                            min={1}
                                                                            max={50}
                                                                            maxLength={2}
                                                                            placeholder="5"
                                                                            id={'roomLength-' + item}
                                                                            className={
                                                                                'form-control' +
                                                                                (values.roomTypeDetails[item].roomLength ? ' filled' : '') +
                                                                                ((touched as any)['roomLength-' + item] &&
                                                                                !values.roomTypeDetails[item].roomLength
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.roomTypeDetails[item].roomLength ? ' required' : '')
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            disabled={auditSent || values?.roomTypeDetails[item]?.roomTypeLocked}
                                                                            value={values.roomTypeDetails[item].roomLength}
                                                                            onChange={(event: any) => {
                                                                                let tmpRoomTypeDetails = values.roomTypeDetails;
                                                                                tmpRoomTypeDetails[item].roomLength = event.target.value;

                                                                                setValues({
                                                                                    ...values,
                                                                                    roomTypeDetails: tmpRoomTypeDetails,
                                                                                });

                                                                                storageService.setAuditValue(3, isValid, 'roomTypeDetails', tmpRoomTypeDetails);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="number"
                                                                            min={1}
                                                                            max={50}
                                                                            maxLength={2}
                                                                            placeholder="5"
                                                                            id={'roomWidth-' + item}
                                                                            className={
                                                                                'form-control' +
                                                                                (values.roomTypeDetails[item].roomWidth ? ' filled' : '') +
                                                                                ((touched as any)['roomWidth-' + item] &&
                                                                                !values.roomTypeDetails[item].roomWidth
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.roomTypeDetails[item].roomWidth ? ' required' : '')
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            disabled={auditSent || values?.roomTypeDetails[item]?.roomTypeLocked}
                                                                            value={values.roomTypeDetails[item].roomWidth}
                                                                            onChange={(event: any) => {
                                                                                let tmpRoomTypeDetails = values.roomTypeDetails;
                                                                                tmpRoomTypeDetails[item].roomWidth = event.target.value;

                                                                                setValues({
                                                                                    ...values,
                                                                                    roomTypeDetails: tmpRoomTypeDetails,
                                                                                });

                                                                                storageService.setAuditValue(3, isValid, 'roomTypeDetails', tmpRoomTypeDetails);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table-fit">
                                                                        <input
                                                                            type="number"
                                                                            min={1}
                                                                            max={10}
                                                                            maxLength={2}
                                                                            placeholder="2,5"
                                                                            id={'roomHeight-' + item}
                                                                            className={
                                                                                'form-control' +
                                                                                (values.roomTypeDetails[item].roomHeight ? ' filled' : '') +
                                                                                ((touched as any)['roomHeight-' + item] &&
                                                                                !values.roomTypeDetails[item].roomHeight
                                                                                    ? ' invalid'
                                                                                    : '') +
                                                                                (!values.roomTypeDetails[item].roomHeight ? ' required' : '')
                                                                            }
                                                                            onBlur={handleBlur}
                                                                            disabled={auditSent || values?.roomTypeDetails[item]?.roomTypeLocked}
                                                                            value={values.roomTypeDetails[item].roomHeight}
                                                                            onChange={(event: any) => {
                                                                                let tmpRoomTypeDetails = values.roomTypeDetails;
                                                                                tmpRoomTypeDetails[item].roomHeight = event.target.value;

                                                                                setValues({
                                                                                    ...values,
                                                                                    roomTypeDetails: tmpRoomTypeDetails,
                                                                                });

                                                                                storageService.setAuditValue(3, isValid, 'roomTypeDetails', tmpRoomTypeDetails);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {/* Bouton suppression */}
                                                                    <button
                                                                        type="button"
                                                                        className="btn icon-remove icon-remove-red"
                                                                        onClick={() => {
                                                                            let updatedObject = values.roomTypeDetails;
                                                                            updatedObject.splice(item, 1);
                                                                            // Si plus tableau vide, injection objet vide
                                                                            if (updatedObject.length === 0) {
                                                                                updatedObject = [
                                                                                    ...updatedObject,
                                                                                    {
                                                                                        roomType: '',
                                                                                        roomLength: '',
                                                                                        roomWidth: '',
                                                                                        roomHeight: '',
                                                                                        roomTypeLocked: false,
                                                                                    },
                                                                                ];
                                                                            }

                                                                            setValues({
                                                                                ...values,
                                                                                roomTypeDetails: updatedObject,
                                                                            });

                                                                            // Mise à jour du localStorage
                                                                            storageService.setAuditValue(3, isValid, 'roomTypeDetails', updatedObject);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>

                                                            <tr className="separator">
                                                                <td colSpan={5}>&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    ))}
                                            </table>
                                        </div>

                                        <button
                                            className={'btn btn-green icon-add btn-square'}
                                            disabled={
                                                values?.roomTypeDetails?.length >= 20 ||
                                                (Object.values(errors)?.length > 0 && typeof errors?.roomTypeDetails === 'object')
                                            }
                                            onClick={() => {
                                                // Mise à jour du blocage de la dernière ligne saisie
                                                values.roomTypeDetails[values.roomTypeDetails.length - 1].roomTypeLocked = true;

                                                // Ajout d'un nouvel élément au tableau
                                                values.roomTypeDetails = [
                                                    ...values.roomTypeDetails,
                                                    {
                                                        roomType: '',
                                                        roomLength: '',
                                                        roomWidth: '',
                                                        roomHeight: '',
                                                        roomTypeLocked: false,
                                                    },
                                                ];

                                                // Mise à jour dans le Formik
                                                setValues({
                                                    ...values,
                                                    roomTypeDetails: values.roomTypeDetails,
                                                });

                                                // Mise à jour du local storage
                                                storageService.setAuditValue(3, isValid, 'roomTypeDetails', values.roomTypeDetails);
                                            }}
                                            type={'button'}
                                        >
                                            Ajouter une nouvelle pièce
                                        </button>
                                    </div>
                                </div>
                            )}

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconBoiler fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Eau chaude sanitaire</h2>
                                <AddStepPhotos
                                    prop={buildingPicturesenergyWater}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    fieldValue={'buildingPicturesenergyWater'}
                                    required={true}
                                    disabled={auditSent}
                                />
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <label htmlFor="energyWaterType" className="form-label">
                                                Type d’énergie pour l’eau chaude sanitaire<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(energyWaterList, values.energyWaterType)}
                                                options={energyWaterList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent || values.heaterType === '999'}
                                                inputId="energyWaterType"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterElectrical', event.value === '1');
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncluded', event.value !== '1');
                                                    // Réinitialisation des champs enfants
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterElectricalOrientation', '', '');
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterStorageCapacity', null);
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncludedStorageCapacity', null);
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterElectricalEmplacement', '', '');
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterThermodynamic', false);
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncludedAccumulation', false);
                                                    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncludedInstant', false);

                                                    // Mise à jour Formik
                                                    setValues({
                                                        ...values,
                                                        energyWaterType: event.value,
                                                        heaterHotWaterElectricalOrientation: '',
                                                        heaterHotWaterStorageCapacity: null,
                                                        heaterHotWaterIncludedStorageCapacity: null,
                                                        heaterHotWaterElectricalEmplacement: '',
                                                        heaterHotWaterThermodynamic: '',
                                                        heaterHotWaterIncludedAccumulation: '',
                                                        heaterHotWaterIncludedInstant: '',
                                                    });
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.energyWaterType ? ' filled' : '') +
                                                    (touched.energyWaterType && errors.energyWaterType ? ' invalid' : '') +
                                                    (!values.energyWaterType ? ' required' : '')
                                                }
                                                placeholder={`Choisissez le type d'énergie pour l'eau sanitaire`}
                                                styles={colourStyles}
                                            />
                                            {touched.energyWaterType && errors.energyWaterType && (
                                                <div className="invalid-feedback">{errors.energyWaterType}</div>
                                            )}
                                        </div>
                                        {values.energyWaterType === '1' && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="heaterHotWaterElectricalOrientation" className="form-label">
                                                    Orientation du chauffe-eau<sup>*</sup>
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(
                                                        heaterHotWaterElectricalOrientationList,
                                                        values.heaterHotWaterElectricalOrientation
                                                    )}
                                                    options={heaterHotWaterElectricalOrientationList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="heaterHotWaterElectricalOrientation"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(
                                                            3,
                                                            isValid,
                                                            'heaterHotWaterElectricalOrientation',
                                                            event.value,
                                                            event.label
                                                        );
                                                        setValues({
                                                            ...values,
                                                            heaterHotWaterElectricalOrientation: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.heaterHotWaterElectricalOrientation ? ' filled' : '') +
                                                        (touched.heaterHotWaterElectricalOrientation && errors.heaterHotWaterElectricalOrientation
                                                            ? ' invalid'
                                                            : '') +
                                                        (!values.heaterHotWaterElectricalOrientation ? ' required' : '')
                                                    }
                                                    placeholder={`Choisissez l'orientation du chauffe-eau`}
                                                    styles={colourStyles}
                                                />
                                                {touched.heaterHotWaterElectricalOrientation && errors.heaterHotWaterElectricalOrientation && (
                                                    <div className="invalid-feedback">{errors.heaterHotWaterElectricalOrientation}</div>
                                                )}
                                            </div>
                                        )}
                                        {values.energyWaterType === '2' && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="heaterHotWaterIncludedStorageCapacity" className="form-label">
                                                    Capacité de stockage<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (values.heaterHotWaterIncludedStorageCapacity || values.heaterHotWaterIncludedStorageCapacity === 0
                                                                ? ' filled'
                                                                : '') +
                                                            (touched.heaterHotWaterIncludedStorageCapacity && errors.heaterHotWaterIncludedStorageCapacity
                                                                ? ' invalid'
                                                                : '') +
                                                            (!values.heaterHotWaterIncludedStorageCapacity ? ' required' : '')
                                                        }
                                                        placeholder="100"
                                                        name="heaterHotWaterIncludedStorageCapacity"
                                                        id="heaterHotWaterIncludedStorageCapacity"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.heaterHotWaterIncludedStorageCapacity}
                                                        onChange={(event) =>
                                                            updateValue(event, handleChange, isValid, 3, 'heaterHotWaterIncludedStorageCapacity')
                                                        }
                                                        disabled={auditSent}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">Litres</span>
                                                </div>
                                                {touched.heaterHotWaterIncludedStorageCapacity && errors.heaterHotWaterIncludedStorageCapacity && (
                                                    <div className="invalid-feedback">{errors.heaterHotWaterIncludedStorageCapacity}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {values.energyWaterType === '1' && (
                                        <div>
                                            <div className="row">
                                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                    <label htmlFor="heaterHotWaterStorageCapacity" className="form-label">
                                                        Capacité de stockage<sup>*</sup>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className={
                                                                'form-control' +
                                                                (values.heaterHotWaterStorageCapacity || values.heaterHotWaterStorageCapacity === 0
                                                                    ? ' filled'
                                                                    : '') +
                                                                (touched.heaterHotWaterStorageCapacity && errors.heaterHotWaterStorageCapacity
                                                                    ? ' invalid'
                                                                    : '') +
                                                                (!values.heaterHotWaterStorageCapacity ? ' required' : '')
                                                            }
                                                            placeholder="100"
                                                            name="heaterHotWaterStorageCapacity"
                                                            id="heaterHotWaterStorageCapacity"
                                                            onBlur={handleBlur}
                                                            defaultValue={values.heaterHotWaterStorageCapacity}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 3, 'heaterHotWaterStorageCapacity')}
                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        />
                                                        <span className="input-group-text">Litres</span>
                                                    </div>
                                                    {touched.heaterHotWaterStorageCapacity && errors.heaterHotWaterStorageCapacity && (
                                                        <div className="invalid-feedback">{errors.heaterHotWaterStorageCapacity}</div>
                                                    )}
                                                </div>
                                                {values.heaterType !== '999' && (
                                                    <div className="col-12 col-md-6">
                                                        <label htmlFor="heaterHotWaterElectricalEmplacement" className="form-label">
                                                            Emplacement<sup>*</sup>
                                                        </label>
                                                        <Select
                                                            defaultValue={renderDefaultValueSelect(
                                                                heaterHotWaterElectricalEmplacementList,
                                                                values.heaterHotWaterElectricalEmplacement
                                                            )}
                                                            options={heaterHotWaterElectricalEmplacementList}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            isDisabled={auditSent}
                                                            inputId="heaterHotWaterElectricalEmplacement"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                storageService.setAuditValue(
                                                                    3,
                                                                    isValid,
                                                                    'heaterHotWaterElectricalEmplacement',
                                                                    event.value,
                                                                    event.label
                                                                );
                                                                setValues({
                                                                    ...values,
                                                                    heaterHotWaterElectricalEmplacement: event.value,
                                                                });
                                                            }}
                                                            className={
                                                                'basic-single' +
                                                                (values.heaterHotWaterElectricalEmplacement ? ' filled' : '') +
                                                                (touched.heaterHotWaterElectricalEmplacement && errors.heaterHotWaterElectricalEmplacement
                                                                    ? ' invalid'
                                                                    : '') +
                                                                (!values.heaterHotWaterElectricalEmplacement ? ' required' : '')
                                                            }
                                                            placeholder={`Choisissez l'emplacement du chauffe-eau`}
                                                            styles={colourStyles}
                                                        />
                                                        {touched.heaterHotWaterElectricalEmplacement && errors.heaterHotWaterElectricalEmplacement && (
                                                            <div className="invalid-feedback">{errors.heaterHotWaterElectricalEmplacement}</div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <CheckboxService
                                                        handleCheckbox={heaterHotWaterThermodynamic}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'heaterHotWaterThermodynamic'}
                                                        propName={values.heaterHotWaterThermodynamic}
                                                        title={`Chauffe-eau thermodynamique`}
                                                        emptyLabel={true}
                                                        border={true}
                                                        disabled={auditSent}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <CheckboxService
                                                        handleCheckbox={heaterHotSolarWaterThermodynamic}
                                                        values={values}
                                                        isValid={isValid}
                                                        setValues={setValues}
                                                        name={'heaterHotSolarWaterThermodynamic'}
                                                        propName={values.heaterHotSolarWaterThermodynamic}
                                                        title={`Chauffe-eau solaire`}
                                                        emptyLabel={true}
                                                        border={true}
                                                        disabled={auditSent}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {values.energyWaterType === '2' && (
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <CheckboxService
                                                    handleCheckbox={heaterHotWaterIncludedAccumulation}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'heaterHotWaterIncludedAccumulation'}
                                                    propName={values.heaterHotWaterIncludedAccumulation}
                                                    title={`Accumulation`}
                                                    emptyLabel={true}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>

                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={heaterHotWaterIncludedInstant}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'heaterHotWaterIncludedInstant'}
                                                    propName={values.heaterHotWaterIncludedInstant}
                                                    title={`Instantané`}
                                                    emptyLabel={true}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0" style={{ minHeight: 71 }}>
                                            <CheckboxService
                                                handleCheckbox={heaterHotWaterIndependent}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'heaterHotWaterIndependent'}
                                                propName={values.heaterHotWaterIndependent}
                                                title={`Système Indépendant chauffe-bain`}
                                                emptyLabel={true}
                                                border={true}
                                                disabled={auditSent}
                                            />
                                        </div>
                                        {values.heaterHotWaterIndependent && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="heaterHotWaterIndependentBathHeaterEmplacement" className="form-label">
                                                    Emplacement<sup>*</sup>
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(
                                                        heaterHotWaterIndependentBathHeaterEmplacementList,
                                                        values.heaterHotWaterIndependentBathHeaterEmplacement
                                                    )}
                                                    options={heaterHotWaterIndependentBathHeaterEmplacementList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="heaterHotWaterIndependentBathHeaterEmplacement"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        storageService.setAuditValue(
                                                            3,
                                                            isValid,
                                                            'heaterHotWaterIndependentBathHeaterEmplacement',
                                                            event.value,
                                                            event.label
                                                        );
                                                        setValues({
                                                            ...values,
                                                            heaterHotWaterIndependentBathHeaterEmplacement: event.value,
                                                        });
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.heaterHotWaterIndependentBathHeaterEmplacement ? ' filled' : '') +
                                                        (touched.heaterHotWaterIndependentBathHeaterEmplacement &&
                                                        errors.heaterHotWaterIndependentBathHeaterEmplacement
                                                            ? ' invalid'
                                                            : '') +
                                                        (!values.heaterHotWaterIndependentBathHeaterEmplacement ? ' required' : '')
                                                    }
                                                    placeholder={`Choisissez l'emplacement`}
                                                    styles={colourStyles}
                                                />
                                                {touched.heaterHotWaterIndependentBathHeaterEmplacement &&
                                                    errors.heaterHotWaterIndependentBathHeaterEmplacement && (
                                                        <div className="invalid-feedback">{errors.heaterHotWaterIndependentBathHeaterEmplacement}</div>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        {['1', '4'].includes(storageService.getAudit().step2.projectType.value) && (
                            <div className="card mb-5">
                                <div className="card-title">
                                    <IconPhotovoltaique fill="#76ca4f" width={40} height={40} className="icon" />
                                    <h2>Installation photovoltaïque</h2>
                                </div>

                                <div className="card-body">
                                    <fieldset className="w-100" disabled={auditSent}>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <CheckboxService
                                                    handleCheckbox={photovoltaique}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'photovoltaique'}
                                                    propName={values.photovoltaique}
                                                    title={`Présence d'une installation photovoltaïque ?`}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>

                                            {values.photovoltaique && (
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="photovoltaiquePower">
                                                        Puissance de l'installation photovoltaïque <sup>*</sup>
                                                    </label>
                                                    <Select
                                                        defaultValue={renderDefaultValueSelect(photovoltaiquePowerList, values.photovoltaiquePower)}
                                                        options={photovoltaiquePowerList}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        isMulti={false}
                                                        isDisabled={auditSent}
                                                        inputId={'photovoltaiquePower'}
                                                        onBlur={handleBlur}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                photovoltaiquePower: event.label,
                                                            });
                                                            storageService.setAuditValue(3, isValid, 'photovoltaiquePower', event.value, event.label);
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.photovoltaiquePower ? ' filled' : '') +
                                                            (touched.photovoltaiquePower && errors.photovoltaiquePower ? ' invalid' : '') +
                                                            (!values.photovoltaiquePower ? ' required' : '')
                                                        }
                                                        placeholder={`Entre 0 et 3 kW`}
                                                        styles={colourStyles}
                                                    />

                                                    {touched.photovoltaiquePower && errors.photovoltaiquePower && (
                                                        <div className="invalid-feedback">{errors.photovoltaiquePower}</div>
                                                    )}
                                                </div>
                                            )}

                                            {values.photovoltaique && (
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="photovoltaiqueConsumption">Mode de consommation de la production photovoltaïque</label>
                                                    <Select
                                                        defaultValue={renderDefaultValueSelect(photovoltaiqueConsumptionList, values.photovoltaiqueConsumption)}
                                                        options={photovoltaiqueConsumptionList}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        isMulti={false}
                                                        isDisabled={auditSent}
                                                        inputId={'photovoltaiqueConsumption'}
                                                        onBlur={handleBlur}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                photovoltaiqueConsumption: event.label,
                                                            });
                                                            storageService.setAuditValue(3, isValid, 'photovoltaiqueConsumption', event.value, event.label);
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.photovoltaiqueConsumption ? ' filled' : '') +
                                                            (touched.photovoltaiqueConsumption && errors.photovoltaiqueConsumption ? ' invalid' : '') +
                                                            (!values.photovoltaiqueConsumption ? ' required' : '')
                                                        }
                                                        placeholder={`Autoconsommation et revente`}
                                                        styles={colourStyles}
                                                    />

                                                    {touched.photovoltaiqueConsumption && errors.photovoltaiqueConsumption && (
                                                        <div className="invalid-feedback">{errors.photovoltaiqueConsumption}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        )}

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconFan fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Ventilation (VMC)</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                            <label htmlFor="ventilationType" className="form-label">
                                                Type de ventilation<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(domTomVmc(), values.ventilationType)}
                                                options={domTomVmc()}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="ventilationType"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(3, isValid, 'ventilationType', event.value, event.label);
                                                    setValues({
                                                        ...values,
                                                        ventilationType: event.value,
                                                    });

                                                    if (['2', '3', '7'].includes(event.value)) {
                                                        setValues({
                                                            ...values,
                                                            ventilationInstallationDate: '',
                                                            ventilationType: event.value,
                                                        });
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.ventilationType ? ' filled' : '') +
                                                    (touched.ventilationType && errors.ventilationType ? ' invalid' : '') +
                                                    (!values.ventilationType ? ' required' : '')
                                                }
                                                placeholder={`Choisissez le type de ventilation`}
                                                styles={colourStyles}
                                            />
                                            {touched.ventilationType && errors.ventilationType && (
                                                <div className="invalid-feedback">{errors.ventilationType}</div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {['5'].includes(values.ventilationType) && (
                                                <CheckboxService
                                                    handleCheckbox={ventilationInstallationDate}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'ventilationInstallationDate'}
                                                    propName={values.ventilationInstallationDate}
                                                    title={`L'installation a été effectuée avant ${currentYearMinus(3)} ?`}
                                                    emptyLabel={true}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(3, '/audit')}>
                                Retour au menu principal
                            </button>
                            <button
                                type="button"
                                onClick={(event: any) => {
                                    event.preventDefault();

                                    // Définition des variables pour "Descriptif des pièces à chauffer"
                                    const nombrePieces = storageService.getAudit().step2.generalOptionsMain.value;
                                    let roomTypeDetailsSize = values?.roomTypeDetails?.length - 1;

                                    // Cas où la longueur du tableau équivaut au nombre de pièces et pas d'erreur des sous-champs
                                    if (typeof errors.roomTypeDetails !== 'object') {
                                        roomTypeDetailsSize = values?.roomTypeDetails?.length;
                                    }

                                    // Purge des erreurs concernant roomTypeDetails
                                    delete errors?.roomTypeDetails;
                                    setErrors(errors);

                                    // Cas 1
                                    if (
                                        (storageService.getAuditCampagne() === 'action_logement' || storageService.getAuditCampagne() === 'reste_a_charge') &&
                                        (values.heaterType === '0' || values.heaterType2 === '0') &&
                                        roomTypeDetailsSize === 0
                                    ) {
                                        return AlertSwal.fire({
                                            title: `Descriptif des pièces à chauffer`,
                                            html: `<p class="mb-0">Vous avez indiqué que votre énergie pour le chauffage est l'électricité.<br/>
                                                    Afin de dimensionner correctement votre installation vous êtes priés d'indiquer au moins 1 pièce dans l'encadré "Descriptif des pièces à chauffer" et pour chaque pièce chauffée (chambres, salon, séjour), nom, longueur, largeur, hauteur.</p>`,
                                            cancelButtonText: 'Retour',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        }).then((result) => {
                                            if (result.isDismissed) {
                                                setTimeout(() => {
                                                    // @ts-ignore
                                                    window.scrollTo({ behavior: 'smooth', top: descriptifPieceAChauffer.current.offsetTop - 100 });
                                                }, 250);
                                            }
                                        });
                                    }

                                    // Cas 2
                                    if (
                                        (storageService.getAuditCampagne() === 'action_logement' || storageService.getAuditCampagne() === 'reste_a_charge') &&
                                        (values.heaterType === '0' || values.heaterType2 === '0') &&
                                        nombrePieces !== roomTypeDetailsSize
                                    ) {
                                        return AlertSwal.fire({
                                            title: `Descriptif des pièces à chauffer`,
                                            html: `<p class="mb-0">Vous avez indiqué que votre énergie pour le chauffage est l'électricité.<br/>
                                        Vous avez renseigné dans l'encadré "Descriptif des pièces à chauffer" <strong>${roomTypeDetailsSize}</strong> pièce(s) alors que vous avez déclaré à l'étape 2 le nombre de <strong>${
                                                storageService.getAudit().step2.generalOptionsMain.value
                                            }</strong> pièce(s) principale(s) (chambres, salon, séjour <strong>hors cuisine</strong>).<br>Veuillez cliquer sur annuler si vous souhaitez apportez des corrections, sinon cliquez sur continuer.</p>`,
                                            cancelButtonText: 'Annuler',
                                            confirmButtonText: 'Continuer',
                                            showConfirmButton: true,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            reverseButtons: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                                confirmButton: 'btn btn-secondary maxi-min-width',
                                            },
                                        }).then((result) => {
                                            if (result.isDismissed) {
                                                setTimeout(() => {
                                                    // @ts-ignore
                                                    window.scrollTo({ behavior: 'smooth', top: descriptifPieceAChauffer.current.offsetTop - 100 });
                                                }, 250);
                                            }
                                            if (result.isConfirmed) {
                                                // Purger les données qui ne sont pas intégralement remplies (le dernier élément du tableau)
                                                if (typeof errors.roomTypeDetails === 'object') {
                                                    delete values.roomTypeDetails[roomTypeDetailsSize];
                                                    values.roomTypeDetails.pop();
                                                    setValues({
                                                        ...values,
                                                        roomTypeDetails: values.roomTypeDetails,
                                                    });

                                                    // Injection des valeurs dans le localstorage
                                                    storageService.setAuditValue(3, isValid, 'roomTypeDetails', values.roomTypeDetails);
                                                }

                                                // Lancement de la fonction standard de validation des champs
                                                if (Object.entries(errors).length !== 0) {
                                                    setTimeout(() => displayErrorsDialog(errors), 500);
                                                } else {
                                                    validateThenRoute(3, ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE);
                                                }
                                            }
                                        });
                                    }

                                    if (Object.entries(errors).length !== 0) {
                                        displayErrorsDialog(errors);
                                    } else {
                                        if (checkPhotos(values)) {
                                            // Préparation liste des photos en erreur
                                            let errorsList: string[] = [];
                                            mandatoryPhotos.forEach((photo: string) => {
                                                // @ts-ignore
                                                if (values[photo.name].length === 0) {
                                                    // @ts-ignore
                                                    errorsList = [...errorsList, `${photo.errorMessage}`];
                                                }
                                            });

                                            // Affichage message
                                            AlertSwal.fire({
                                                title: `Photos manquantes`,
                                                html: `<p class="mb-0">Les photos suivantes n’ont pas été insérées :<br/>
                                            <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span>
                                            <br/>
                                            Vous pouvez poursuivre le remplissage de l’audit mais il sera nécessaire de les ajouter à l’étape 8 pour sa validation finale 
                                            </p>`,
                                                cancelButtonText: 'Rester sur l’étape',
                                                confirmButtonText: 'Accepter',
                                                showConfirmButton: true,
                                                showCancelButton: true,
                                                reverseButtons: true,
                                                focusCancel: true,
                                                showClass: {
                                                    popup: 'errors',
                                                    backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                                },
                                                customClass: {
                                                    cancelButton: 'btn btn-primary maxi-min-width',
                                                    confirmButton: 'btn btn-secondary maxi-min-width',
                                                },
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    validateThenRoute(3, ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE);
                                                }
                                            });
                                        } else {
                                            validateThenRoute(3, ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE);
                                        }
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default EnergySystemInformations;
