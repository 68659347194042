import { StringifiedProjectionFinanciere } from './calculs/applyPreconisation';
import { ModeConsommation } from './calculs/calculPhotovoltaique';
import { PackageData } from './calculs/package';
import { Theme } from './calculs/theme';
import { TicketData } from './calculs/ticket';
import { ClientIdentity } from './localStorageService';
import { capitalizeFirstLetter, Labelled, LabelledString } from './tools/TypeHelper';
import { getLevelCount } from './tools/auditTools';
import * as storageService from '../services/localStorageService';
import { AppointmentOutput, FundingType, PaginatedResponse, createFlow, isAuditType } from './apiFlowService';
import { FilterThemePreference } from '../pages/espace-agent/mon-compte/preference';
import { ROUTE_LOGIN } from '../routing/paths';
import { DpPicturePayload } from '../pages/pre-visite/Forecast/Forecast';
import { FeatureCollection, Point } from 'geojson';
import { DpeLine } from './dpe/types';
import { RenovationDAmpleurParams } from './calculs/aides/aideRenovationDAmpleurDefs';
import { DocumentEntry } from '../components/Document/DocumentList';

export const ERROR_INDISPONIBLE = "L'accès au service d'envoi est indisponible, veuillez réessayer en vous assurant de disposer d'une connexion internet";

const ArbortReasonDefs = { cancelled: "Annulé par l'utilisateur", timedout: "Délai maximum d'attente écoulé, sans succès" };

export type AbortReason = keyof typeof ArbortReasonDefs;

export const isErrorBody = (value: unknown): value is ErrorBody<unknown> => {
    if (!value) return false;
    const converted = value as ErrorBody<unknown>;
    if (!converted || !converted.error || !converted.message || !converted.errorId) return false;
    return true;
};

export interface ErrorBody<T> {
    statusCode: number;
    error: string;
    errorId: string;
    message: string;
    details?: T;
}

//#region base query (get, post, put)
// #region search params

export type Pagination = {
    pageIndex: number;
    elementsPerPage: number;
};

export type Order = 'asc' | 'desc';

export type OrderBy = {
    key: string;
    order: Order;
};

export type SearchParams =
    | {
          filters?: Record<string, string | string[] | number | boolean>;
          orderby?: OrderBy | Array<OrderBy>;
          pagination?: Pagination;
      }
    | undefined;

// #endregion

export const getData = async (url: string) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/${url}`, {
        credentials: 'include',
        method: 'GET',
    });

    if (!response.ok) {
        // Si le code de statut n'est pas dans la plage [200, 299]
        if (response.status === 401) {
            // Le statut est 401 (Unauthorized)
            // Vous pouvez ici gérer la déconnexion de l'utilisateur
            // Par exemple, rediriger vers la page de login ou afficher un message d'erreur
            window.location.href = ROUTE_LOGIN;
        }
        throw await response.json();
    }
    // // Don't know about side effect on this.
    // if (response.status === 201 || response.status === 204) {
    //     console.log('response empty, no JSON ');
    //     // may be rebuild a reply with a status since our
    //     return undefined;
    // }
    return await response.json();
};

export const getExternalData = async (url: string) => {
    const response = await fetch(`${url}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
};

export const getDataBlob = async (url: string) => {
    const response = await fetch(`${url}`, {
        method: 'GET',
    });

    if (!response.ok) {
        // Si le code de statut n'est pas dans la plage [200, 299]
        if (response.status === 401) {
            // Le statut est 401 (Unauthorized)
            // Vous pouvez ici gérer la déconnexion de l'utilisateur
            // Par exemple, rediriger vers la page de login ou afficher un message d'erreur
            window.location.href = ROUTE_LOGIN;
        }
        throw await response.blob();
    }

    return await response.blob();
};

export const deleteData = async (url: string, body?: any) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(`${process.env.REACT_APP_URL_API}/${url}`, {
        credentials: 'include',
        method: 'DELETE',
        headers,
        body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
        // Si le code de statut n'est pas dans la plage [200, 299]
        if (response.status === 401) {
            // Le statut est 401 (Unauthorized)
            // Vous pouvez ici gérer la déconnexion de l'utilisateur
            // Par exemple, rediriger vers la page de login ou afficher un message d'erreur
            window.location.href = ROUTE_LOGIN;
        }
        throw await response.json();
    }
};

export type UploadedImage = {
    fileKey: string;
    url: string;
};
export type UploadOutput = {
    success: Array<UploadedImage>;
    filerrors: string[];
};

export const uploadFiles = async (formData: FormData): Promise<UploadOutput> => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/uploads/persist`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
    });

    return await response.json();
};
// 2024-10-01 => passer la route /uploads en /uploads/persist, pour permettre la duplication des photos; lors de la duplication d'audit.
// rappel de contexte, la route uploads, télécharge des fichiers temporaire qui sont à destination de icoll
// Ils sont ensuite supprimés au bout de 24h.

export const postFile = async (body: Record<string, File>): Promise<UploadOutput> => {
    let headers = {};

    let response: any;

    let formData = new FormData();
    Object.keys(body).forEach((item: any) => {
        formData.append(item, body[item]);
    });

    response = await fetch(`${process.env.REACT_APP_URL_API}/uploads/persist`, {
        headers,
        method: 'POST',
        credentials: 'include',
        body: formData,
    });

    return await response.json();
};
export type Document = {
    id: string;
    url: string;
    fileName: string;
    extension: string;
};
export const postDocument = async (body: any): Promise<{ [key in string]: Document }> => {
    let headers = {};

    let response: any;

    let formData = new FormData();
    Object.keys(body).forEach((item: any) => {
        formData.append(item, body[item]);
    });
    response = await fetch(`${process.env.REACT_APP_URL_API}/document/uploads`, {
        headers,
        method: 'POST',
        credentials: 'include',
        body: formData,
    });

    return await response.json();
};

export const putJsonData = async (url: string, body?: any, timeout: number = 0, aborter: AbortController | undefined = undefined) => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    let signal: AbortSignal | undefined = undefined;

    // 4 cases :
    // A - non-abortable (undefined)
    //   1 - with timeout
    //   2 - without timeout (timeout = 0)
    // B - abortable (aborter !== undefined)
    //   1 - with timeout
    //   2 - without timeout (timeout = 0)

    if (aborter === undefined) {
        if (timeout !== 0) {
            // A1
            // create a dummy abortcontroller, jsut to take care of timeout.
            const controller = new AbortController();
            timeoutId = setTimeout(() => controller.abort('timedout'), timeout);
            signal = controller.signal;
        }
        // else A2, do nothing special
    } else {
        // Use the abort controller passed in parameter.
        // It can do the timout, or any athoer abort fired by caller.
        if (timeout !== 0) {
            // B1
            timeoutId = setTimeout(() => aborter.abort('timedout'), timeout);
        }

        // B1 & B2
        signal = aborter.signal;
    }
    // Now we just have set up the signal variable to pass to fetch.
    // signal can be undefined, it is OK.

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let response: any;

    try {
        response = await fetch(`${process.env.REACT_APP_URL_API}/${url}`, {
            headers,
            method: 'PUT',
            credentials: 'include',
            body: body ? JSON.stringify(body) : undefined,
            signal, // Might be undefined, it is ok.
        });
        if (timeout !== undefined) clearTimeout(timeoutId);

        const text = await response.text();
        const parsed = text.length ? JSON.parse(text) : undefined;

        if (!response.ok) {
            // Si le code de statut n'est pas dans la plage [200, 299]
            if (response.status === 401) {
                // Le statut est 401 (Unauthorized)
                // Vous pouvez ici gérer la déconnexion de l'utilisateur
                // Par exemple, rediriger vers la page de login ou afficher un message d'erreur
                window.location.href = ROUTE_LOGIN;
            }
            throw await parsed;
        } else {
            if (response.status === 204) {
                return response;
            } else {
                return await parsed;
            }
        }
    } catch (error) {
        if (timeout !== undefined) clearTimeout(timeoutId);
        // In cas of crash,
        // If error is a DOMException, then it is probably an abort
        if (error instanceof DOMException) {
            const domEx = error as DOMException;

            if (domEx.name === 'AbortError') {
                // retrieve aborting reason and message.
                const aborted = aborter && aborter.signal.aborted;
                const status = aborted ? aborter.signal.reason : 'unknown';
                const message = aborted ? ArbortReasonDefs[status as AbortReason] : '';

                const abortError = {
                    status,
                    response: { message },
                    statusCode: status,
                };
                console.log('abortError + converted error = ' + JSON.stringify(abortError, null, 2));

                throw abortError;
            }
        }
        // Else normal throw.
        throw error;
    } finally {
        // clear timer
        if (timeout !== undefined) clearTimeout(timeoutId);
    }
};

export const postJsonData = async (url: string, body?: any, timeout: number = 0, aborter: AbortController | undefined = undefined, pushLoginScreen = true) => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    let signal: AbortSignal | undefined = undefined;

    // 4 cases :
    // A - non-abortable (undefined)
    //   1 - with timeout
    //   2 - without timeout (timeout = 0)
    // B - abortable (aborter !== undefined)
    //   1 - with timeout
    //   2 - without timeout (timeout = 0)

    if (aborter === undefined) {
        if (timeout !== 0) {
            // A1
            // create a dummy abortcontroller, jsut to take care of timeout.
            const controller = new AbortController();
            timeoutId = setTimeout(() => controller.abort('timedout'), timeout);
            signal = controller.signal;
        }
        // else A2, do nothing special
    } else {
        // Use the abort controller passed in parameter.
        // It can do the timout, or any athoer abort fired by caller.
        if (timeout !== 0) {
            // B1
            timeoutId = setTimeout(() => aborter.abort('timedout'), timeout);
        }

        // B1 & B2
        signal = aborter.signal;
    }
    // Now we just have set up the signal variable to pass to fetch.
    // signal can be undefined, it is OK.

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let response: any;

    try {
        response = await fetch(`${process.env.REACT_APP_URL_API}/${url}`, {
            headers,
            method: 'POST',
            credentials: 'include',
            body: body ? JSON.stringify(body) : undefined,
            signal, // Might be undefined, it is ok.
        });
        if (timeout !== undefined) clearTimeout(timeoutId);

        const text = await response.text();
        const parsed = text.length ? JSON.parse(text) : undefined;

        if (!response.ok) {
            // Si le code de statut n'est pas dans la plage [200, 299]
            if (response.status === 401 && pushLoginScreen) {
                // Le statut est 401 (Unauthorized)
                // Vous pouvez ici gérer la déconnexion de l'utilisateur
                // Par exemple, rediriger vers la page de login ou afficher un message d'erreur
                // (sauf pour la page de login sur laquelle on ne veut pas revenir en boucle. d'ou le pushLoginScreeen)
                window.location.href = ROUTE_LOGIN;
            }
            throw parsed;
        } else {
            if (response.status === 204) {
                return response;
            } else {
                return parsed;
            }
        }
    } catch (error) {
        if (timeout !== undefined) clearTimeout(timeoutId);
        // In cas of crash,
        // If error is a DOMException, then it is probably an abort
        if (error instanceof DOMException) {
            const domEx = error as DOMException;

            if (domEx.name === 'AbortError') {
                // retrieve aborting reason and message.
                const aborted = aborter && aborter.signal.aborted;
                const status = aborted ? aborter.signal.reason : 'unknown';
                const message = aborted ? ArbortReasonDefs[status as AbortReason] : '';

                const abortError = {
                    status,
                    response: { message },
                    statusCode: status,
                };
                console.log('abortError + converted error = ' + JSON.stringify(abortError, null, 2));

                throw abortError;
            }
        }
        // Else normal throw.
        throw error;
    } finally {
        // clear timer
        if (timeout !== undefined) clearTimeout(timeoutId);
    }
};

//#endregion

//#region v1. Identify, uploadFiles, et autres apsses plat icoll

export type AgentIdentity = {
    nom?: string;
    nom_agent?: string;
    prenom_agent?: string;
    email?: string;
    tel?: string;
    companyName?: string;
    companyAddress?: string;
    companyLogo?: string;
    e_attestation?: boolean;
    code_fournisseur?: string;
};

export const identify = async (agentCode: string, password: string): Promise<AgentIdentity> => {
    try {
        return (await postJsonData(
            `identify?agentCode=${agentCode}&password=${password}`,
            undefined,
            undefined,
            undefined,
            false // To prevent go to login screen in case of 401
        )) as AgentIdentity;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export type DpParams = {
    address: string;
    zoom: number;
    width: number;
    height: number;
    parcel: string | undefined;
    threshold: number | undefined;
};

export const dpMap = async (params: DpParams) => {
    const response = await fetch(
        `${process.env.REACT_APP_URL_API}/dp?address=${params.address}&zoom=${params.zoom}&width=${params.width}&height=${params.height}&${
            params.parcel ? `parcel=${params.parcel}` : ``
        }&${params.threshold ? `threshold=${params.threshold}` : ``}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }
    );
    if (response.ok) return await response.json();
    else throw await response.json();
};

export const dpToIcoll = async (data: DpPicturePayload) => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/dp/send`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: data ? JSON.stringify(data) : undefined,
    });

    return await response;
};

/**
 * Permet d'obtenir une liste d'option spécifique pour un menu déroulant.
 * Ces option viennent du serveur icoll.
 * Il faut être loggé.
 * la liste obtenue peut être utilsiée directement comme source de données d'un menu déroulant classique
 */
export const getListe = async (listname: string): Promise<Array<LabelledString>> => {
    const response = await fetch(`${process.env.REACT_APP_URL_API}/tech/icolllist?listname=${listname}`, {
        credentials: 'include',
        method: 'GET',
    });

    const list: Array<LabelledString> = await response.json();

    return list;
};

//#endregion

//#region  devis, package routes

export type DevisInput = {
    auditId: number;
    packages: PackagesEntries;
};

export type PackagesEntries = Array<PackageEntry>;

export type PackageEntry = {
    package_id: string;
    produits: Array<ProduitEntry>;
};

export type ProduitEntry = {
    product_id: number;
    quantite: string;
    surface: string;
};

export const simulateDevis = async (auditId: number, packages: PackagesEntries): Promise<Devis> => {
    let response = await postJsonData(`preco/devis/simulate`, { auditId, packages });
    if (!response) throw response;
    return response as Devis;
};

export const validateDevis = async (flowId: string, packages: PackagesEntries, themes: Array<Theme>): Promise<string> => {
    let response = await putJsonData(`flow/${flowId}/preco/devis/validate`, { packages, themes });
    if (!response || !response.devisId) throw response;
    return response.devisId;
};
// OLD WAY
// export const validateDevis = async (flowId: string, packages: PackagesEntries): Promise<string> => {
//     let response = await postJsonData(`flow/${flowId}/audit`, { flowId, packages });
//     if (!response || !response.devisId) throw response;
//     return response.devisId;
// };

// here, devis is the content of the object (AGENT_CODE)-SIM stored in local storage
export const updateDevis = async (flowId: string, devis: any): Promise<string> => {
    let response = await putJsonData(`flow/${flowId}/preco/devis`, { sim: devis });
    if (!response || !response.devis) throw response;
    return response.devis;
};
// here, devis is the content of the object (AGENT_CODE)-SIM stored in local storage
export const updateFlowComment = async (flowId: string, comment: string): Promise<void> => {
    await putJsonData(`flow/${flowId}/comment`, { comment });
};
export type AideOutput = {
    titre: string;
    product_name: string;
    product_id: string;
    montant: string;
};

export type Devis = {
    client: {
        zone_climatique: string;
        cat_revenu: string;
    };
    logement: {
        type_residence: string;
        date_achevement_maison: string;
        logement_neuf: string;
    };
    unformatted: {
        client_id: string;
        cat_revenu_slug: string;
    };
    aides: {
        montant_aides_cumul: string;
    };
    'aides-details': Array<AideOutput>;
    total: {
        reste_a_charge: string;
    };
    devis: {
        montant_vente_ht_unformatted: number;
        montant_vente_tva_unformatted: number;
        montant_vente_unformatted: number;
        montant_vente_ht: string;
        montant_vente_tva: string;
        montant_vente: string;
    };
};

export type EnergyLossInput = {
    levels: number; //1 | 2 | 3;
    year: number;
    surfaceHab: number;
    departmentId: string;
    consigneTemperature: number;
    altitude: number;
    murs: boolean;
    combles: boolean;
    plancher: boolean;
    doubleVitrage: boolean;
    hygroreglable: boolean;
};
export type EnergyLossOutput = {
    temperatureBase: number;
    coefficientDerperdition: number;
    deperditions: number;
};
export const getEnergyLoss = async (input: EnergyLossInput): Promise<EnergyLossOutput> => {
    let response = await postJsonData(`preco/energy-loss`, input);
    if (!response) throw response;
    return response;
};
export const exctractAndCheckEnergyLossInfo = (audit: any): EnergyLossInput | undefined => {
    if (!audit || !audit.SHab) return undefined;
    if (!audit.heaterFeature) return undefined;

    const surfaceHab = +audit.SHab?.value;
    const year = +audit.houseAge?.value;

    const floorCount: number = getLevelCount(audit, 'horsSSavecCA');
    // Attention, le nombre de level varie entre appell algorithme cardonnel et affichage dans le pdf (ici on est borné à 3.)
    const levels = floorCount > 3 ? 3 : floorCount < 1 ? 1 : floorCount;

    const departmentId = audit.department.value.slice(0, 2);
    const consigneTemperature = +audit.temperatureTarget.value;
    const altitude = +audit.altitude.value;

    // si les murs sont isolés
    const murs = !(
        audit.exteriorWallInsulationPresence === undefined ||
        audit.exteriorWallInsulationPresence.value === false ||
        audit.exteriorWallInsulationThickness === undefined ||
        +audit.exteriorWallInsulationThickness.value < 12
    );
    // si les combles sont isolés
    const combles = !(
        audit.ceilingInsulationPresence === undefined ||
        audit.ceilingInsulationPresence.value === false ||
        audit.ceilingInsulationThickness === undefined ||
        +audit.ceilingInsulationThickness.value < 20
    );
    // si les plancher sont isolés
    const plancher = !(
        audit.floorType === undefined ||
        audit.floorInsulationPresence === undefined ||
        ((+audit.floorType.value === 2 || +audit.floorType.value === 3) &&
            // ET que les planchers ne sont pas isolés
            audit.floorInsulationPresence !== undefined &&
            audit.floorInsulationPresence.value === false)
    );
    // si les double vitrage
    const doubleVitrage = +audit.buildingWindows1glazing.value > 0;
    // si la VMC est performante.
    const hygroreglable = audit.ventilationType?.value_label?.includes('hygroréglable') || audit.ventilationType?.value_label?.includes('Double');

    return { levels, year, surfaceHab, departmentId, consigneTemperature, altitude, murs, combles, plancher, doubleVitrage, hygroreglable };
};

//#endregion

//#region audit, RDV, AL, RAC

export const createAuditFlow = async () => {
    try {
        const auditType = storageService.getCurrentAuditType();
        if (!auditType || !isAuditType(auditType)) {
            throw new Error("Impossible de déterminer le type d'audit");
        }
        // const auditJson = storageService.stepListToAuditAndClient();
        const id = await createFlow(auditType);
        localStorage.setItem('flowId', id);
        localStorage.setItem('flowState', 'Created');
    } catch (error) {
        console.error('createAuditFlow' + error);
        throw error;
    }
};

/**
 * Fonction pour envoyer les données de l'audit
 * @param data (Données de l'Audit)
 */
const updateAuditData = async (flowId: string, data: any) => {
    return await putJsonData(`flow/${flowId}/audit`, data);
};

export const updateAuditDataService = async () => {
    try {
        const clientIdentity = storageService.getClientIdentity();
        const auditLocalStorage = storageService.getAudit();

        // const auditJson = storageService.stepListToAuditAndClient();
        const flowId = localStorage.getItem('flowId');

        if (!flowId) {
            throw new Error("Impossible d'envoyer les données de l'audit, parcours utilisateur inexistant");
        }
        // Lancement de la requête
        return updateAuditData(flowId, { clientIdentity, audit: auditLocalStorage });
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * Fonction pour envoyer les données de l'audit
 * @param data (Données de l'Audit)
 */
export const sendAudit = async (flowId: string) => {
    const result = await putJsonData(`flow/${flowId}/audit/send-audit`);
    const auditId = result?.data?.auditId;
    localStorage.setItem('auditId', auditId);
    return result;
};
// OLD WAY
// export const postAuditData = async (data: any) => {
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');

//     let response: any;

//     response = await fetch(`${process.env.REACT_APP_URL_API}/audit`, {
//         headers,
//         method: 'POST',
//         credentials: 'include',
//         body: JSON.stringify(data),
//     });

//     if (!response.ok) {
//         throw await response.json();
//     } else {
//         const result = await response.json();
//         const auditId = result?.response?.data?.auditId;
//         localStorage.setItem('auditId', auditId);

//         return result;
//     }
// };

/**
 * Fonction pour envoyer les données du RDV
 * @param data (Données de RDV)
 */
export const requestRdvNoSignature = async (flowId: string, data: any) => {
    return await putJsonData(`flow/${flowId}/audit/request-rdv-no-sig`, data);
};
// OLD WAY
// export const postRdvData = async (data: any) => {
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');

//     let response: any;

//     response = await fetch(`${process.env.REACT_APP_URL_API}/rdvNoSig`, {
//         headers,
//         method: 'POST',
//         credentials: 'include',
//         body: JSON.stringify(data),
//     });

//     return await response.json();
// };

/**
 * Fonction pour envoyer els donénes de l'audit ET du RDV.
 * @param data
 * @returns
 */
export const requestRdv = async (flowId: string, data: any) => {
    return await putJsonData(`flow/${flowId}/audit/request-rdv`, data);
};
// OLD WAY
// export const postAuditAndRdv = async (data: any) => {
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');

//     const response = await fetch(`${process.env.REACT_APP_URL_API}/audit-sign-rdv`, {
//         headers,
//         method: 'POST',
//         credentials: 'include',
//         body: JSON.stringify(data),
//     });
//     const json = await response.json();
//     if (response.status !== 200 && response.status !== 201) throw Error(json);

//     return json;
// };

export const validateRdv = async (flowId: string): Promise<AppointmentOutput> => {
    return await putJsonData(`flow/${flowId}/audit/rdv-signed`);
};

/**
 * Fonction pour envoyer les données du RDV
 * @param data (Données de RDV)
 */
export const postActionLogementRecapData = async (data: any) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let response: any;

    response = await fetch(`${process.env.REACT_APP_URL_API}/action-logement/recap`, {
        headers,
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
    });

    return await response.json();
};

/**
 * Fonction pour envoyer les données du RDV
 * @param data (Données de RDV)
 */
export const postActionLogementValidateData = async (data: any) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let response: any;

    response = await fetch(`${process.env.REACT_APP_URL_API}/action-logement/validate`, {
        headers,
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
    });

    return await response.json();
};

//#endregion

//#region config files

// Récupère un certains nombre de fichiers de configuration et les stockes dans le local storage du navigateur.
// Ces fichiers sont lu du serveur directement par appels des routes associées.
export const getAllConfigFiles = async () => {
    let response;

    response = await getData('simulator/energyprices');
    localStorage.setItem('energyprices', JSON.stringify(response));

    response = await getData('simulator/photovoltaiqueconst');
    localStorage.setItem('constantesPhotovoltaiques', JSON.stringify(response));

    response = await getData('simulator/sunshines');
    localStorage.setItem('sunshines', JSON.stringify(response));

    response = await getData('simulator/sunshinesyield');
    localStorage.setItem('sunshinesyield', JSON.stringify(response));

    response = await getData('simulator/indexations');
    localStorage.setItem('indexations', JSON.stringify(response));

    response = await getData('simulator/recommendationsChauffage');
    localStorage.setItem('preconisationsChauffage', JSON.stringify(response));

    response = (await getData('investment-bonus/today')).elements;
    localStorage.setItem('investmentBonus', JSON.stringify(response));

    response = await getData(`simulator/constant/customerFlowParameters`);
    localStorage.setItem('customerFlowParameters', JSON.stringify(response));

    // Financo
    try {
        response = await getData('funding/financo/scales');
        localStorage.setItem('financoScales', JSON.stringify(response));
    } catch (ex) {
        // nothing to do. we are fucked !
    }
};

export const areAllConfigFilesReady = (): boolean => {
    if (localStorage.getItem('energyprices') === null) return false;
    if (localStorage.getItem('constantesPhotovoltaiques') === null) return false;
    if (localStorage.getItem('sunshines') === null) return false;
    if (localStorage.getItem('sunshinesyield') === null) return false;
    if (localStorage.getItem('indexations') === null) return false;
    if (localStorage.getItem('preconisationsChauffage') === null) return false;
    if (localStorage.getItem('financoScales') === null) return false;
    if (localStorage.getItem('investmentBonus') === null) return false;
    if (localStorage.getItem('customerFlowParameters') === null) return false;
    return true;
};

//#endregion

//#region get investment bonus, and const

export const bonusTypeName = ['TarifAchat', 'PrimeInvestissement', 'PrimePaysagere'] as const;
export type BonusType = (typeof bonusTypeName)[number];

export const SliceTypeKeys = { Ta: 'Ta', Tb: 'Tb', Tc: 'Tc', Pa: 'Pa', Pb: 'Pb', Pc: 'Pc' } as const;

export type SliceType = (typeof SliceTypeKeys)[keyof typeof SliceTypeKeys];
export interface AmountPerSlice {
    thresholdMin: number;
    thresholdMax: number;
    amount: number;
    durationThreshold?: number;
    amountAfterThreshold?: number;
    type?: SliceType;
    name?: string;
}

export type InvestmentBonus = {
    id: string;
    startDate: string;
    endDate: string;
    name: string;
    type: BonusType;
    consumptionMode: ModeConsommation;
    slices: Array<AmountPerSlice>;
};

export const getAideBonus = async (power: number, mode: ModeConsommation): Promise<AmountPerSlice | undefined> => {
    let response;
    try {
        response = await getData(`investment-bonus/investment/?power=${power}&consumptionMode=${mode}`);
    } catch (ex) {
        console.log(`invalide slice in investment-bonus/investment/?power=${power}&consumptionMode=${mode}`);
        return undefined;
    }
    return response as AmountPerSlice;
};

export const getTarifRachatBonus = async (power: number, mode: ModeConsommation): Promise<AmountPerSlice | undefined> => {
    let response;
    try {
        response = await getData(`investment-bonus/achat/?power=${power}&consumptionMode=${mode}`);
    } catch (ex) {
        console.log(`invalide slice in investment-bonus/achat/?power=${power}&consumptionMode=${mode}`);
        return undefined;
    }
    return response as AmountPerSlice;
};

//#endregion

// #region route liée à la génération des pdf bdc et types associés.

type bon_de_commande = { docName: 'bon_de_commande'; parameters: undefined };
type cgv = { docName: 'cgv'; parameters: undefined };

type devis = { docName: 'devis'; parameters: undefined };
type cgv_devis = { docName: 'cgv_devis'; parameters: undefined };

export type annexe_bdc = {
    docName: 'annexe_bdc';
    parameters: {
        quantite_1?: string;
        designation_1?: string;
        quantite_2?: string;
        designation_2?: string;
        quantite_3?: string;
        designation_3?: string;
        quantite_4?: string;
        designation_4?: string;
        quantite_5?: string;
        designation_5?: string;
        quantite_6?: string;
        designation_6?: string;
        quantite_7?: string;
        designation_7?: string;
    };
};

type financement_sogys = {
    docName: 'financement_sogys';
    parameters: {
        montant_vente: string;
        montant_vente_unformatted: string;
        type_financement: string;
        montant_acompte: string;
        montant_solde: string;
        organisme_financier: string;
        montant_credit_total: string;
        montant_credit_plus_interets: string;
        report_credit: string;
        taux_deb_fixe: string;
        taeg_credit: string;
        nb_echeances: string;
        mt_echeances: string;
    };
};

type assurance_mma = {
    docName: 'assurance_mma';
    parameters: {
        num_contrat_habitation: string;
        compagnie_assurance_nom: string;
        id_exposition: string;
        id_inclinaison: string;
        pv_coefficient_ombrage: string;
        pv_puissance_installation: string;
        cotisation_puissance: string;
    };
};

type contrat_pub = {
    docName: 'contrat_pub';
    parameters: { montant_contrat_pub_chiffre: string; client_date_naissance: string; client_lieu_naissance: string; client_nationalite: string };
};

type engagement_client_sogys = { docName: 'engagement_client_sogys'; parameters: undefined };

type simulation = {
    docName: 'simulation';
    parameters: {
        pv_ensoleillement_annuel: string;
        id_exposition: string;
        id_inclinaison: string;
        pv_coef_orientation_inclinaison: string;
        pv_puissance_installation: string;
        pv_efficacite_installation: string;
        pv_production_escomptee: string;
        pv_tarif_base: string;
    };
};
type attestation_tva = { docName: 'attestation_tva'; parameters: { tva_55: string; type_residence: string } };
type mandat_enedis = { docName: 'mandat_enedis'; parameters: { coche1: string; coche2: string; coche3: string; coche4: string } };
type mandat_special_client_sogys = { docName: 'mandat_special_client_sogys'; parameters: undefined };
type mandat_anah = { docName: 'mandat_anah'; parameters: {} };

type DocParametersBase =
    | bon_de_commande
    | cgv
    | devis
    | cgv_devis
    | annexe_bdc
    | financement_sogys
    | assurance_mma
    | contrat_pub
    | engagement_client_sogys
    | simulation
    | attestation_tva
    | mandat_enedis
    | mandat_special_client_sogys
    | mandat_anah;

export type DocNames = DocParametersBase['docName'];
export type DocParameterType<T extends DocNames> = Extract<DocParametersBase, { docName: T }>['parameters'];
// redefinit tous les types devis, bon_de_commande ...
export type DocParameters<T extends DocNames = DocNames> = { docName: T; parameters: DocParameterType<DocNames> };

//export type XX = DocParameterType<'mandat_enedis'>;

export type PdfOutput<T extends DocNames = DocNames> = {
    simu_id: string;
    doc_name: T;
    doc_lb: string;
    filePath: string;
};
export const DOWNLOAD_TIME_OUT = 120; // old value was 60

export const createPdf = async <T extends DocNames>(
    auditId: number,
    parameters: DocParameters<T>,
    timeout: number = 0,
    aborter: AbortController | undefined = undefined
): Promise<PdfOutput<T>> => {
    //console.time('create pdf ' + parameters.docName);
    //console.log('create pdf ' + parameters.docName + (timeout === 0 ? '' : '  WITH TIMEOUT ' + timeout));

    let response = await postJsonData(`preco/createpdf`, { auditId, ...parameters }, timeout, aborter);
    if (response.status !== 'Created') {
        console.timeEnd('create pdf ' + parameters.docName);
        throw response.response;
    }
    const devis: PdfOutput<T> = response.response.data as PdfOutput<T>;
    //console.timeEnd('create pdf ' + parameters.docName);

    return devis;
};

export type SignOutput = {
    // clientId: string;
    accessToken: string;
};

// return access token to be passed to Netheos sign service
export const signDocs = async (flowId: string, timeout = 0, aborter: AbortController | undefined = undefined): Promise<SignOutput> => {
    let response = await putJsonData(`flow/${flowId}/preco/request-command-sign`, undefined, timeout, aborter);
    if (!response) throw response;

    return response as SignOutput;
};
// OLD WAY
// export const signDocs = async (auditId: number, timeout = 0, aborter: AbortController | undefined = undefined): Promise<SignOutput> => {
//     let response = await postJsonData(`preco/sign`, { auditId }, timeout, aborter);
//     if (response.status !== 'Created') throw response.response;
//     const output: SignOutput = response.response.data as SignOutput;
//     return output;
// };

export const rejectDocs = async (flowId: string): Promise<void> => {
    await putJsonData(`flow/${flowId}/preco/reject-command-sign`);
};
// OLD WAY
// export const rejectDocs = async (auditId: number): Promise<void> => {
//     let response = await postJsonData(`preco/sign/rejected`, { auditId });
//     if (response.status !== 'OK') throw response.response;
// };

export const commandSigned = async (flowId: string): Promise<void> => {
    await putJsonData(`flow/${flowId}/preco/command-signed`);
};

export const notifyDP = async (id: number): Promise<void> => {
    await postJsonData(`dp/send-mail`, { auditId: id });
};

export const sendPartnerMail = async (email: string): Promise<void> => {
    let response = await postJsonData('simulator/send-partner-mail', { email });
    if (response.status !== 'CREATED') throw response.response;
};

type SendBackMmaContractInput = {
    unsignedMmaUrl: string;
    unsignedSimulationUrl: string;
    auditId: number;
};

export const sendBackMmaContract = async (input: SendBackMmaContractInput): Promise<void> => {
    await postJsonData('preco/sendback-mma', input);
};

type SendSignedOrderInput = {
    unsignedBonDeCommandeUrl: string;
    unsignedFinancementSogysUrl: string;
    financoId: string;
    auditId: number;
};

export const sendSignedOrder = async (input: SendSignedOrderInput): Promise<void> => {
    await postJsonData('preco/send-signed-order', input);
};

// export const sendRenovationGlobale = async (input: ClientIdentity): Promise<void> => {
//     await postJsonData('preco/send-reno-globale', input);
// };

export const sendRenovationAmpleur = async (flowId: string, renoDAmpleur: RenovationDAmpleurParams): Promise<Partial<RenovationDAmpleurParams>> => {
    const response = await postJsonData(`flow/${flowId}/create-dra`, renoDAmpleur);
    if (!response) throw response;
    return response as Partial<RenovationDAmpleurParams>;
};

//#endregion

//#region route et types concernant le fichier PDF nommé DEVIS.

const devisPdfTypeNames = ['Early', 'Simple', 'Complete'] as const;
export type DevisPdfType = (typeof devisPdfTypeNames)[number];

export type SimulationSimple = {
    avant: {
        parEnergie: { gaz: string; electricite: string; fioul: string; bois: string; total: string };
        parPoste: {
            prixChauffage: string;
            ratioChauffage: string;
            economieChauffage: string;
            prixEauChaude: string;
            ratioEauChaude: string;
            economieEauChaude: string;
            prixAutres: string;
            ratioElectricite: string;
            economieElectricite: string;
        };
        energies: { primaire: string; finale: string; primaireParSurface: string };
        equipements: {
            equipements: string[];
            energies: Array<string>;
            consosHorsAbo: Array<{ energie: string; prix: string }>;
            abos: Array<{ energie: string; prix: string }>;
            consos: Array<{ energie: string; prix: string }>;
        };
    };
    apres: {
        parEnergie: { gaz: string; electricite: string; fioul: string; bois: string; total: string; premiereAnnee: string };
        parPoste: {
            prixChauffage: string;
            ratioChauffage: string;
            economieChauffage: string;
            prixEauChaude: string;
            ratioEauChaude: string;
            economieEauChaude: string;
            prixAutres: string;
            ratioElectricite: string;
            economieElectricite: string;
        };
        energies: {
            primaire: string;
            primaireEconomie: string;
            finale: string;
            finaleEconomie: string;
            primaireParSurface: string;
            primaireParSurfaceEconomie: string;
        };
        economie: { moyenneAnnuelle: string; sur25ans: string; taux: string };
    };
};

export type SynthesePreco = {
    avant: {
        chauffage: { prix: string; energie: string; energieName: string; ratio: string };
        eauchaude: { prix: string; energie: string; energieName: string; ratio: string };
        menager: { prix: string; energie: string; energieName: string; ratio: string };
        abonnements: { electricite: string; gaz: string };
        total: string;
    };
    apres: {
        economie: string;
        chauffage: { prix: string; energie: string; energieName: string; ratio: string };
        eauchaude: { prix: string; energie: string; energieName: string; ratio: string };
        menager: { prix: string; energie: string; energieName: string; ratio: string };
        abonnements: { electricite: string; gaz: string };
        total: string;
    };
    synthese: { indep: string; eco25: string; rendement: string };
    equipements: {
        equipements: string[];
        energies: Array<string>;
        consosHorsAbo: Array<{ energie: string; prix: string }>;
        abos: Array<{ energie: string; prix: string }>;
        consos: Array<{ energie: string; prix: string }>;
    };
};

export type SynthesePV = {
    puissanceRecommandee: string;
    production: string;
    independance: string;
    investissement: string;
    reventeSurplus: string;
    autoconso: string;
    tauxAutoconomation: string;
    economieGlobale25ans: string;
    aide: string;
};

type CreateDevisInput = {
    clientIdentity: ClientIdentity;
    simulationSimple: SimulationSimple;
    synthesePreco: SynthesePreco;
    synthesePv: SynthesePV | undefined;
    projectionFinanciere: StringifiedProjectionFinanciere | undefined;
    equipements: Array<string>;
    fichesTechniques: Array<string>;
    ticket: TicketData;
    selectedPackages: Array<PackageData>;
    audit?: any;
    themes?: Array<Theme>;
    icollDevis?: Devis;
};

export const createDevisPdf = async (devisPdfType: DevisPdfType, auditId: number, quotation: CreateDevisInput) => {
    const flowId = localStorage.getItem('flowId');

    if (!flowId) {
        throw new Error('Impossible de créer devis pdf dans createDevisPdf, parcours utilisateur inexistant');
    }

    let response = await putJsonData(`flow/${flowId}/preco/quotation`, { type: devisPdfType, auditId, email: quotation.clientIdentity.userEmail, quotation });

    return response.url;
};

//#endregion

export type FlowFunding = {
    type?: FundingType;
    url?: string;
    organism?: string;
    data?: Record<string, unknown>;
    state?: string;
};

export type FlowFundingOutput = {
    fundingType?: FundingType | null;
    fundingUrl?: string | null;
    fundingOrganism?: string | null;
    funding?: Record<string, unknown> | null;
    fundingState?: string | null;
};

export const updateFundingData = async (flowId: string, data: FlowFunding): Promise<FlowFundingOutput> => {
    const result: FlowFundingOutput = await putJsonData(`flow/${flowId}/funding`, data);
    storageService.setFunding(result);

    return result;
};

export const getFundingData = async (flowId: string): Promise<FlowFundingOutput> => {
    return await getData(`flow/${flowId}/funding`);
};

//#region route et type concernant les prévisites.

export type NoteDeDimensionnement = Partial<{
    // Part top
    address: string;
    postalCode: string;
    city: string;
    altitude: string;

    // Part bottom
    constructionDate: string;
    thermalRegulation: string;
    setTemperature: string;
    baseTemperature: string;
    habitableSurface: string;
    houseWidth: string;
    houseLength: string;
    numberOfLevels: string;
    wallSurface: string;
    wallInsulation: string;
    roofInsulation: string;
    floorInsulation: string;
    humidityControlledVentilation: string;
    doubleTripleGlazing: string;
    heatingLinkedHotWaterProduction: string;
    heatLossCoefficient: string;
    besoinPuissanceChauffage: string;
    totalHeatLoss: string;
    recommendedHeatPumpPower: string;
}>;

export const updatePrevisitData = async (flowId: string, data: Record<string, unknown>) => {
    return await putJsonData(`flow/${flowId}/previsit`, { data });
};
// OLD WAY
// export const createPrevisit = async (auditId: number, devisId: number, data: Record<string, unknown>): Promise<any> => {
//     return await postJsonData('previsit', { auditId, devisId, data });
// };

export type AppointmentInput = {
    date: Date | string;
    latitude: number;
    longitude: number;
    address: string;
    installer?: Labelled<unknown>;
};

export const createInstallationAppointment = async (flowId: string, installationAppointment: AppointmentInput) => {
    return await putJsonData(`flow/${flowId}/installation/appointment`, installationAppointment);
};
// OLD WAY
// export const createInstallationAppointment = async (id: string, installationAppointment: Appointment): Promise<any> => {
//     return await postJsonData(`previsit/${id}/installation-appointment`, installationAppointment);
// };

// à implémenter
export const withoutInstallationAppointment = async (flowId: string) => {
    return await putJsonData(`flow/${flowId}/installation/no-appointment`);
};

// à implémenter
export const getInstallerList = async (): Promise<Array<Labelled<number>>> => {
    return await getData(`preco/installer-list`);
};

export const getAppointmentSlots = async (): Promise<Array<Date>> => {
    const datesStr: Array<string> = await getData(`flow/installation/available-appointments`);
    const dates = datesStr.map((date: string) => {
        return new Date(date);
    });

    return dates;
};
// OLD WAY
// export const getAppointmentSlots = async (): Promise<Array<Date>> => {
//     const datesStr: Array<string> = await getData(`previsit/available-appointment`);
//     const dates = datesStr.map((date: string) => {
//         return new Date(date);
//     });

//     return dates;
// };

export const validatePrevisit = async (flowId: string, additionalData: Record<string, unknown>): Promise<string> => {
    let response: { url: string } = await putJsonData(`flow/${flowId}/previsit/validate`, additionalData);
    if (!response) throw response;
    return response.url;
};
// OLD WAY
// export const postPrevisitPdf = async (id: string, additionalData: Record<string, unknown>): Promise<string> => {
//     let response: { url: string } = await postJsonData(`previsit/${id}/pdf`, additionalData);
//     if (!response) throw response;
//     return response.url;
// };

// #endregion

// #region financo

export type FinancoScale = {
    id: string;
    latest: boolean;
    valid: boolean;
    balloonScale: boolean;
    scaleWording: string;
    productCode: string;
    numberOfPaymentsMin: number;
    numberOfPaymentsMax: number;
    monthlyPaymentMin: number;
    qualifierCode: string;
    monthlyPaymentMax: number;
    creditAmountMin: number;
    creditAmountMax: number;
    creditDepositPercentMin: number;
    startValidityDate: string;
    endValidityDate: string;
    paymentPostponements: Array<number>;
    marketCodes: Array<string>;
    rateType: string;
    splitUnblocking: boolean;
    allowAdditionnalInsurance: boolean;
    defaultScale: boolean;
};

export type FinancoScales = FinancoScale[];

// Demande de simumation
export type FinancoLoan = {
    loan: {
        scaleId: string;
        depositAmount: string;
        purchaseAmount: string;
        numberOfPayments: string;
        paymentPostponement: number;
        borrowerInsuranceId?: string;
    };
};

export type FinancoInsuranceInput = {
    scaleId: string;
    purchaseAmount: number;
    depositAmount: number;
    numberOfPayments: number;
};
// Retour demande de simulation
export type FinancoSimulation = {
    scaleId: string;
    purchaseAmount: number;
    loanAmount: number;
    depositAmount: number;
    numberOfPayments: number;
    paymentPostponement: number;
    contractPeriod: number;
    fees: number;
    monthlyPaymentInsuranceNotIncluded: number;
    monthlyPaymentInsuranceIncluded: number;
    totalAmountInsuranceNotIncluded: number;
    totalAmountInsuranceIncluded: number;
    annualPercentageRate: number;
    fixedBorrowingRate: number;
    insuranceAnnualPercentageRate: number;
    nominalRate: number;
    totalInterestAmount: number;
    borrowerInsurance?: {
        id: string;
        insuranceMonthlyAmount: number;
        insuranceTotalAmount: number;
        medicalSurvey: boolean;
    };
};

export type FinancoBorrower = {
    identity: {
        /**  Date de naissance du prospect  */
        birthDate: string;
        /** Prénom du prospect*/
        firstName: string;
        /** Nom du prospect*/
        lastName: string;
        /** Genre du prospect (1 Mr, 2 Mme) */
        titleCode: '1' | '2';
        /** complete if titleCode == 2 */
        birthName?: string; // default => lastname
    };
    contactDetails?: {
        cellPhone: string;
        email: string;
        addressLine1?: string;
        city?: string;
        zipCode?: string;
    };
};

export type FinancoStudyInput = FinancoLoan & {
    loan: FinancoLoan;
    borrower: FinancoBorrower;
    capacity: 'PERSONAL';
};

export type FinancoContextStudyInput = Omit<FinancoStudyInput, 'loan'> & {
    loan: FinancoSimulation;
    auditId: number;
    devisId: number;
    email: string;
};

export const getFinancoScales = async (): Promise<FinancoScales> => {
    const scales: FinancoScales = await getData(`funding/financo/scales`);

    return scales;
};

export const getFinancoInsurances = async (insuranceInput: FinancoInsuranceInput): Promise<Array<Labelled<string | undefined>>> => {
    const insurancesDropDown: Array<LabelledString> = await postJsonData(`funding/financo/insurances-dropdown`, insuranceInput);

    return insurancesDropDown;
};

export const postFinancoSimulation = async (loan: FinancoLoan): Promise<FinancoSimulation> => {
    const simu: { loan: FinancoSimulation } = await postJsonData(`funding/financo/study-simulation`, loan);

    return simu.loan;
};

export const postCreateFinancoStudy = async (loanContext: FinancoContextStudyInput): Promise<FinancoStudyStatus> => {
    const study: FinancoStudyStatus = await postJsonData(`funding/financo/study`, loanContext);

    return study;
};

const statusLabelNames = ['ABANDONNEE', 'EN ANALYSE', 'A CONTROLER', 'A FINANCER', 'INCOMPLET', 'DEFAVORABLE', 'EN ANALYSE'] as const;
export type StatusLabel = (typeof statusLabelNames)[number];

export type FinancoStudyStatus = {
    id: string;
    url: string;
    studyInformation: {
        productType: 'loan' | 'leasing';
        scored: boolean;
        statusLabel: StatusLabel;
    };
};

// #endregion

// #region myself

export const civilityNames = ['MR', 'MISS', 'MRS', 'OTHER', 'UNKNOWN'] as const;

export type Civility = (typeof civilityNames)[number];

export const knownUserDocType = ['certif_lcc', 'autres'] as const;
export type KnownUserDocType = (typeof knownUserDocType)[number];

export const knownUserDocTypeToString = (type: KnownUserDocType | string): string => {
    switch (type) {
        case 'certif_lcc':
            return 'Certificat LCC';
        case 'autres':
            return 'Autres';
        default:
            return type;
    }
};

export type UserDocOutput = {
    id: string;
    createdAt: string;
    comment: KnownUserDocType | string;
    document: Document;
};

export const userDocOutputToDocumentEntry = (doc: UserDocOutput | undefined): DocumentEntry | undefined => {
    if (!doc) return undefined;
    const title = capitalizeFirstLetter(knownUserDocTypeToString(doc.comment as KnownUserDocType));
    return {
        title,
        comment: title,
        url: doc.document.url,
        extension: doc.document.extension,
    };
};

const sponsorModeNames = ['Normal', 'Pyramidal'] as const;
export type SponsorMode = (typeof sponsorModeNames)[number];
export type UserForManager = {
    id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    agentCode?: string;
    attachedUsers?: Array<UserForManager>;
};
export type SponsorForManager = {
    id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
};
export type Myself = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    email: string;
    sponsorMode: SponsorMode;
    passwordHash: string | null;
    resetPassword: boolean;
    lastSignedInAt: Date | null;
    termsSigned: boolean;
    termsSignedAt: Date | null;
    zipcode: string | null;
    civility: Civility | null;
    lastname: string | null;
    firstname: string | null;
    phoneNumber: string | null;
    birthDate: Date | null;
    fundingEnpowerment?: AllFundingProperties;
    preferences?: UserPreferences;
    attachedUsers?: Array<SubAgent>;
    sponsorships?: Array<SponsorshipAgent>;
    managerCode?: string | null;
    agentCode: string;
    userDocs?: Array<UserDocOutput>;
};

export const formatNetworkDeep = (level: number | undefined): string => {
    if (level === undefined || isNaN(level) || level === Infinity || level === -Infinity || level === 0) return '-';
    return (level < 0 ? 'N' : 'N+') + level.toString();
};

export type SubAgent = {
    id: string;
    createdAt: string;
    disabled: boolean;
    email: string;
    firstname: string;
    lastname: string;
    agentCode: string;
    address?: string | null;
    zipcode?: string | null;
    city?: string | null;
    phoneNumber?: string;
    company?: {
        address: string;
        city: string;
        email: string;
        id: string;
        legalType: string;
        name: string;
        siret: string;
        zipCode: string;
    };
    attachedUsers?: Array<SubAgent>;
    networkDeep?: number;
    manager?: {
        id: string;
        firstname: string;
        lastname: string;
        networkDeep?: number;
    };
};

export type SponsorshipAgent = {
    id: string;
    createdAt: string;
    email: string;
    firstname: string;
    lastname: string;
    sponsorState: 'Created' | 'Validated';
};

export type SubAgentList = PaginatedResponse<SubAgent>;
export type SponsorshipAgentList = PaginatedResponse<SponsorshipAgent>;

export type UserPreferences = Record<string, unknown> & {
    themeFilter?: FilterThemePreference;
};

export type NoFundingProperties = {
    type: 'None';
    hasLcc: false;
};

export type FinancoFundingProperties = {
    type: 'Financo';
    hasLcc: boolean;
    fundingOrganismProperties: {
        sellersId: string;
    };
};

export type AllFundingProperties = NoFundingProperties | FinancoFundingProperties;

export const getMyself = async (): Promise<Myself> => {
    const myself: Myself = await getData(`myself`);

    return myself;
};
/**
 * sub agents without manager
 * @returns
 */
export const getSubAgents = async (): Promise<SubAgentList> => {
    const subagent: SubAgentList = await getData(`myself/subagents`);

    return subagent;
};
/**
 * sub agents including manager if exist
 * @returns
 */
export const getNetwork = async (): Promise<SubAgentList> => {
    const subagent: SubAgentList = await getData(`myself/network`);

    return subagent;
};

export const getSubAgent = async (id: string): Promise<SubAgent> => {
    const subagent: SubAgent = await getData(`myself/subagents/${id}`);

    return subagent;
};

export const getSponsorships = async (): Promise<SponsorshipAgentList> => {
    const subagentList: SponsorshipAgentList = await getData(`myself/sponsorships`);

    return subagentList;
};

export const getSponsorship = async (id: string): Promise<SponsorshipAgent> => {
    const sponsorship: SponsorshipAgent = await getData(`myself/sponsorships/${id}`);

    return sponsorship;
};

export const setMyselfFundingProperties = async (agentCode: string, fundingProps: AllFundingProperties): Promise<void> => {
    await putJsonData(`myself/funding`, fundingProps);
};

export const setMyselfPref = async (agentCode: string, prefs: Record<string, unknown>): Promise<void> => {
    await putJsonData(`myself/pref`, prefs);
};

export type InviteNewAgentInput = {
    civility: Civility;
    lastname: string;
    firstname: string;
    phoneNumber: string;
    email: string;

    country?: string;
    address: string;
    zipcode: string;
    city: string;

    birthPlace?: string;
    birthDate?: string;
    birthCountry?: string;
    nationality?: string;

    identityDocument1Id?: string;
    // identityDocument2Id?: string;
};

export const inviteNewAgent = async (newAgent: InviteNewAgentInput): Promise<SponsorshipAgent> => {
    const user: SponsorshipAgent = await postJsonData(`myself/sponsorship`, newAgent);

    return user;
};

// Documents
export const associateMyselfDocument = async (documentId: string, comment: string): Promise<UserDocOutput> => {
    return await postJsonData(`myself/associate-doc`, { documentId, comment });
};

export const deleteMyselfDocument = async (id: string): Promise<void> => {
    await deleteData(`myself/associate-doc/${id}`);
};

// export const getUserDocuments = async (): Promise<Array<UserDocOutput>> => {
//     return await getData(`myself/associated-docs`);
// };

// #endregion

//#region constant

export type ConstantesPhotovoltaique = {
    tauxAutoconsommationMax_B2C: number; // utilisé partout.
    efficacite: number;
    efficaciteMicroOndulee: number;
};

export type EnergyType = 'fuel' | 'wood' | 'electricity' | 'gaz';

export type EnergyPriceProjection = {
    type: EnergyType;
    year: number;
    unitPrice: number;
};

export const getEnergyPriceProjection = async (): Promise<Array<EnergyPriceProjection>> => {
    return await getData(`simulator/constant/energyPriceProjection`);
};

export type CustomerFlowParameters = {
    operationsCommerciales: {
        remiseMMA: boolean;
        remisePrevisite: number;
    };
};

//#endregion

//#region municipality
export type AddressProperties = {
    id: string;
    type: string;
    housenumber: string;
    street: string;
    locality: string;
    municipality: string;
    score: number;
    housenumber2?: string;
    street2?: string;
    name: string;
    postcode: string;
    citycode: string;
    city: string;
    district: string;
    oldcitycode?: string;
    oldcity?: string;
    context: string;
    label: string;
    x: number;
    y: number;
    importance: number;
};

export const getMunicipality = async (postalCode: string): Promise<FeatureCollection<Point, AddressProperties>> => {
    return await getExternalData(`https://api-adresse.data.gouv.fr/search?q=${postalCode}&type=municipality&limit=1`);
};
//#endregion

//#region dpe

export const searchDpe = async (userAddress: string, userZipCode: string, userLocality: string): Promise<DpeLine[] | DpeLine | undefined> => {
    const dpe: DpeLine[] | DpeLine | undefined = await postJsonData(`dpe/search`, { userAddress, userZipCode, userLocality });
    return dpe;
};
//#endregion

// #region marketing

export const productNames = [
    'Kakemono - Renovation énergétique',
    "Kakemono - Rénovation d'ampleur",
    'Kakemono - Installation photovoltaïque',
    'Kakemono - Réseau national',
    'Kakemono - Améliorer votre habitat',
    'Cartes de visite',
];

export type Product = (typeof productNames)[number];

export type MarketingOrderProduct = {
    [key in Product]?: {
        quantity?: number;
        price?: number;
        total?: number;
        customization?: Record<string, string>;
    };
};

export type MarketingOrder = {
    order: MarketingOrderProduct;
    total: number;
    deliveryAddress?: Record<string, string>;
};

export type MarketingOrderOutput = {
    id: string;
    createdAt: string;
    updatedAt: string;
    agentCode: string;
    order: MarketingOrderProduct;
    total: number;
    deliveredAt: string | null;
    deliveryAddress?: Record<string, string>;
    user: UserForManager;
};

export const createMarketingOrder = async (order: MarketingOrder): Promise<MarketingOrderOutput> => {
    const marketingOrder: MarketingOrderOutput = await postJsonData(`marketing-order`, order);

    return marketingOrder;
};

export const searchMarketingOrder = async (searchParams: SearchParams = undefined): Promise<MarketingOrderOutput> => {
    let query: string | undefined = undefined;
    if (searchParams) {
        const queryFilters = searchParams.filters ? `filters=${JSON.stringify(searchParams.filters)}` : '';
        const queryPagination = searchParams.pagination ? `pagination=${JSON.stringify(searchParams.pagination)}` : '';
        const queryOrderby = searchParams.orderby ? `orderby=${JSON.stringify(searchParams.orderby)}` : '';
        // Le .filter(Boolean) est utilisé pour éliminer les éléments falsy du tableau
        // Cela permet de ne garder que les parties de la requête qui ont effectivement une valeur
        // Boolean est utilisé comme fonction de filtrage, convertissant chaque élément en booléen
        // Ainsi, les chaînes vides, null, undefined, etc. sont supprimés du tableau final
        query = [queryFilters, queryPagination, queryOrderby].filter(Boolean).join('&');
    }

    const marketingOrder: MarketingOrderOutput = await getData(`marketing-order${query ? `?${query}` : ''}`);
    return marketingOrder;
};

export const updateMarketingOrder = async (order: MarketingOrder): Promise<MarketingOrderOutput> => {
    const marketingOrder: MarketingOrderOutput = await putJsonData(`marketing-order`, order);
    return marketingOrder;
};

export const getMarketingOrder = async (id: string): Promise<MarketingOrderOutput> => {
    const marketingOrder: MarketingOrderOutput = await getData(`marketing-order/${id}`);
    return marketingOrder;
};

// #endregion
