import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import Checkbox from '../../../components/checkbox/CheckBox';
import * as apiDRA from '../../../services/apiDRAService';
import { FormRow } from '../../../components/FormRow/FormRow';
import * as api from '../../../services/apiParticulierService';
import { draDocOutputToDocumentEntry, KnownDraDocType } from '../../../services/apiDRAService';
import FileFormRow from '../../../components/FileUpload/FileFormRow';
import { NetheosAnah, NetheosAnahPropsContext } from './NetheosAnah';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

type DraMakeItReadyProps = {
    dossierRenoDAmpleur: apiDRA.DossierRenoDAmpleur;
};

const DraMakeItReady: React.FC<DraMakeItReadyProps> = (props: DraMakeItReadyProps) => {
    const AlertSwal = withReactContent(Swal);
    const [clientInformed, setClientInformed] = useState<boolean>(props.dossierRenoDAmpleur.clientInformed);
    const [clientMprOpened, setClientMprOpened] = useState<boolean>(props.dossierRenoDAmpleur.clientMprOpened);

    const disabled = props.dossierRenoDAmpleur.state !== 'Created' && props.dossierRenoDAmpleur.state !== 'ReadyForContactMar';
    // 4 docs obligatoires :
    const mandatAnah = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'mandat_anah_mpr');
    const pieceIdentite = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'piece_identite');
    const avisImposition = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'avis_imposition');
    const taxeFonciere = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'taxe_fonciere');

    const updateDra = async (ci: boolean, cmp: boolean, comment: string[] | undefined = undefined) => {
        console.log('updateDra old ', clientInformed, clientMprOpened);
        console.log('updateDra new ', ci, cmp);
        setClientInformed(ci);
        setClientMprOpened(cmp);
        await apiDRA.updateDra(props.dossierRenoDAmpleur.id, {
            clientInformed: ci,
            clientMprOpened: cmp,
            comment,
        });
        //window.location.reload();
    };

    const handleDeleteDocument = async (document: apiDRA.DraDocOutput | undefined) => {
        // SI il y  a un lcc, on le supprime
        if (document && document.id) {
            await apiDRA.deleteDraDocument(props.dossierRenoDAmpleur.id, document.id);
            window.location.reload();
        }
    };

    const uploadDra = async (file: File, comment: KnownDraDocType) => {
        try {
            // Upload du doc, et association entre le doc et le dra
            const documents = await api.postDocument({ [comment]: file });
            await apiDRA.associateDraDocument(props.dossierRenoDAmpleur.id, documents[comment].id, comment);

            window.location.reload();
        } catch (error) {
            // TODO NICO : afficher un message d'erreur echec du téléchargement
            console.error("Erreur lors de l'envoi des données de l'agent :", error);
        }
    };

    const handleAnahModal = () => {
        try {
            apiDRA.consentAnahSign(props.dossierRenoDAmpleur.id).then((emptyNetheosContract) => {
                const context: NetheosAnahPropsContext = {
                    draId: props.dossierRenoDAmpleur.id,
                    emptyAnahContract: emptyNetheosContract,
                };

                AlertSwal.fire({
                    title: 'Signature du mandat Anah / MaPrimeRénov',
                    html: <NetheosAnah token={emptyNetheosContract.participantAccessToken} context={context} onAnahConsentSigned={onAnahConsentSigned} />,
                    width: 800,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
            });
        } catch (error) {
            console.log('consentement impossible a envoyer : ' + error);
            throw error;
        }
    };

    const onAnahConsentSigned = async (context: NetheosAnahPropsContext): Promise<boolean> => {
        try {
            await apiDRA.anahConsentDownload(context.draId, context.emptyAnahContract.dossierUuid);

            return true;
        } catch (err) {
            console.log('download failed');
            return false;
        } finally {
            // ATTENTION ICI, si jamais on modifie le code de src/pages/espace-agent/Detail/NetheosAnah.tsx
            // Et qu'on utilise vraiment le returne true ou false pour faire un traitement
            // (autre qu'arrêter le sablier). Alors il faudra trouver un autre moyen de rafraichir la page.
            window.location.reload();
        }
    };

    return (
        <>
            <Formik initialValues={{}} onSubmit={() => {}}>
                {({ values, errors, touched, setValues }: FormikProps<{}>) => (
                    <div>
                        {disabled ? (
                            <>
                                <FormRow
                                    label="Le client est bien au courant du règlement à faire au MAR"
                                    value={props.dossierRenoDAmpleur.clientInformed ? 'Oui' : 'Non'}
                                />
                                <FormRow label="Le client a déjà un compte MPR ouvert" value={props.dossierRenoDAmpleur.clientMprOpened ? 'Oui' : 'Non'} />
                            </>
                        ) : (
                            <>
                                <FormRow
                                    label="Le client est bien au courant du règlement à faire au MAR"
                                    value={
                                        <Checkbox
                                            checked={clientInformed}
                                            onCheckedChange={(checked: boolean) => {
                                                updateDra(checked, clientMprOpened);
                                            }}
                                        />
                                    }
                                />
                                <FormRow
                                    label="Le client a déjà un compte MPR ouvert"
                                    value={
                                        <Checkbox
                                            checked={clientMprOpened}
                                            onCheckedChange={(checked: boolean) => {
                                                updateDra(clientInformed, checked);
                                            }}
                                        />
                                    }
                                />
                            </>
                        )}

                        <div className="col-12">
                            {mandatAnah ? (
                                <FileFormRow<apiDRA.DraDocOutput>
                                    label={apiDRA.knownDraDocTypeToString('mandat_anah_mpr')}
                                    fileDescriptor={mandatAnah}
                                    disabled={disabled}
                                    canDeleteDocs={true}
                                    convertToDocumentEntry={draDocOutputToDocumentEntry}
                                    onDelete={handleDeleteDocument}
                                    onUpload={(file: File) => uploadDra(file, 'mandat_anah_mpr')}
                                />
                            ) : (
                                <>
                                    {' '}
                                    <FormRow
                                        label={apiDRA.knownDraDocTypeToString('mandat_anah_mpr')}
                                        value={
                                            <button className="btn btn-change-pref position-static mw-100" onClick={() => handleAnahModal()}>
                                                Signature Mandat Anah
                                            </button>
                                        }
                                    />
                                </>
                            )}
                            <FileFormRow<apiDRA.DraDocOutput | undefined>
                                label={apiDRA.knownDraDocTypeToString('piece_identite')}
                                fileDescriptor={pieceIdentite}
                                disabled={disabled}
                                canDeleteDocs={true}
                                convertToDocumentEntry={draDocOutputToDocumentEntry}
                                onDelete={handleDeleteDocument}
                                onUpload={(file: File) => uploadDra(file, 'piece_identite')}
                            />
                            <FileFormRow<apiDRA.DraDocOutput | undefined>
                                label={apiDRA.knownDraDocTypeToString('avis_imposition')}
                                fileDescriptor={avisImposition}
                                disabled={disabled}
                                canDeleteDocs={true}
                                convertToDocumentEntry={draDocOutputToDocumentEntry}
                                onDelete={handleDeleteDocument}
                                onUpload={(file: File) => uploadDra(file, 'avis_imposition')}
                            />
                            <FileFormRow<apiDRA.DraDocOutput | undefined>
                                label={apiDRA.knownDraDocTypeToString('taxe_fonciere')}
                                fileDescriptor={taxeFonciere}
                                disabled={disabled}
                                canDeleteDocs={true}
                                convertToDocumentEntry={draDocOutputToDocumentEntry}
                                onDelete={handleDeleteDocument}
                                onUpload={(file: File) => uploadDra(file, 'taxe_fonciere')}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default DraMakeItReady;
