import React from 'react';

interface FormRowProps {
    label: string | React.ReactNode;
    value: string | number | undefined | React.ReactNode;
}

export const FormRow: React.FC<FormRowProps> = ({ label, value }) => {
    return (
        <div className="form-group align-items-center account mb-2">
            <label className="col-form-label">{label} :</label>
            <div className="position-relative">
                <span className="result">{value}</span>
            </div>
        </div>
    );
};

interface FormRowMultilineProps {
    label: string;
    values: string[];
}

export const FormRowMultiline: React.FC<FormRowMultilineProps> = ({ label, values }) => {
    return (
        <div className="form-group align-items-center account mb-2">
            <label className="col-form-label">{label} :</label>
            <div className="form-row--multiline form-row__content">
                {values.map((value, index) => (
                    <span key={index} className="result">
                        {value}
                    </span>
                ))}
            </div>
        </div>
    );
};
