import * as yup from 'yup';
import React, { useState, useEffect, useMemo } from 'react';
import * as storageService from '../../../services/localStorageService';
import { Formik, Field, FormikProps } from 'formik';
import { FormValues } from './MonCompteEnums';
import { validationSchema } from './schema/schema';
import * as api from '../../../services/apiParticulierService';
import * as routes from '../../../routing/paths';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Back } from '../../../components/Back/Back';
import { FilterThemePreference, defaultAdvancedFilterThemePreference, defaultFilterThemePreference } from './preference';
import { ComplexThemeType, SubThemesOf, ThemeType } from '../../../services/calculs/theme';
import FileFormRow from '../../../components/FileUpload/FileFormRow';
import { userDocOutputToDocumentEntry } from '../../../services/apiParticulierService';

// Icons
import { ReactComponent as IconEuro } from '../../../assets/icons/espace-agent/icon-euro.svg';
import { ReactComponent as IconFilter } from '../../../assets/icons/espace-agent/icon-filter.svg';
import { ReactComponent as IconUser } from '../../../assets/icons/espace-agent/icon-account.svg';

// Style
import './MonCompte.scss';

const nameRegex = /^[-' 0-9a-zA-ZÀÁÂÄÇÈÉÊËÌÍÎÏÐÒÓÔÕÖÙÚÛÜÝàáâäçèéêëìíîïòóôõöùúûüýÿ]{1,64}$/; // necessary for netheos participant
const phoneRegex = /^((\+)33|0)[1-9](\d{2}){4}$/; // French phone numbers
export const addSubAgentSchema = yup
    .object()
    .shape({
        civility: yup.mixed<api.Civility>().oneOf(Object.values(api.civilityNames)).required('validation.required'),
        lastname: yup.string().matches(nameRegex, 'validation.invalid').required('validation.required'),
        firstname: yup.string().matches(nameRegex, 'validation.invalid').required('validation.required'),
        email: yup.string().email('validation.invalid').required('validation.required'),
        phoneNumber: yup.string().matches(phoneRegex, 'validation.invalid').max(12).required('validation.required'),

        country: yup.string().notRequired(),
        address: yup.string().required('validation.required'),
        zipcode: yup.string().required('validation.required'),
        city: yup.string().required('validation.required'),

        birthPlace: yup.string().notRequired(), // .required('validation.required'), ??
        birthDate: yup.string().notRequired(), // .required('validation.required'), ??
        birthCountry: yup.string().notRequired(), // .required('validation.required'), ??
        nationality: yup.string().notRequired(),

        identityDocument1Id: yup.string().notRequired(),
        identityDocument2Id: yup.string().notRequired(),
    })
    .noUnknown(true);

const MonCompte: React.FC = () => {
    // Unlock code financo edition
    const [unlockFinanco, setUnlockFinanco] = useState<boolean>(false);
    // Unlock remise
    const [unlockRemise, setUnlockRemise] = useState<boolean>(false);
    const [remiseCommerciale, setRemiseCommerciale] = useState<number | undefined>(undefined);
    // Get financoId from API
    const [financoId, setFinancoId] = useState<string>('');
    const [certifLcc, setCertifLcc] = useState<api.UserDocOutput | undefined>(undefined);
    const [prefThemeFilter, setPrefThemeFilter] = useState<FilterThemePreference>(defaultFilterThemePreference); // Yes undefined

    const [isEditingPref, setIsEditingPref] = useState(false);
    const [tempPrefThemeFilter, setTempPrefThemeFilter] = useState<FilterThemePreference>(defaultFilterThemePreference);

    // Get current agent infos from localStorage
    const agent = storageService.getCurrentAgent();
    const AlertSwal = withReactContent(Swal);

    // Set sellersId if existing
    useEffect(() => {
        const loadMyself = async () => {
            const myself = await api.getMyself();
            if (
                myself.fundingEnpowerment &&
                myself.fundingEnpowerment.type === 'Financo' &&
                myself.fundingEnpowerment.fundingOrganismProperties &&
                myself.fundingEnpowerment.fundingOrganismProperties.sellersId
            ) {
                setFinancoId(myself.fundingEnpowerment.fundingOrganismProperties.sellersId);
            }

            if (myself.userDocs && myself.userDocs.length > 0) {
                const certif = myself.userDocs.find((doc) => doc.comment === 'certif_lcc');
                if (certif) {
                    setCertifLcc(certif);
                }
            }

            if (myself.preferences && myself.preferences.remiseCommerciale) {
                setRemiseCommerciale(myself.preferences.remiseCommerciale as number | undefined);
            }

            if (myself.preferences && myself.preferences.themeFilter) {
                setPrefThemeFilter(myself.preferences.themeFilter as FilterThemePreference);
                setTempPrefThemeFilter(myself.preferences.themeFilter as FilterThemePreference);
            } else {
                setPrefThemeFilter(defaultFilterThemePreference);
                setTempPrefThemeFilter(defaultFilterThemePreference);
            }
        };

        loadMyself();
    }, []);
    // Update agent sellersId
    const saveFinancoId = async (values: FormValues) => {
        try {
            await api.setMyselfFundingProperties(agent.agentCode, {
                type: 'Financo',
                hasLcc: certifLcc ? true : false,
                fundingOrganismProperties: { sellersId: values.financoId },
            });

            // Show success modal and autoclose
            AlertSwal.fire({
                icon: 'success',
                title: 'Succès',
                html: <p>Votre modification a bien été prise en compte</p>,
                timer: 1000, // time in ms
                timerProgressBar: true,
                showConfirmButton: false,
            });

            // Hide "save" button if success
            setUnlockFinanco(false);
        } catch (e) {
            console.error(e);
        }
    };

    // Helpers pour boucler sur les themes / sous themes dans le formulaire.
    const themeTypeNames = Object.keys(defaultAdvancedFilterThemePreference) as Array<ThemeType>;
    const subThemeTypeNames = (() => {
        const array: { [k in string]?: Array<SubThemesOf<ThemeType>> } = {};
        for (const theme of themeTypeNames) {
            const keys = Object.keys(defaultAdvancedFilterThemePreference[theme]);
            const typekeys = keys as Array<SubThemesOf<typeof theme>>;
            array[theme] = typekeys;
        }
        return array;
    })();

    // Update agent sellersId
    const savePref = async (remiseValue?: number | undefined) => {
        try {
            await api.setMyselfPref(agent.agentCode, {
                themeFilter: tempPrefThemeFilter, // Comment obtenir les données des cases à cocher ?
                remiseCommerciale: remiseValue ?? remiseCommerciale,
            });

            // Show success modal and autoclose
            AlertSwal.fire({
                icon: 'success',
                title: 'Succès',
                html: <p>Votre modification a bien été prise en compte</p>,
                timer: 1000, // time in ms
                timerProgressBar: true,
                showConfirmButton: false,
            });

            // Hide "save" button if success
            setIsEditingPref(false);
            setUnlockRemise(false);
        } catch (e) {
            console.error(e);
        }
    };

    const initialValues: FormValues = useMemo(() => {
        return {
            financoId,
            certifLcc,
            remiseCommerciale,
            preferences: { ...prefThemeFilter }, // Copiez les préférences actuelles
        };
    }, [financoId, certifLcc, prefThemeFilter, remiseCommerciale]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Permet les touches de contrôle comme Backspace, Tab, Enter, etc.
        if (['Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key)) {
            return;
        }

        // Bloque les caractères non numériques
        if (!/^\d$/.test(event.key)) {
            event.preventDefault();
        }
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean) => void,
        values: FormValues
    ) => {
        let newValue = event.target.value;
        // Vérifie si la valeur est vide ou un nombre entier
        if (newValue === '' || /^\d+$/.test(newValue)) {
            const numericValue = parseInt(newValue, 10);
            if (!isNaN(numericValue) && numericValue > 1000) {
                newValue = '1000';

                AlertSwal.fire({
                    title: 'Dépassement du plafond de remise',
                    html: <p>Le montant maximum de la remise commerciale « Nouveau Producteur » est plafonnée à 1000 €</p>,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-continue min-width',
                    },
                });
            }

            setValues({
                ...values,
                remiseCommerciale: Number(newValue),
            });
        }
    };

    const uploadCertifLcc = async (file: File) => {
        try {
            // Upload du doc, et association entre le doc et le user
            const documents = await api.postDocument({ certif_lcc: file });
            const userDoc = await api.associateMyselfDocument(documents.certif_lcc.id, 'certif_lcc');

            // Si c'est un echec, est dans le catch.
            // sinon, on met à jour qu'on a un lcc.
            await api.setMyselfFundingProperties(agent.agentCode, {
                type: 'Financo',
                hasLcc: true,
                fundingOrganismProperties: { sellersId: financoId },
            });
            // On met à jour le state
            setCertifLcc(userDoc);
        } catch (error) {
            // TODO NICO : afficher un message d'erreur echec du téléchargement
            console.error("Erreur lors de l'envoi des données de l'agent :", error);
        }
    };

    /**
     * SI il y  a un lcc, on le supprime
     */
    const handleDeleteCertifLcc = async (document: api.UserDocOutput | undefined) => {
        if (!document || !document.id) return;

        await api.deleteMyselfDocument(document.id);
        await api.setMyselfFundingProperties(agent.agentCode, {
            type: 'Financo',
            hasLcc: false,
            fundingOrganismProperties: { sellersId: financoId },
        });
        setCertifLcc(undefined);
    };

    // Check all boxes according to theme
    // const toggleThemeCheckboxes = (themeName: ThemeType) => {
    //     if (isEditingPref) {
    //         const updatedPref: Record<string, boolean> = { ...tempPrefThemeFilter };

    //         if (themeName !== undefined && subThemeTypeNames[themeName] !== undefined) {
    //             subThemeTypeNames[themeName]?.forEach((subThemeName) => {
    //                 const complexType = (themeName + '/' + subThemeName) as ComplexThemeType;
    //                 updatedPref[complexType] = !updatedPref[themeName as keyof FilterThemePreference];
    //             });
    //         }

    //         // Créez un nouvel objet FilterThemePreference avec les valeurs mises à jour
    //         const newTempPrefThemeFilter: FilterThemePreference = {
    //             ...tempPrefThemeFilter,
    //             ...updatedPref,
    //         };

    //         setTempPrefThemeFilter(newTempPrefThemeFilter);
    //     }
    // };
    return (
        <>
            <div className="container mt-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
            </div>
            <div className="container py-5 px-4">
                <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={validationSchema} validateOnMount enableReinitialize>
                    {({ values, errors, touched, setValues }: FormikProps<FormValues>) => (
                        <>
                            <div className="container-split">
                                <div className="container-creation split-50 py-4 row justify-content-center mb-4">
                                    <div className="row">
                                        <h2 className="d-flex align-items-center gap-2">
                                            <IconUser fill="#5a5a5a" height={16} />
                                            Informations agent
                                        </h2>
                                        <div className="col-12 col-md-5">
                                            <div className="form-inline">
                                                <div className="form-group account mb-2">
                                                    <label htmlFor="name" className="col-form-label">
                                                        Nom :
                                                    </label>
                                                    <div className="position-relative w-100">
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            className="form-control invisible-field"
                                                            value={agent.nom_agent}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group account mb-2">
                                                    <label htmlFor="firstname" className="col-form-label">
                                                        Prénom :
                                                    </label>
                                                    <div className="position-relative w-100">
                                                        <input
                                                            type="text"
                                                            id="firstname"
                                                            name="firstname"
                                                            className="form-control invisible-field"
                                                            value={agent.prenom_agent}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group account mb-2">
                                                    <label htmlFor="phone" className="col-form-label">
                                                        Code agent :
                                                    </label>
                                                    <div className="position-relative w-100">
                                                        <input
                                                            type="text"
                                                            id="codeAgent"
                                                            name="codeAgent"
                                                            className="form-control invisible-field"
                                                            value={agent.agentCode}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-7">
                                            <div className="form-inline">
                                                <div className="form-group account mb-2">
                                                    <label htmlFor="email" className="col-form-label">
                                                        Email :
                                                    </label>
                                                    <div className="position-relative w-100">
                                                        <input
                                                            type="email"
                                                            id="email"
                                                            name="email"
                                                            className="form-control invisible-field"
                                                            value={agent.email}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group account mb-2">
                                                    <label htmlFor="phone" className="col-form-label">
                                                        Téléphone :
                                                    </label>
                                                    <div className="position-relative w-100">
                                                        <input
                                                            type="tel"
                                                            id="phone"
                                                            name="phone"
                                                            className="form-control invisible-field"
                                                            value={agent.tel}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-creation split-50 py-4 row justify-content-center position-relative mb-4">
                                    <div className="row">
                                        <h2 className="d-flex align-items-start">
                                            <span className="d-flex align-items-center gap-2">
                                                <IconEuro fill="#5a5a5a" height={16} />
                                                Financement
                                            </span>
                                        </h2>
                                        <div className="col-12">
                                            <FileFormRow<api.UserDocOutput | undefined>
                                                label={api.knownUserDocTypeToString('certif_lcc')}
                                                fileDescriptor={certifLcc}
                                                disabled={false}
                                                canDeleteDocs={true}
                                                convertToDocumentEntry={userDocOutputToDocumentEntry}
                                                onDelete={handleDeleteCertifLcc}
                                                onUpload={uploadCertifLcc}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="form-inline">
                                                <div className="form-group account mb-2">
                                                    <label htmlFor="financo" className="col-form-label">
                                                        Code Financo :
                                                    </label>
                                                    <div className="position-relative d-flex align-items-center gap-4">
                                                        <Field
                                                            type="text"
                                                            className={unlockFinanco ? 'form-control' : 'form-control invisible-field'}
                                                            readOnly={!unlockFinanco}
                                                            id="financo"
                                                            name="financo"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                setValues({
                                                                    ...values,
                                                                    financoId: e.target.value,
                                                                });
                                                            }}
                                                            value={values.financoId}
                                                            placeholder={unlockFinanco ? '' : '-'}
                                                        />
                                                        {touched.financoId && errors.financoId && <span className="invalid-feedback">{errors.financoId}</span>}
                                                    </div>
                                                    {unlockFinanco ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-change-pref btn-change-pref-save no-absolute"
                                                            onClick={() => saveFinancoId(values)}
                                                            disabled={Object.entries(errors).length > 0}
                                                        >
                                                            Enregistrer
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            className="btn btn-change-pref no-absolute"
                                                            onClick={() => setUnlockFinanco(true)}
                                                        >
                                                            Modifier
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-creation mb-4 py-4 row justify-content-center position-relative">
                                {unlockRemise ? (
                                    <button
                                        type="button"
                                        className="btn btn-change-pref btn-change-pref-save"
                                        onClick={() => savePref(values.remiseCommerciale)}
                                        disabled={Object.entries(errors).length > 0}
                                    >
                                        Enregistrer
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-change-pref" onClick={() => setUnlockRemise(true)}>
                                        Modifier
                                    </button>
                                )}
                                <div className="row">
                                    <h2 className="d-flex align-items-center gap-2">
                                        <IconUser fill="#5a5a5a" height={16} />
                                        Promotion d'aide à la vente
                                    </h2>
                                    <div className="col-12 col-md-6">
                                        <div className="form-inline">
                                            <div className="form-group account mb-2">
                                                <label htmlFor="remise" className="col-form-label">
                                                    Activer une remise commerciale nouveau producteur (€ TTC) :
                                                </label>
                                                <Field
                                                    type="text"
                                                    className={unlockRemise ? 'form-control' : 'form-control invisible-field'}
                                                    readOnly={!unlockRemise}
                                                    id="remise"
                                                    name="remise"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, setValues, values)}
                                                    onKeyDown={handleKeyDown}
                                                    value={values.remiseCommerciale}
                                                    placeholder={unlockRemise ? '' : '0'}
                                                />
                                                {touched.remiseCommerciale && errors.remiseCommerciale && (
                                                    <span className="invalid-feedback">{errors.remiseCommerciale}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-creation py-4 row justify-content-center position-relative">
                                {isEditingPref ? (
                                    <button type="button" className="btn btn-change-pref btn-change-pref-save" onClick={() => savePref()}>
                                        Enregistrer
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-change-pref" onClick={() => setIsEditingPref(true)}>
                                        Modifier
                                    </button>
                                )}
                                <div className="row">
                                    <h2 className="d-flex align-items-center gap-2">
                                        <IconFilter fill="#5a5a5a" height={16} />
                                        Filtrage des thèmes
                                    </h2>
                                    <div className="themes container">
                                        <div className="row">
                                            {/* Boucle sur les themes */}
                                            {themeTypeNames.map((themeName: ThemeType, index) => (
                                                <div key={'themeName' + index} className="col-12 col-md-6 mb-4">
                                                    <h3 className="sub-theme">{themeName}</h3>
                                                    {/* Bon courage pour ajouter une checkbox qui règle le theme entier...
                                                    On pourra le faire ensemble si tu veux. */}
                                                    <>
                                                        {/* Boucle sur les sous themes. */}
                                                        {themeName !== undefined && subThemeTypeNames[themeName] !== undefined
                                                            ? subThemeTypeNames[themeName]?.map((subThemeName, index) => {
                                                                  const complexType = (themeName + '/' + subThemeName) as ComplexThemeType;
                                                                  //   console.log('Thème:', themeName);
                                                                  return (
                                                                      <div className="row align-items-center mb-2 ps-lg-1 ps-0" key={'complexType' + index}>
                                                                          <div className="form-checkbox position-relative col-1">
                                                                              <input
                                                                                  type="checkbox"
                                                                                  id={`checkbox-${index}`}
                                                                                  name={complexType}
                                                                                  checked={tempPrefThemeFilter[complexType as ComplexThemeType]}
                                                                                  disabled={
                                                                                      (complexType as ComplexThemeType) === "Economie d'énergie/Photovoltaïque"
                                                                                  }
                                                                                  readOnly={!isEditingPref}
                                                                                  onChange={() => {
                                                                                      if (isEditingPref) {
                                                                                          const updatedPref = { ...tempPrefThemeFilter };
                                                                                          const complexeTheme = complexType as ComplexThemeType;
                                                                                          if (complexeTheme === "Economie d'énergie/Photovoltaïque")
                                                                                              updatedPref[complexeTheme] = true;
                                                                                          else updatedPref[complexeTheme] = !tempPrefThemeFilter[complexeTheme];
                                                                                          setTempPrefThemeFilter(updatedPref);
                                                                                      }
                                                                                  }}
                                                                                  className={!isEditingPref ? 'disabled' : undefined}
                                                                              />
                                                                              <div className="fake-checkbox"></div>
                                                                          </div>
                                                                          <label htmlFor={`checkbox-${index}`} className="col-form-label col-11">
                                                                              {subThemeName}
                                                                          </label>
                                                                      </div>
                                                                  );
                                                              })
                                                            : null}
                                                    </>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default MonCompte;
