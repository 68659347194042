import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const PRESQUE_ZERO = 0.00001;

export type Writable<T> = {
    -readonly [K in keyof T]: T[K];
};

const currencyFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
});

const currencyFormatterWithCents2 = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});
const currencyFormatterWithCents4 = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
});

export const powerFormat = (element: string | number | undefined) => {
    let number: number = 0;
    if (typeof element === 'string') {
        number = Number(element);
    }
    if (typeof element === 'number') {
        number = element;
    }
    const formattedNumber = number.toLocaleString('fr-FR', { maximumFractionDigits: 3 });

    return formattedNumber;
};

export type Stringified<T extends { [k in keyof T]: number }> = { [k in keyof T]: string };

export const currencyFormat = (price: number, cents: boolean | 2 | 4 = false): string => {
    if (cents === 4) return currencyFormatterWithCents4.format(price);
    if (cents === true || cents === 2) return currencyFormatterWithCents2.format(price);
    return currencyFormatter.format(price);
};

export const percentFormat = (percent: number, decimales = 0): string => {
    // rouning is not so simple.
    // for our needs, its ok.
    // BUT : https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
    if (decimales <= 0) return Math.round(percent * 100) + '%';
    else {
        const multiplier = Math.pow(10, decimales);
        return Math.round((percent + Number.EPSILON) * 100 * multiplier) / multiplier + '%';
    }
};

export const parseCurrency = (price: string): number => {
    return +price.replace('€', '').replace(/\s/g, '').replace(',', '.');
};

export const dateFormat = (date: string | Date | undefined | null): string => {
    if (!date) return '-';
    return format(new Date(date), 'dd/MM/yyy', { locale: fr });
};

export type Labelled<T> = { label: string; value: T };
export type LabelledString = Labelled<string>;

export const filterInPlace = <T>(array: Array<T>, predicate: (value: T, index: number, array: Array<T>) => boolean): Array<T> => {
    let i = 0,
        j = 0;

    while (i < array.length) {
        const val = array[i];
        if (predicate(val, i, array)) array[j++] = val;
        i++;
    }

    array.length = j;
    return array;
};

// ARRAY EXTENSIONS.
// declare global {
//     interface Array<T> {
//         remove(elem: T): Array<T>;
//         /** remove all element that match predicate, in place  */
//         //removeAll(predicate: (value: T, index: number, array: Array<T>) => boolean): Array<T>;
//     }
// }

// if (!Array.prototype.remove) {
//     // eslint-disable-next-line no-extend-native
//     Array.prototype.remove = function <T>(this: T[], elem: T): T[] {
//         return this.filter((e) => e !== elem);
//     };
// }

// if (!Array.prototype.removeAll) {
//     // eslint-disable-next-line no-extend-native
//     Array.prototype.removeAll = function <T>(this: T[], predicate: (value: T, index: number, array: Array<T>) => boolean): T[] {
//         let i = 0;

//         while (i < this.length) {
//             const val = this[i];
//             if (predicate(val, i, this)) this.splice(i, 1);
//             else i++;
//         }

//         return this;
//     };
// }

// List generator with steps
export const generateNumberList = (start: number, end: number, step: number): Array<LabelledString> => {
    const list = [];
    for (let i = start; i <= end; i += step) {
        list.push({ label: i.toString(), value: i.toString() });
    }
    return list;
};

export type NoticeProps = 'audit' | 'previsit' | 'financement';

export const isValidUrl = (url: string | undefined): boolean => {
    if (!url || url === '') return false;
    if (typeof url === 'undefined' || typeof url !== 'string') return false;
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
